import React, { useCallback, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { setDeliveryInfoAction } from 'root-redux/actions/user'
import { selectIsFetching } from 'root-redux/selects/common'

import { checkIsUSPhoneValid } from 'helpers/phone'

import { getStateByCode } from 'modules/subscriptions/helpers'

import { InputWithFloatPlaceholder } from 'components/InputWithFloatPlaceholder'
import { SelectWithFloatPlaceholder } from 'components/SelectWithFloatPlaceholder'

import checkIcon from 'assets/images/sprite/check-icon-violet.svg'

import { StyledDeliveryForm as S } from './DeliveryForm.styles'
import {
  ErrorContext,
  ONLY_DIGITS_REGEX,
  USER_FORM_FIELD_MAX_LENGTH,
  US_STATES_OPTIONS,
} from './constants'

type TCustomerFormInput = {
  country: string
  firstName: string
  lastName: string
  address: string
  optionalAddress?: string
  city: string
  state: { value: string; label: string } | null
  zipCode: string
  phone: string
}

type TProps = {
  onUpgradePlan: () => void
}

export const DeliveryForm: React.FC<TProps> = ({ onUpgradePlan }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const isFetching = useSelector(selectIsFetching)
  const [hasOptionalAddress, setHasOptionalAddress] = useState(false)

  const {
    trigger,
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm<TCustomerFormInput>({
    defaultValues: {
      country: 'United States',
      firstName: '',
      lastName: '',
      address: '',
      optionalAddress: '',
      city: '',
      zipCode: '',
      phone: '',
      state: null,
    },
    mode: 'all',
    reValidateMode: 'onBlur',
  })

  const handleSubmit = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault()
      wrapperRef?.current?.focus()

      const formValues: TCustomerFormInput = getValues()
      dispatch(
        setDeliveryInfoAction({
          ...formValues,
          state: formValues.state?.value || '',
        }),
      )
      onUpgradePlan()
    },
    [dispatch, getValues, onUpgradePlan],
  )

  return (
    <S.Wrapper ref={wrapperRef} tabIndex={-1}>
      <S.Title>{t`subscriptions.chipolo.checkout.deliveryForm.title`}</S.Title>
      <S.Form onSubmit={handleSubmit}>
        <InputWithFloatPlaceholder
          value={t`subscriptions.chipolo.checkout.deliveryForm.usa`}
          labelName={t`subscriptions.chipolo.checkout.deliveryForm.country`}
          disabled
        />
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <InputWithFloatPlaceholder
              {...field}
              labelName={t(
                'subscriptions.chipolo.checkout.deliveryForm.firstName.label',
              )}
              isValid={!errors.firstName}
              {...(errors.firstName && {
                validationText: t(
                  'subscriptions.chipolo.checkout.deliveryForm.firstName.error',
                ),
              })}
            />
          )}
          rules={{ required: true, validate: (value) => !!value.trim().length }}
        />

        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <InputWithFloatPlaceholder
              {...field}
              labelName={t(
                'subscriptions.chipolo.checkout.deliveryForm.lastName.label',
              )}
              isValid={!errors.lastName}
              {...(errors.lastName && {
                validationText: t(
                  'subscriptions.chipolo.checkout.deliveryForm.lastName.error',
                ),
              })}
            />
          )}
          rules={{ required: true, validate: (value) => !!value.trim().length }}
        />

        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <InputWithFloatPlaceholder
              {...field}
              labelName={t(
                'subscriptions.chipolo.checkout.deliveryForm.address.label',
              )}
              isValid={!errors.address}
              marginBottom={hasOptionalAddress ? 24 : 20}
              {...(errors.address && {
                validationText: t(
                  'subscriptions.chipolo.checkout.deliveryForm.address.error',
                  {
                    context:
                      getValues('address').length > USER_FORM_FIELD_MAX_LENGTH
                        ? ErrorContext.LENGTH
                        : ErrorContext.DEFAULT,
                  },
                ),
              })}
            />
          )}
          rules={{
            required: true,
            validate: (value) =>
              !!value.trim().length &&
              value.length <= USER_FORM_FIELD_MAX_LENGTH,
          }}
        />

        {hasOptionalAddress ? (
          <Controller
            name="optionalAddress"
            control={control}
            render={({ field }) => (
              <InputWithFloatPlaceholder
                {...field}
                labelName={t(
                  'subscriptions.chipolo.checkout.deliveryForm.optionalAddress.label',
                )}
              />
            )}
          />
        ) : (
          <S.OptionalAddressButton
            onClick={() => setHasOptionalAddress(true)}
            tabIndex={-1}
          >
            {t(
              'subscriptions.chipolo.checkout.deliveryForm.optionalAddress.button',
            )}
          </S.OptionalAddressButton>
        )}

        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            <InputWithFloatPlaceholder
              {...field}
              labelName={t(
                'subscriptions.chipolo.checkout.deliveryForm.city.label',
              )}
              isValid={!errors.city}
              {...(errors.city && {
                validationText: t(
                  'subscriptions.chipolo.checkout.deliveryForm.city.error',
                  {
                    context:
                      getValues('city').length > USER_FORM_FIELD_MAX_LENGTH
                        ? ErrorContext.LENGTH
                        : ErrorContext.DEFAULT,
                  },
                ),
              })}
            />
          )}
          rules={{
            required: true,
            validate: (value) => {
              return (
                !!value.trim().length &&
                value.length <= USER_FORM_FIELD_MAX_LENGTH
              )
            },
          }}
        />

        <Controller
          name="state"
          control={control}
          render={({ field }) => (
            <SelectWithFloatPlaceholder
              {...field}
              placeholder={t(
                'subscriptions.chipolo.checkout.deliveryForm.state.label',
              )}
              options={US_STATES_OPTIONS}
              isValid={!errors.state}
              onBlur={() => {
                if (getValues().state) {
                  trigger('zipCode')
                }
              }}
              {...(errors.state && {
                validationText: t(
                  'subscriptions.chipolo.checkout.deliveryForm.error',
                ),
              })}
            />
          )}
          rules={{ required: true }}
        />

        <Controller
          name="zipCode"
          control={control}
          render={({ field }) => (
            <InputWithFloatPlaceholder
              {...field}
              labelName={t(
                'subscriptions.chipolo.checkout.deliveryForm.zipCode.label',
              )}
              isValid={!errors.zipCode}
              {...(errors.zipCode && {
                validationText: t(
                  'subscriptions.chipolo.checkout.deliveryForm.zipCode.error',
                  {
                    state: getValues('state')?.label,
                    context: getValues('state')
                      ? ErrorContext.STATE
                      : ErrorContext.DEFAULT,
                  },
                ),
              })}
            />
          )}
          rules={{
            required: true,
            validate: (value) => {
              const state = getStateByCode(value)
              return (
                state === getValues('state')?.value &&
                ONLY_DIGITS_REGEX.test(value)
              )
            },
          }}
        />

        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <InputWithFloatPlaceholder
              {...field}
              labelName={t(
                'subscriptions.chipolo.checkout.deliveryForm.phone.label',
              )}
              hintText={t(
                'subscriptions.chipolo.checkout.deliveryForm.phone.hint',
              )}
              isValid={!errors.phone}
              marginBottom={20}
              {...(errors.phone && {
                validationText: t(
                  'subscriptions.chipolo.checkout.deliveryForm.phone.error',
                  {
                    context:
                      getValues('phone') &&
                      !checkIsUSPhoneValid(getValues('phone'))
                        ? ErrorContext.INVALID
                        : ErrorContext.DEFAULT,
                  },
                ),
              })}
            />
          )}
          rules={{
            required: true,
            validate: (value) => value.trim() && checkIsUSPhoneValid(value),
          }}
        />

        <S.ShippingInfoTitle>{t`subscriptions.chipolo.checkout.shippingInfoTitle`}</S.ShippingInfoTitle>
        <S.ShippingInfoContainer>
          <S.ShippingInfoIcon svg={checkIcon} />
          <S.ShippingInfoText>{t`subscriptions.chipolo.checkout.freeShipping`}</S.ShippingInfoText>
          <S.ShippingInfoDays>{t`subscriptions.chipolo.checkout.shippingDays`}</S.ShippingInfoDays>
        </S.ShippingInfoContainer>

        <S.Button type="submit" disabled={!isValid || isFetching}>
          {t`subscriptions.chipolo.checkout.confirmPayment`}
        </S.Button>
      </S.Form>
    </S.Wrapper>
  )
}
