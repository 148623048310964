import styled from 'styled-components'

type TProps = {
  marginBottom?: number
  height?: number
}
export const StyledStepsVariant1 = {
  StepsWrapper: styled.div<TProps>`
    display: flex;
    gap: 24px;
    margin-top: 24px;
    margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;

    img {
      max-height: ${({ height }) => height || 570}px;
      margin-top: 9px;
    }
  `,
  StepsContent: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 269px;
  `,
  StepsTitle: styled.h4`
    padding: 4px 12px;
    border-radius: 16px;
    margin-bottom: 12px;
    background-color: #eff5f7;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    text-transform: uppercase;
    max-width: 83px;
  `,
  StepsSubtitle: styled.p`
    color: #5c90ff;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 4px;
  `,
  StepsText: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  `,
}
