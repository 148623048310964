import { Confidence } from 'root-constants/common'

export const TITLE_PATH = 'onboarding.geoTracking.title'
export const TITLE_PATH_2 = 'onboarding.geoTracking.title2'

export const OPTION_DATA = [
  { value: Confidence.SURE, text: 'commonComponents.sure' },
  { value: Confidence.MAYBE, text: 'commonComponents.maybe' },
  { value: Confidence.NO, text: 'commonComponents.no' },
]
