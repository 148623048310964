import styled, { css } from 'styled-components'

import { Color } from 'root-constants/common'

type TPriceTextProps = {
  gridArea: string
}

type TPricePeriodProps = {
  gridArea: string
  marginBottom: number
}

type TDiscountProps = {
  maxWidth?: number
}

type TDividerProps = TPriceTextProps

const commonPriceStyles = css`
  text-align: right;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
`

export const StyledPlanBlockVariant2 = {
  Wrapper: styled.div`
    width: 100%;
    margin-bottom: 16px;
  `,
  Title: styled.h2`
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
  `,
  PriceContainer: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'priceTodayText priceTodayText'
      'oldPeriod purchaseLabel'
      'firstDivider firstDivider'
      'priceAfterText priceAfterText'
      'newPeriod price'
      'discount oldPrice'
      'secondDivider secondDivider'
      'disclaimer disclaimer';
    align-items: start;
  `,
  PriceText: styled.p<TPriceTextProps>`
    grid-area: ${({ gridArea }) => gridArea};
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
  `,
  PricePeriod: styled.p<TPricePeriodProps>`
    grid-area: ${({ gridArea }) => gridArea};
    margin-bottom: ${({ marginBottom }) => marginBottom}px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  `,
  Price: styled.p`
    ${commonPriceStyles};
    grid-area: price;
  `,
  OldPrice: styled.p`
    ${commonPriceStyles};
    grid-area: oldPrice;
    text-decoration-line: line-through;
    color: rgba(254, 89, 89, 0.5);
  `,
  Discount: styled.p<TDiscountProps>`
    grid-area: discount;
    max-width: ${({ maxWidth }) => maxWidth || 70}px;
    margin-bottom: 24px;
    padding: 5px 8px;
    color: ${Color.WHITE};
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    border-radius: 12px;
    background-color: #7bc27e;
  `,
  PurchaseLabel: styled.div`
    grid-area: purchaseLabel;
    max-width: 132px;
    display: flex;
    justify-content: space-between;
    justify-self: flex-end;
    align-items: center;
    padding: 2px 8px 2px 4px;
    border-radius: 16px;
    background-color: #d6efff;
  `,
  PurchaseLabelText: styled.span`
    padding-left: 2px;
    text-align: right;
    font-size: 12px;
    font-weight: 556;
    line-height: 16px;
  `,
  Divider: styled.div<TDividerProps>`
    grid-area: ${({ gridArea }) => gridArea};
    margin-bottom: 16px;
    width: 100%;
    height: 1px;
    background-color: #e5e7eb;
  `,
  Disclaimer: styled.p`
    grid-area: disclaimer;
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
  `,
}
