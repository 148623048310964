import styled from 'styled-components'

import { BurgerMenu } from 'components/BurgerMenu'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import introImg from 'assets/images/intro-variant-6.webp'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledIntroVariant6 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
    width: 100%;
  `,
  ImageContainer: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
    min-height: 321px;
    padding-top: 15px;
    background-image: url(${introImg});
    background-size: 100% auto;
    background-repeat: no-repeat;
    aspect-ratio: 375/321;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  FixedContainer: styled.div`
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 0 0 15px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    text-align: center;
    margin: 0 auto;
  `,
  Title: styled.h1`
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    margin-bottom: 31px;
  `,
  OptionsContainer: styled(StyledOption.OptionsContainer)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;

    div {
      min-width: 150px;
      flex-shrink: 0;
    }
  `,
  QuestionButton: styled(QuestionButton)`
    justify-content: center;
  `,
  NotSureButton: styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};
    margin-bottom: 35px;

    input:checked + & {
      color: ${Color.ACCENT_VIOLET};
    }
  `,
  BurgerMenu: styled(BurgerMenu)`
    button {
      top: 6px;
      right: 6px;
    }
  `,
}
