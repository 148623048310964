import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { selectSubscriptionList } from 'root-redux/selects/common'
import { selectHasUserEmail } from 'root-redux/selects/user'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { useScrollToAnchor } from 'hooks/useScrollToAnchor'
import { useViewportValue } from 'hooks/useViewportValue'

import { compareSubscriptionList } from 'helpers/compareSubscriptionList'
import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import {
  BenefitsVariant1,
  BenefitsVariant3,
} from 'modules/subscriptions/components/google/Benefits'
import { APP_BENEFITS_V2 } from 'modules/subscriptions/components/google/Benefits/constants'
import { GoodHands } from 'modules/subscriptions/components/google/GoodHands'
import { StepsVariant1 } from 'modules/subscriptions/components/google/Steps'
import { SubscriptionsBlockVariant1 } from 'modules/subscriptions/components/google/SubscriptionsBlockVariant1'
import { BEENEFITS_V2 } from 'modules/subscriptions/components/google/SubscriptionsBlockVariant1/constants'
import { SAFETY_6_STEPS_DATA } from 'modules/subscriptions/constants'

import { ReviewsSlider3 } from 'components/ReviewsSlider'
import { Security } from 'components/Security'
import { Spinner } from 'components/Spinner'
import { SwiperSlideVariant1 } from 'components/SwiperSlide'

import line from 'assets/images/average-line.png'

import { goTo } from 'browser-history'
import { CDN_FOLDER_LINK, Images, SubscriptionTag } from 'root-constants/common'

import { StyledSubscriptionsGoogleVariant5 as S } from './SubscriptionsGoogleVariant5.styles'

export const SubscriptionsGoogleVariant5: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const scrollToElement = useRef<HTMLParagraphElement>(null)
  const subscriptions = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )
  const hasUserEmail = useSelector(selectHasUserEmail)

  const [phoneNumber, setPhoneNumber] = useState('')

  useGetPrices({ tags: SubscriptionTag.NO_TAX })
  useViewportValue()
  const { hasPrices } = usePaywall(nextPagePath)
  const { currentPaymentPageId } = useGetPageInfo()
  useScrollToAnchor(hasPrices)

  useLayoutEffect(() => {
    if (hasUserEmail) {
      goTo({ pathname: currentPaymentPageId, search })
    }
  }, [search, hasUserEmail, currentPaymentPageId])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
    googleAnalyticsLogger.logCheckoutStarted(subscriptions)
  }, [subscriptions])

  const handleButtonScroll = useCallback(() => {
    scrollToElement.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }, [])

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper id="1">
      <S.BurgerMenu />
      <S.Content>
        <S.Column>
          <S.Title>
            {t`subscriptions.subscriptionsGoogle.variant5.title`}
          </S.Title>
          <S.Text ref={scrollToElement}>
            {t`subscriptions.subscriptionsGoogle.variant5.keepSafe`}
          </S.Text>

          <S.PhoneForm
            buttonText="subscriptions.subscriptionsGoogle.variant1.locateTheNumber"
            pageId={pageId}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
        </S.Column>

        <S.PhoneImageContainer>
          <img
            src={`${CDN_FOLDER_LINK}${Images.GOOGLE_SUBS_PHONE_ALEX_FAMILY}.jpg`}
            alt="phone"
          />
        </S.PhoneImageContainer>

        <S.Column>
          <GoodHands />
        </S.Column>

        <BenefitsVariant1 />
        <S.Column marginTop={48}>
          <BenefitsVariant3 benefits={APP_BENEFITS_V2} textAlign="start" />
          <S.Subtitle>
            {t`subscriptions.subscriptionsGoogle.variant5.subtitle`}
          </S.Subtitle>
        </S.Column>
        <SwiperSlideVariant1 />
        <S.Column>
          <S.Button
            type="button"
            onClick={handleButtonScroll}
            marginBottom={48}
          >
            {t`subscriptions.subscriptionsGoogle.variant1.subscribe`}
          </S.Button>

          <S.StepsTitle id="3">{t`onboarding.introGoogle4.howItWorks`}</S.StepsTitle>
          <StepsVariant1
            marginBottom={15}
            stepsData={SAFETY_6_STEPS_DATA}
            image={line}
            height={406}
          />
        </S.Column>
        <ReviewsSlider3 />
        <S.Column>
          <S.PhoneFormTitle id="4">{t`onboarding.introGoogle3.phoneText2`}</S.PhoneFormTitle>
          <S.PhoneForm
            buttonText="onboarding.introGoogle4.phoneButton"
            pageId={pageId}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
        </S.Column>
        <SubscriptionsBlockVariant1
          ref={scrollToElement}
          benefits={BEENEFITS_V2}
        />

        <S.Column>
          <Security />
        </S.Column>
      </S.Content>
    </S.Wrapper>
  )
}
