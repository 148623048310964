import React from 'react'

import { TPageProps } from 'models/common.model'

import { RadioOptionPage } from 'pages/options-page/RadioOptionPage'

import { OPTION_DATA, TITLE_PATH_2 } from './constants'

export const GeoTrackingVariant2: React.FC<TPageProps> = (props) => (
  <RadioOptionPage
    {...props}
    options={OPTION_DATA}
    titlePath={TITLE_PATH_2}
    marginBottom={89}
  />
)
