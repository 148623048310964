import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { eventLogger } from 'services/eventLogger.service'

import { PaymentMethod } from 'modules/payment/constants'

import { SvgImage } from 'components/SvgImage'

import applePayBlackIcon from 'assets/images/sprite/apple-pay-mini-black.svg'
import applePayIcon from 'assets/images/sprite/apple-pay-mini.svg'
import creditCardBlackIcon from 'assets/images/sprite/credit-card-black.svg'
import creditCardIcon from 'assets/images/sprite/credit-card.svg'
import crossIcon from 'assets/images/sprite/cross.svg'
import googlePayBlackIcon from 'assets/images/sprite/google-pay-mini-black.svg'
import googlePayIcon from 'assets/images/sprite/google-pay-mini.svg'

import { StyledSelectPaymentMethod as S } from './SelectPaymentMethod.styles'

type TSelectPaymentMethod = {
  onClose: () => void
  onChange: (event) => void
}

export const SelectPaymentMethod: React.FC<TSelectPaymentMethod> = ({
  onClose,
  onChange,
}) => {
  const { t } = useTranslation()
  const { paymentMethod, paymentRequestButtonType } = usePurchaseStore()

  const handleClose = useCallback(() => {
    eventLogger.logPaymentMethodPopupClose()
    onClose()
  }, [onClose])

  return (
    <S.Wrapper>
      <S.PaymentMethodPanel>
        <S.Header>
          <S.Title>{t`payment.variant4.chooseMethod`}</S.Title>
          <S.CloseButton onClick={handleClose}>
            <SvgImage svg={crossIcon} width={24} />
          </S.CloseButton>
        </S.Header>

        <S.PaymentMethodControls>
          {paymentRequestButtonType === PaymentMethod.APPLE_PAY && (
            <S.Label>
              <S.Checkbox
                type="radio"
                name="paymentMethod"
                onChange={onChange}
                defaultChecked={paymentMethod === PaymentMethod.APPLE_PAY}
                value={PaymentMethod.APPLE_PAY}
              />
              <S.PaymentMethodControl>
                <SvgImage
                  svg={
                    paymentMethod === PaymentMethod.APPLE_PAY
                      ? applePayBlackIcon
                      : applePayIcon
                  }
                  width={30}
                />
                <span>{t('payment.variant4.applePay')}</span>
              </S.PaymentMethodControl>
            </S.Label>
          )}
          {paymentRequestButtonType === PaymentMethod.GOOGLE_PAY && (
            <S.Label>
              <S.Checkbox
                type="radio"
                name="paymentMethod"
                onChange={onChange}
                defaultChecked={paymentMethod === PaymentMethod.GOOGLE_PAY}
                value={PaymentMethod.GOOGLE_PAY}
              />
              <S.PaymentMethodControl>
                <SvgImage
                  svg={
                    paymentMethod === PaymentMethod.GOOGLE_PAY
                      ? googlePayBlackIcon
                      : googlePayIcon
                  }
                  width={32}
                />
                <span>{t('payment.variant4.googlePay')}</span>
              </S.PaymentMethodControl>
            </S.Label>
          )}
          <S.Label>
            <S.Checkbox
              type="radio"
              name="paymentMethod"
              value={PaymentMethod.CREDIT_CARD}
              onChange={onChange}
              defaultChecked={paymentMethod === PaymentMethod.CREDIT_CARD}
            />
            <S.PaymentMethodControl>
              <SvgImage
                svg={
                  paymentMethod === PaymentMethod.CREDIT_CARD
                    ? creditCardBlackIcon
                    : creditCardIcon
                }
                width={15}
              />
              <span>{t('payment.variant4.creditCard')}</span>
            </S.PaymentMethodControl>
          </S.Label>
        </S.PaymentMethodControls>
      </S.PaymentMethodPanel>
    </S.Wrapper>
  )
}
