import styled from 'styled-components'

import { Button } from 'components/Button'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { MediaBreakpoint } from 'root-constants/common'

type TProps = {
  isESLanguage: boolean
}
export const StyledAppBenefitVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    height: 100dvh;
    padding-bottom: 40px;
    background: #0b0217;
  `,
  ImageContainer: styled.div`
    margin: 0 auto;
    aspect-ratio: 375/418;
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
  `,
  Title: styled.h1<TProps>`
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    text-align: center;
    margin-top: ${({ isESLanguage }) => (isESLanguage ? -20 : 3)}px;
    margin-bottom: 7px;
    color: ${({ theme }) => theme.colors.primaryText};
  `,
  Subtitle: styled.h3`
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    text-align: center;
    color: #bcc9d0;
  `,
  Button: styled(Button)`
    margin-top: auto;
    color: ${({ theme }) => theme.colors.activeTextColor};
    background-color: ${({ theme }) => theme.colors.activeBackground};
  `,
}
