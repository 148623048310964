import { Confidence } from 'root-constants/common'

export const TITLE_PATH = `onboarding.shareLocation.question`

export const OPTION_DATA = [
  { value: Confidence.YES, text: 'commonComponents.yes' },
  { value: Confidence.NO, text: 'commonComponents.no' },
  { value: Confidence.NOT_SURE, text: 'commonComponents.notSure' },
  { value: Confidence.SOMETIMES, text: 'commonComponents.sometimes' },
]
