import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import 'react-international-phone/style.css'
import { useSelector } from 'react-redux'

import { selectUserCountryCode } from 'root-redux/selects/user'

import { useScrollToAnchor } from 'hooks/useScrollToAnchor'
import { useViewportValue } from 'hooks/useViewportValue'

import { checkIsPhoneValid } from 'helpers/phone'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import {
  BENEFITS_DATA_GOOGLE_4,
  PAGE_NAME,
  POSSIBILITIES_DATA,
  STEPS_DATA,
} from 'pages/intro-google/constants'

import { Button } from 'components/Button'
import { ProgressBarListModal } from 'components/ProgressBarList'
import { ReviewsSlider2 } from 'components/ReviewsSlider'
import { REVIEWS_DATA_SLIDER_VARIANT_3 } from 'components/ReviewsSlider/constants'
import { Security } from 'components/Security'
import { SwiperSlideVariant1 } from 'components/SwiperSlide'

import leftLeaf from 'assets/images/left-golden-leaf.png'
import lineIcon from 'assets/images/line-icon.png'
import rightLeaf from 'assets/images/right-golden-leaf.png'

import { StyledIntroGoogleVariant4 as S } from './IntroGoogleVariant4.styles'

export const IntroGoogleVariant4: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const countryCode = useSelector(selectUserCountryCode)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const isValid = checkIsPhoneValid(phoneNumber)

  useViewportValue()
  useScrollToAnchor(true)

  const handleChange = useCallback((value: string) => {
    setPhoneNumber(value)
  }, [])

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      if (!isFocused && !isValid) {
        setIsFocused(true)
        return
      }
      if (isFocused && !isValid) {
        return
      }
      setIsSubmitted(true)
      eventLogger.logCheckNumberClicked()
    },
    [isFocused, isValid],
  )

  return (
    <S.Wrapper id="1">
      <S.BurgerMenu />
      <S.Title>
        <Trans i18nKey="onboarding.introGoogle4.title" />
      </S.Title>
      <S.Column>
        <S.TrackerWrapper>
          <img src={leftLeaf} alt="icon" />
          <p>
            <Trans i18nKey="onboarding.introGoogle4.strongPhoneTracker" />
          </p>
          <img src={rightLeaf} alt="icon" />
        </S.TrackerWrapper>
        <S.PhoneForm onSubmit={handleSubmit}>
          <S.PhoneText>{t`onboarding.introGoogle3.phoneText2`}</S.PhoneText>
          <S.PhoneNumberInput
            defaultCountry={countryCode.toLowerCase()}
            value={phoneNumber}
            onChange={handleChange}
            onFocus={handleFocus}
          />
          {isFocused && !isValid && (
            <S.PhoneErrorText>
              {t`onboarding.introGoogle3.phoneError`}
            </S.PhoneErrorText>
          )}
          <S.Text>{t`onboarding.introGoogle4.worksWithAll`}</S.Text>
          <Button type="submit">{t`onboarding.introGoogle3.phoneButton`}</Button>
        </S.PhoneForm>
      </S.Column>
      <S.Subtitle id="2">
        <Trans i18nKey="onboarding.introGoogle4.famioAllows" />
      </S.Subtitle>
      <S.BenefitsWrapper>
        <ul>
          {BENEFITS_DATA_GOOGLE_4.map(({ title, subtitle }) => (
            <S.ListTitle key={title}>
              <Trans i18nKey={title} />
              <S.ListSubtitle>
                <Trans i18nKey={subtitle} />
              </S.ListSubtitle>
            </S.ListTitle>
          ))}
        </ul>
      </S.BenefitsWrapper>
      <SwiperSlideVariant1 />
      <S.PossibilitiesWrapper>
        <S.PossibilitiesTitle>{t`onboarding.introGoogle4.title2`}</S.PossibilitiesTitle>
        <S.PossibilitiesContainer>
          {POSSIBILITIES_DATA.map(({ image, title, subtitle }) => (
            <div key={title}>
              <S.PossibilitiesIconContainer>
                <img src={image} alt="icon" />
              </S.PossibilitiesIconContainer>
              <S.ContainerTitle>{t(title)}</S.ContainerTitle>
              <S.ContainerSubtitle>{t(subtitle)}</S.ContainerSubtitle>
            </div>
          ))}
        </S.PossibilitiesContainer>
      </S.PossibilitiesWrapper>
      <S.Subtitle marginBottom={24}>
        <Trans i18nKey="onboarding.introGoogle4.joinMillions" />
      </S.Subtitle>
      <ReviewsSlider2 reviews={REVIEWS_DATA_SLIDER_VARIANT_3} />
      <S.Subtitle marginTop={24} id="3">
        <Trans i18nKey="onboarding.introGoogle4.howItWorks" />
      </S.Subtitle>
      <S.StepsWrapper>
        <img src={lineIcon} alt="icon" />
        <S.StepsContent>
          {STEPS_DATA.map(({ title, subtitle }) => (
            <div key={title}>
              <S.StepsTitle>{t(title)}</S.StepsTitle>
              <S.StepsSubtitle>{t(subtitle)}</S.StepsSubtitle>
            </div>
          ))}
        </S.StepsContent>
      </S.StepsWrapper>
      <S.BottomPhoneForm onSubmit={handleSubmit} id="4">
        <S.PhoneText>{t`onboarding.introGoogle3.phoneText2`}</S.PhoneText>
        <S.PhoneNumberInput
          defaultCountry={countryCode.toLowerCase()}
          value={phoneNumber}
          onChange={handleChange}
          onFocus={handleFocus}
        />
        {isFocused && !isValid && (
          <S.PhoneErrorText>
            {t`onboarding.introGoogle3.phoneError`}
          </S.PhoneErrorText>
        )}
        <S.Button type="submit">{t`onboarding.introGoogle4.phoneButton`}</S.Button>
      </S.BottomPhoneForm>
      <S.SecurityWrapper>
        <Security />
      </S.SecurityWrapper>
      <ProgressBarListModal
        isShown={isSubmitted}
        nextPagePath={nextPagePath}
        pageId={pageId}
        pageName={PAGE_NAME}
        phoneNumber={phoneNumber}
      />
    </S.Wrapper>
  )
}
