import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { useProductId } from 'hooks/useProductId'
import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { compareSubscriptionList } from 'helpers/compareSubscriptionList'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import {
  PaymentFlowsGoogle,
  PaymentFlowsGoogleVariant2,
} from 'modules/payment/components/PaymentFlowsGoogle'

import { DesktopProvider } from 'components/DesktopProvider'
import { Spinner } from 'components/Spinner'
import { StripePaymentProcessing } from 'components/StripePaymentProcessing'

import { Cohort, PagePath, SubscriptionTag } from 'root-constants/common'

import { StyledPaymentVariant2 as S } from './PaymentVariant2.styles'

export const PaymentVariant2: React.FC<TPageProps> = () => {
  const {
    email,
    cohort,
    cohortToUse,
    uuid,
    selectedSubscription,
    hasSubscription,
    isPaymentFlowsShown,
    stripeAccountId,
    stripeAccountName,
    screenName,
    threeDSecureIframeUrl,
  } = usePurchaseStore()

  const productId = useProductId()
  const subscriptions = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )
  const { hasPrices } = usePaywall('')
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  useGetPrices({ tags: SubscriptionTag.NO_TAX })

  useEffect(() => {
    if (!selectedSubscription) return

    window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })
    googleAnalyticsLogger.logPageView(`${PagePath.SUBSCRIPTIONS}/${cohort}`)
    googleAnalyticsLogger.logCheckoutStarted(subscriptions)
  }, [cohort, selectedSubscription, uuid, subscriptions])

  useEffect(() => {
    if (hasSubscription || !selectedSubscription) return

    eventLogger.logPurchaseShown({
      productId,
      screenName,
      stripeAccountName,
      stripeAccountId,
    })
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    window.ttq.track('AddToCart')
  }, [
    email,
    hasSubscription,
    productId,
    screenName,
    selectedSubscription,
    stripeAccountId,
    stripeAccountName,
    isPersonalDataAllowed,
  ])

  return (
    <DesktopProvider>
      <S.Wrapper>
        {threeDSecureIframeUrl ? (
          <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
        ) : (
          <>
            <StripePaymentProcessing />
            <S.Content>
              {cohortToUse === Cohort.GOOGLE_4 ? (
                <PaymentFlowsGoogleVariant2 />
              ) : (
                <PaymentFlowsGoogle />
              )}
            </S.Content>
          </>
        )}
        {(!isPaymentFlowsShown || !hasPrices) && <Spinner />}
      </S.Wrapper>
    </DesktopProvider>
  )
}
