import React from 'react'

import { TPageProps } from 'models/common.model'

import { RadioOptionPage } from 'pages/options-page/RadioOptionPage'

import { SIMPLE_OPTION_DATA } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { TITLE_PATH } from './constants'

export const PartnersInDangerVariant1: React.FC<TPageProps> = (props) => (
  <RadioOptionPage
    {...props}
    pageName={PageId.PARTNERS_IN_DANGER_1}
    options={SIMPLE_OPTION_DATA}
    titlePath={TITLE_PATH}
    wrapperPaddingTop={100}
    columnPaddingTop={24}
    marginBottom={40}
  />
)
