import { Cohort } from 'root-constants/common'

export const COHORTS_WITH_DISCLAIMER: string[] = [
  Cohort.GOOGLE_4,
  Cohort.GOOGLE_7,
  Cohort.GOOGLE_8,
  Cohort.GOOGLE_9,
  Cohort.GOOGLE_10,
  Cohort.GOOGLE_15,
  Cohort.GOOGLE_16,
]
