import React, {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { resetErrorAction } from 'root-redux/actions/common'
import {
  GET_STATUS,
  SEND_USER_EMAIL,
  sendUserEmailAction,
} from 'root-redux/actions/user'
import {
  selectActionList,
  selectCurrentVariantCohortToUse,
  selectError,
} from 'root-redux/selects/common'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useEmailInputField } from 'hooks/useEmailInputField'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { SUBTITLES } from 'modules/email/constants'

import { DesktopProvider } from 'components/DesktopProvider'
import { Input } from 'components/Input'
import { Modal } from 'components/Modal'
import { PhoneNumberLabel } from 'components/PhoneNumberLabel'
import { PoliciesGroup } from 'components/PoliciesGroup'
import { Spinner } from 'components/Spinner'

import emailImageDesktop from 'assets/images/email-desktop.jpg'
import lockImg from 'assets/images/sprite/lock.svg'

import { Cohort } from 'root-constants/common'

import { getFilteredEmailDomains } from '../../helpers'
import { StyledEmailVariant1 as S } from './EmailVariant1.styles'

export const EmailVariant1: React.FC<TPageProps> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const fetchingActionsList = useSelector(selectActionList)
  const error = useSelector(selectError)

  const [areEmailTipsVisible, setAreEmailTipsVisible] = useState(false)
  const [isErrorModalShown, setIsErrorModalShown] = useState(false)

  const { title, subtitle, buttonText } = useDynamicOBConfig()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()
  const hasPhoneNumberPage = useFeatureIsOn('car_266_phone_number_test')
  const [email, , validateEmail] = useEmailInputField()
  const deferredEmail = useDeferredValue(email.value)
  const { userPhoneNumber } = useUserData()

  const isStatusFetching = useMemo(
    () =>
      fetchingActionsList?.includes(SEND_USER_EMAIL) ||
      fetchingActionsList?.includes(GET_STATUS),
    [fetchingActionsList],
  )

  const isButtonDisabled = useMemo(
    () => !email.value || !email.isValid || isStatusFetching,
    [email.isValid, email.value, isStatusFetching],
  )

  const filteredEmailDomains = useMemo(
    () => getFilteredEmailDomains(deferredEmail),
    [deferredEmail],
  )

  const subtitleText = useMemo(() => {
    if (!userPhoneNumber && hasPhoneNumberPage) {
      return 'onboarding.email.subtitle'
    }

    return userPhoneNumber
      ? SUBTITLES[cohortToUse]?.alternative
      : SUBTITLES[cohortToUse]?.common
  }, [cohortToUse, hasPhoneNumberPage, userPhoneNumber])

  const hasPhoneLabel = useMemo(
    () => cohortToUse === Cohort.GOOGLE_4,
    [cohortToUse],
  )

  useEffect(() => {
    eventLogger.logEmailPageShown()
  }, [])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()

      if (!email.isValid || isStatusFetching) return

      dispatch(
        sendUserEmailAction({
          email: email.value,
          isPersonalDataAllowed,
          unsuccessCallback: () => setIsErrorModalShown(true),
        }),
      )
    },
    [
      dispatch,
      email.isValid,
      email.value,
      isStatusFetching,
      isPersonalDataAllowed,
    ],
  )

  const handleChange = useCallback(
    ({ target: { value } }) => {
      validateEmail(value)
      setAreEmailTipsVisible(true)
    },
    [validateEmail],
  )

  const handlePrefilledEmail = useCallback(
    ({ currentTarget: { value } }) => {
      validateEmail(value)
      setAreEmailTipsVisible(false)
    },
    [validateEmail],
  )

  return isStatusFetching ? (
    <Spinner />
  ) : (
    <DesktopProvider imageSrc={emailImageDesktop}>
      <S.Wrapper>
        {hasPhoneLabel && <PhoneNumberLabel />}
        <form onSubmit={handleSubmit}>
          <S.Content hasPhoneLabel={hasPhoneLabel}>
            <S.Title>
              {title || (
                <Trans
                  i18nKey={
                    cohortToUse === Cohort.KIDS_1
                      ? t`onboarding.email.title2`
                      : t`onboarding.email.title`
                  }
                />
              )}
            </S.Title>
            <S.Subtitle>
              {subtitle || <Trans i18nKey={subtitleText} />}
            </S.Subtitle>

            <S.InputWrapper>
              <Input
                value={email.value}
                isValid={email.isValid}
                validationText={email.validationText}
                placeholder={t`onboarding.email.placeholder`}
                autoComplete="off"
                onChange={handleChange}
              />
              {areEmailTipsVisible && (
                <S.AutoCompleteWrapper>
                  {filteredEmailDomains.map((value) => (
                    <S.AutoCompleteButton
                      type="button"
                      key={value}
                      value={value}
                      onMouseDown={handlePrefilledEmail}
                    >
                      <S.AutoCompleteButtonTextWrapper>
                        {value}
                      </S.AutoCompleteButtonTextWrapper>
                    </S.AutoCompleteButton>
                  ))}
                </S.AutoCompleteWrapper>
              )}
            </S.InputWrapper>
            <S.Note>
              <S.LockImg svg={lockImg} />
              <S.Description>{t`onboarding.email.note`}</S.Description>
            </S.Note>
            <S.Footer data-is-auto-complete-shown={areEmailTipsVisible}>
              <S.Button type="submit" disabled={isButtonDisabled}>
                {buttonText || t`actions.continue`}
              </S.Button>
              <PoliciesGroup />
            </S.Footer>
          </S.Content>
        </form>
      </S.Wrapper>
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
      >
        {error}
      </Modal>
    </DesktopProvider>
  )
}
