import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useGetFlowInfo } from 'hooks/useGetFlowInfo'
import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { getIsMobileDevice } from 'helpers/getIsMobileDevice'

import { ProgressbarWithNumeration } from 'components/ProgressbarWithNumeration'

import logo from 'assets/images/logo.png'

import { Cohort } from 'root-constants/common'

import { ProgressBar } from '../ProgressBar'
import { StyledHeader as S } from './Header.styles'

export const Header: React.FC = () => {
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const isMobile = getIsMobileDevice()
  const { hasHeader } = useGetPageInfo()
  const { hasDesktopVersion } = useGetFlowInfo()

  const isSafetyCohort = useMemo(
    () => cohortToUse === Cohort.SAFETY_6,
    [cohortToUse],
  )

  if (!hasHeader || (hasDesktopVersion && !isMobile)) return null

  return (
    <S.HeaderWrapper>
      <S.Header>
        <img height="30" src={logo} alt="logo" />
      </S.Header>
      {isSafetyCohort ? <ProgressbarWithNumeration /> : <ProgressBar />}
    </S.HeaderWrapper>
  )
}
