import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useUserData } from 'hooks/useUserData'
import { useVatInfo } from 'hooks/useVatInfo'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { SubheaderWithTimer } from 'modules/subscriptions/components/common/SubheaderWithTimer'
import { Timer } from 'modules/subscriptions/components/common/Timer'
import { MAIN_4_COHORT_DATA, PLAN_ITEMS } from 'modules/subscriptions/constants'

import { Guarantee } from 'components/Guarantee'
import { ReviewsSlider, ReviewsSliderDynamic } from 'components/ReviewsSlider'
import { Security } from 'components/Security'
import { Spinner } from 'components/Spinner'

import { CDN_FOLDER_LINK, SubscriptionTag } from 'root-constants/common'

import { Benefits } from '../../components/benefits'
import { StyledTrialSubscriptionsVariant1 as S } from './TrialSubscriptionsVariant1.styles'

export const TrialSubscriptionsVariant1: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const scrollToElement = useRef<HTMLDivElement>(null)
  const timerTriggerElementRef = useRef(null)

  const { subscriptions, language } = usePurchaseStore()
  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)
  const {
    title,
    subtitle,
    buttonText,
    subscriptionBlockType,
    subscriptionBlockTitle,
    customerReviews,
  } = useDynamicPaywallConfig()
  const hasIncludedVat = useVatInfo()
  const { userGoals } = useUserData()

  useGetPrices({
    tags: hasIncludedVat ? SubscriptionTag.TAX : SubscriptionTag.NO_TAX,
  })

  const prioritizedUsersGoal = useMemo(() => {
    const [topPriorityData] = MAIN_4_COHORT_DATA.filter(({ userGoal }) =>
      userGoals.includes(userGoal),
    )
    return topPriorityData || MAIN_4_COHORT_DATA[0]
  }, [userGoals])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  const handleTimerButtonClick = useCallback(() => {
    scrollToElement.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }, [])

  return !hasPrices ? (
    <Spinner />
  ) : (
    <>
      <SubheaderWithTimer
        elemForComparisonRef={timerTriggerElementRef}
        onClick={handleTimerButtonClick}
      />
      <S.Wrapper>
        <S.Column marginBottom={40}>
          <S.Title>{title || t(prioritizedUsersGoal.data.title)}</S.Title>

          <S.Text>
            {subtitle || <Trans i18nKey={prioritizedUsersGoal.data.subtitle} />}
          </S.Text>
          <Timer />
        </S.Column>

        <S.ImageWrapper aspectRatio={prioritizedUsersGoal.data.aspectRatio}>
          <img
            ref={timerTriggerElementRef}
            src={`${CDN_FOLDER_LINK}${prioritizedUsersGoal.data.img}_${language}.webp`}
            alt={prioritizedUsersGoal.userGoal}
          />
        </S.ImageWrapper>

        <S.Column data-has-bg>
          <Benefits />
          <S.Sharing>
            <Trans i18nKey="subscriptions.variant2.shareBlock" />
          </S.Sharing>
          <S.Subtitle>
            {subscriptionBlockTitle || t`subscriptions.variant2.titlePlanBlock`}
          </S.Subtitle>
          <S.SelectPlanBlock
            ref={scrollToElement}
            onSelect={handleSelectSubscription}
            SelectPlanItem={PLAN_ITEMS[subscriptionBlockType]}
          />
          <S.Button onClick={handleShowPayment} marginBottom={12}>
            {buttonText || t`actions.getPlan`}
          </S.Button>
          <S.SubscriptionDescription />
        </S.Column>
        <S.Column>
          <Guarantee />
          {customerReviews?.length ? (
            <ReviewsSliderDynamic />
          ) : (
            <ReviewsSlider />
          )}
          <S.Button onClick={handleShowPayment} marginBottom={24}>
            {buttonText || t`actions.start`}
          </S.Button>
          <Security />
        </S.Column>
      </S.Wrapper>
    </>
  )
}
