import bellImage from 'assets/images/bell-icon.png'
import carImage from 'assets/images/car-icon.png'
import locationImage from 'assets/images/location-icon-blue.png'
import lockImage from 'assets/images/lock-icon-blue.png'
import sosImage from 'assets/images/sos-icon.png'

export const BENEFITS = [
  { image: locationImage, text: 'onboarding.benefits.stayInformed' },
  { image: bellImage, text: 'onboarding.benefits.instantNotifications' },
  { image: carImage, text: 'onboarding.benefits.drivingMode' },
  { image: lockImage, text: 'onboarding.benefits.beConnected' },
  { image: sosImage, text: 'onboarding.benefits.sosAlerts' },
]
