import styled from 'styled-components'

import { PhoneForm } from 'modules/subscriptions/components/google/PhoneForm'

import { BurgerMenu } from 'components/BurgerMenu'
import { Button } from 'components/Button'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

type TPhoneFormProps = {
  marginBottom?: number
}

type TImageWrapperProps = {
  imageUrl: string
}

type TTextProps = {
  fontSize?: number
}

export const StyledSubscriptionsGoogleVariant4 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
    padding-bottom: 25px;
  `,
  BurgerMenu: styled(BurgerMenu)`
    button {
      top: 6px;
      right: 6px;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        top: 3px;
        right: 3px;
      }
    }
  `,
  Content: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    height: calc(100dvh - 56px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: ${Color.WHITE};

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  IntroMediaContainer: styled.div<TImageWrapperProps>`
    width: 100%;
    aspect-ratio: 375/495;
    min-height: 530px;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 24px;
    background-image: ${({ imageUrl }) => `url(${imageUrl})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  SaleTitle: styled.h2<TTextProps>`
    margin-bottom: 8px;
    text-align: center;
    font-size: ${({ fontSize }) => fontSize || 30}px;
    font-weight: 800;
    line-height: 38px;
  `,
  SaleTextContainer: styled.div`
    display: flex;
    place-content: center;
  `,
  SaleText: styled.p`
    padding-right: 8px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  `,
  SaleDiscount: styled.p`
    color: ${Color.ACCENT_VIOLET};
    font-size: 26px;
    font-weight: 800;
    line-height: 26px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    width: 331px;
  `,
  Title: styled.h1`
    margin-bottom: 12px;
    font-size: 32px;
    font-weight: 800;
    line-height: 38px;
    text-align: center;
    margin-top: 24px;

    strong {
      font-size: 32px;
      font-weight: 800;
      line-height: 38px;
      color: ${Color.BLUE};
    }
  `,
  Text: styled.p`
    margin-bottom: 32px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  `,
  PhoneForm: styled(PhoneForm)<TPhoneFormProps>`
    margin-bottom: ${({ marginBottom }) => `${marginBottom || 0}px`};

    button {
      margin-top: 0;
    }
  `,
  PhoneImageContainer: styled.div`
    width: 100%;
    aspect-ratio: 375/328;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin-top: -20px;
    z-index: -1;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  ButtonContainer: styled.div`
    margin: -24px 0 16px;
  `,
  Button: styled(Button)`
    background: linear-gradient(
      95deg,
      #ff2d55 -2.36%,
      #f085f2 41.02%,
      #6336ff 84.16%
    );
  `,
  SubscribeButton: styled(Button)`
    margin-bottom: 32px;
  `,
  VideoContainer: styled.div`
    position: relative;
    padding-bottom: 56.25%;
    z-index: 1;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
    margin-bottom: 48px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  `,
  StepsTitle: styled.h3`
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 30px;
  `,
  PhoneFormTitle: styled.h3`
    margin-bottom: 16px;
    padding: 0 10px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
  `,
}
