import styled from 'styled-components'

import { SubscriptionDescriptionTrial } from 'modules/subscriptions/components/common/SubscriptionDescription'

import { Button } from 'components/Button'
import { Security } from 'components/Security'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledSubscriptionsVariant4 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
  `,
  Title: styled.h1`
    margin: 30px 0 12px;
    color: ${({ theme }) => theme.colors.primaryText};
    text-align: center;
    font-size: 32px;
    font-weight: 800;
    line-height: 44px;
  `,
  Subtitle: styled.h2`
    margin-bottom: 28px;
    color: ${({ theme }) => theme.colors.secondaryText};
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
  `,
  ImageWrapper: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
    margin-top: 40px;
    aspect-ratio: 375/302;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  SubscriptionsTitle: styled.h2`
    margin-bottom: 40px;
    color: ${({ theme }) => theme.colors.primaryText};
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
  `,
  Button: styled(Button)`
    height: 56px;
    margin-top: 28px;
    line-height: 56px;
    text-transform: capitalize;
  `,
  SubscriptionDescriptionTrial: styled(SubscriptionDescriptionTrial)`
    margin: 12px 0 27px;
  `,
  Security: styled(Security)`
    margin-top: 40px;
  `,
}
