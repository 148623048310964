import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledPhoneNumberLabel = {
  Wrapper: styled.div`
    width: 175px;
    margin: 0 auto;
    padding: 8px 10px;
    border-radius: 0 0 12px 12px;
    background-color: ${Color.DEFAULT_TEXT};
    box-shadow: 0 4px 14px 0 rgba(45, 67, 82, 0.3);
  `,
  Text: styled.p`
    color: ${Color.WHITE};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    strong {
      font-weight: 800;
    }
  `,
}
