import React, { useCallback, useRef } from 'react'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'

import { TPageProps } from 'models/common.model'

import { BenefitsVariant2 } from 'modules/subscriptions/components/benefits'
import { PLAN_ITEMS } from 'modules/subscriptions/constants'

import { Guarantee } from 'components/Guarantee'
import { Spinner } from 'components/Spinner'

import peopleImg from 'assets/images/subscriptions-people.webp'

import { SubscriptionTag } from 'root-constants/common'

import { SubheaderWithTimer } from '../../components/common/SubheaderWithTimer'
import { Timer } from '../../components/common/Timer'
import { StyledSubscriptions as S } from './SubscriptionsVariant3.styles'

export const SubscriptionsVariant3: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const scrollToElement = useRef<HTMLDivElement>(null)
  const timerTriggerElementRef = useRef(null)

  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)

  useGetPrices({ tags: SubscriptionTag.NO_TAX })
  const { subscriptionBlockType } = useDynamicPaywallConfig()

  const handleTimerButtonClick = useCallback(() => {
    scrollToElement.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }, [])

  return !hasPrices ? (
    <Spinner />
  ) : (
    <>
      <SubheaderWithTimer
        elemForComparisonRef={timerTriggerElementRef}
        onClick={handleTimerButtonClick}
      />
      <S.Wrapper>
        <S.Column>
          <S.Title>Keep Your Family Safe</S.Title>
          <S.Subtitle>Keep calm and stay connected with your family</S.Subtitle>
          <Timer />
        </S.Column>

        <S.ImageContainer>
          <img ref={timerTriggerElementRef} src={peopleImg} alt="people" />
        </S.ImageContainer>

        <S.Column>
          <BenefitsVariant2 />
          <S.SubscriptionsTitle>Invest in Your Peace</S.SubscriptionsTitle>
          <S.SelectPlanBlock
            ref={scrollToElement}
            onSelect={handleSelectSubscription}
            SelectPlanItem={PLAN_ITEMS[subscriptionBlockType]}
          />
          <S.Button onClick={handleShowPayment}>Start My Plan</S.Button>
          <S.SubscriptionDescription />
          <Guarantee />
          <S.Button onClick={handleShowPayment}>Start My Plan</S.Button>
          <S.Security />
        </S.Column>
      </S.Wrapper>
    </>
  )
}
