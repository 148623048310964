import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrency,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectUpgradeAmountToPay,
} from 'modules/payment/redux/selects'
import { DISCOUNT_UPSELL } from 'modules/subscriptions/constants'

import {
  CURRENCY_SYMBOLS,
  PERIOD_NAME_TO_MARKUP_MAP,
} from 'root-constants/common'

import { StyledPlanBlockVariant1 as S } from './PlanBlockVariant1.styles'

export const PlanBlockVariant1: React.FC = () => {
  const { t } = useTranslation()
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const currency = useSelector(selectCurrency)
  const amountToPay = useSelector(selectUpgradeAmountToPay)

  const oldPrice = useMemo(() => amountToPay * 2, [amountToPay])

  return (
    <S.Wrapper>
      <S.Title>{t('subscriptions.purchaseUpgrade.plan.title')}</S.Title>
      <S.PriceContainer
        data-plan-period={t('subscriptions.purchaseUpgrade.plan.period', {
          quantity: periodQuantity,
          period: t(PERIOD_NAME_TO_MARKUP_MAP[periodName], {
            count: periodQuantity,
          }),
        })}
      >
        <S.OldPriceText>
          {t('subscriptions.purchaseUpgrade.plan.oldPrice')}
        </S.OldPriceText>
        <S.OldPrice>
          {t('subscriptions.planBlock.price', {
            price: oldPrice,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          })}
        </S.OldPrice>

        <S.Discount>
          {t('subscriptions.purchaseUpgrade.plan.discount', {
            discount: DISCOUNT_UPSELL,
          })}
        </S.Discount>

        <S.PriceText>
          {t('subscriptions.purchaseUpgrade.plan.newPrice')}
        </S.PriceText>
        <S.Price>
          {t('subscriptions.planBlock.price', {
            price: amountToPay,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          })}
        </S.Price>
      </S.PriceContainer>
    </S.Wrapper>
  )
}
