import React from 'react'

import { TPageProps } from 'models/common.model'

import { RadioOptionPage } from 'pages/options-page/RadioOptionPage'

import { ALTERNATIVE_CONFIDENCE_OPTION_DATA } from 'root-constants/common'

import { TITLE_PATH_CHILD_LOCATION_3 } from './constants'

export const ChildLocationVariant3: React.FC<TPageProps> = (props) => (
  <RadioOptionPage
    {...props}
    options={ALTERNATIVE_CONFIDENCE_OPTION_DATA}
    titlePath={TITLE_PATH_CHILD_LOCATION_3}
    marginBottom={23}
  />
)
