import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { APP_BENEFIT_DATA_2 } from 'pages/app-benefit/constants'

import { CDN_FOLDER_LINK, Language } from 'root-constants/common'

import { StyledAppBenefitVariant2 as S } from './AppBenefitVariant2.styles'

export const AppBenefitVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const language = useSelector(selectLanguage)

  const isESLanguage = useMemo(() => language === Language.ES, [language])

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })
  return (
    <S.Wrapper>
      <S.ImageContainer>
        <img
          src={`${CDN_FOLDER_LINK}${APP_BENEFIT_DATA_2[pageId].image}_${language}.png`}
          alt="app_benefit"
        />
      </S.ImageContainer>

      <S.Column>
        <S.Title isESLanguage={isESLanguage}>
          {t(APP_BENEFIT_DATA_2[pageId].title)}
        </S.Title>
        <S.Subtitle>{t(APP_BENEFIT_DATA_2[pageId].subtitle)}</S.Subtitle>
      </S.Column>

      <S.Button type="button" onClick={() => handleContinue('')}>
        {t`actions.continue`}
      </S.Button>
    </S.Wrapper>
  )
}
