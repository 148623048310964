import React from 'react'

import { ISelectPlanItemProps } from 'models/common.model'

import {
  PlanItemVariant1,
  VatPlanItemVariant1,
} from 'modules/subscriptions/components/common/PlanItemVariant1'
import {
  TrialPlanItemVariant1,
  TrialPlanItemVariant2,
  TrialPlanItemVariant3,
} from 'modules/subscriptions/components/trial'

import {
  REVIEWS_DATA,
  REVIEWS_DATA_SLIDER_VARIANT_2,
} from 'components/ReviewsSlider/constants'

import coupleImage from 'assets/images/subscriptions-couple.webp'
import friendsImage from 'assets/images/subscriptions-friends.webp'
import kidImage from 'assets/images/subscriptions-kid.webp'

import { BillingCycle, Cohort, Images, UserGoal } from 'root-constants/common'

export const PERIOD_QUANTITY_TO_MARKUP_MAP = {
  [BillingCycle.ONE_MONTH]: 'subscriptions.description.monthly2',
  [BillingCycle.THREE_MONTHS]: 'subscriptions.description.quarterly2',
  [BillingCycle.SIX_MONTHS]: 'subscriptions.description.semiAnnually',
  [BillingCycle.NINE_MONTHS]: 'subscriptions.description.nineMonth',
}

export const PERIOD_QUANTITY_PURCHASE_UPGRADE = {
  [BillingCycle.ONE_MONTH]:
    'subscriptions.purchaseUpgrade.subscription.monthly',
  [BillingCycle.THREE_MONTHS]:
    'subscriptions.purchaseUpgrade.subscription.quarterly',
  [BillingCycle.SIX_MONTHS]:
    'subscriptions.purchaseUpgrade.subscription.semiAnnually',
  [BillingCycle.NINE_MONTHS]:
    'subscriptions.purchaseUpgrade.subscription.everyNineMonths',
  [BillingCycle.YEAR]: 'subscriptions.purchaseUpgrade.subscription.annually',
}
export const MAIN_4_COHORT_DATA = [
  {
    userGoal: UserGoal.PARTNER,
    data: {
      title: 'subscriptions.variant2.titlePartner',
      subtitle: 'subscriptions.variant2.subtitlePartner',
      img: Images.PARTNER_GOAL,
      aspectRatio: 375 / 302,
    },
  },
  {
    userGoal: UserGoal.KID,
    data: {
      title: 'subscriptions.variant2.titleKid',
      subtitle: 'subscriptions.variant2.subtitleKid',
      img: Images.KID_GOAL,
      aspectRatio: 375 / 322,
    },
  },
  {
    userGoal: UserGoal.FRIEND,
    data: {
      title: 'subscriptions.variant2.titleFriend',
      subtitle: 'subscriptions.variant2.subtitleFriend',
      img: Images.FRIEND_GOAL,
      aspectRatio: 375 / 302,
    },
  },
  {
    userGoal: UserGoal.OTHER,
    data: {
      title: 'subscriptions.variant2.titleFriend',
      subtitle: 'subscriptions.variant2.subtitleFriend',
      img: Images.FRIEND_GOAL,
      aspectRatio: 375 / 302,
    },
  },
]
export const GOAL_TO_MARKUP_MAP = {
  [UserGoal.PARTNER]: {
    title: 'subscriptions.variant2.titlePartner',
    subtitle: 'subscriptions.variant2.subtitlePartner',
    img: Images.PARTNER_GOAL,
    laptopImg: Images.PARTNER_GOAL_LAPTOP,
    aspectRatio: 375 / 302,
  },
  [UserGoal.KID]: {
    title: 'subscriptions.variant2.titleKid',
    subtitle: 'subscriptions.variant2.subtitleKid',
    img: Images.KID_GOAL,
    laptopImg: Images.KID_GOAL_LAPTOP,
    aspectRatio: 375 / 322,
  },
  [UserGoal.FRIEND]: {
    title: 'subscriptions.variant2.titleFriend',
    subtitle: 'subscriptions.variant2.subtitleFriend',
    img: Images.FRIEND_GOAL,
    laptopImg: Images.FRIEND_GOAL_LAPTOP,
    aspectRatio: 375 / 302,
  },
  [UserGoal.TEEN]: {
    title: 'subscriptions.variant2.titleKid',
    subtitle: 'subscriptions.variant2.subtitleKid',
    img: Images.TEEN_GOAL,
    aspectRatio: 375 / 322,
  },
}

export const SAFETY3_COHORT_DATA = [
  {
    userGoal: UserGoal.PARTNER,
    data: {
      title: 'subscriptions.variant9.titlePartner',
      subtitle: 'subscriptions.variant9.subtitlePartner',
      img: coupleImage,
      aspectRatio: 375 / 302,
      reviews: REVIEWS_DATA_SLIDER_VARIANT_2,
    },
  },
  {
    userGoal: UserGoal.KID,
    data: {
      title: 'subscriptions.variant2.titleKid',
      subtitle: 'subscriptions.variant2.subtitleKid2',
      img: kidImage,
      aspectRatio: 375 / 322,
      reviews: REVIEWS_DATA.teenGoalReviews,
    },
  },
  {
    userGoal: UserGoal.FRIEND,
    data: {
      title: 'subscriptions.variant2.titleFriend',
      subtitle: 'subscriptions.variant2.subtitleFriend2',
      img: friendsImage,
      aspectRatio: 375 / 302,
      reviews: REVIEWS_DATA.friendGoalReviews,
    },
  },
  {
    userGoal: UserGoal.OTHER,
    data: {
      title: 'subscriptions.variant2.titleFriend',
      subtitle: 'subscriptions.variant2.subtitleFriend2',
      img: friendsImage,
      aspectRatio: 375 / 302,
      reviews: REVIEWS_DATA.friendGoalReviews,
    },
  },
]

export const STEPS_DATA = [
  {
    title: 'onboarding.introGoogle4.steps.stepOne',
    subtitle: 'subscriptions.subscriptionsGoogle.variant1.steps.enterNumber',
    text: 'subscriptions.subscriptionsGoogle.variant1.steps.geolocationTracking',
  },
  {
    title: 'onboarding.introGoogle4.steps.stepTwo',
    subtitle: 'subscriptions.subscriptionsGoogle.variant1.steps.createAccount',
    text: 'subscriptions.subscriptionsGoogle.variant1.steps.downloadFamio',
  },
  {
    title: 'onboarding.introGoogle4.steps.stepThree',
    subtitle: 'subscriptions.subscriptionsGoogle.variant1.steps.createGroups',
    text: 'subscriptions.subscriptionsGoogle.variant1.steps.getLocation',
  },
  {
    title: 'onboarding.introGoogle4.steps.stepFour',
    subtitle: 'subscriptions.subscriptionsGoogle.variant1.steps.enjoyPeace',
    text: 'subscriptions.subscriptionsGoogle.variant1.steps.beInTouch',
  },
]

export const CHIPOLO_STEPS_DATA = [
  {
    title: 'onboarding.introGoogle4.steps.stepOne',
    subtitle: 'subscriptions.chipolo.paywall.steps.upgradePlan',
    text: 'subscriptions.chipolo.paywall.steps.enjoyFamio',
  },
  {
    title: 'onboarding.introGoogle4.steps.stepTwo',
    subtitle: 'subscriptions.chipolo.paywall.steps.shipping',
    text: 'subscriptions.chipolo.paywall.steps.fillDelivery',
  },
  {
    title: 'onboarding.introGoogle4.steps.stepThree',
    subtitle: 'subscriptions.chipolo.paywall.steps.howItWorks',
    text: 'subscriptions.chipolo.paywall.steps.chipoloConnects',
  },
  {
    title: 'onboarding.introGoogle4.steps.stepFour',
    subtitle: 'subscriptions.chipolo.paywall.steps.enjoyPeace',
    text: 'subscriptions.chipolo.paywall.steps.stayConnected',
  },
]

export const SAFETY_6_STEPS_DATA = [
  {
    title: 'onboarding.introGoogle4.steps.stepOne',
    subtitle: 'subscriptions.variant10.steps.download',
    text: 'subscriptions.variant10.steps.note',
  },
  {
    title: 'onboarding.introGoogle4.steps.stepTwo',
    subtitle: 'subscriptions.variant10.steps.createGroups',
    text: 'subscriptions.variant10.steps.realTime',
  },
  {
    title: 'onboarding.introGoogle4.steps.stepThree',
    subtitle: 'subscriptions.variant10.steps.enjoyPeace',
    text: 'subscriptions.variant10.steps.beInTouch',
  },
]
export const YOUTUBE_VIDEO_ID = 'DauqZDTtPqc'

export const ZIP_RANGES: [number, number, string][] = [
  [35000, 36999, 'AL'],
  [99500, 99999, 'AK'],
  [85000, 86999, 'AZ'],
  [71600, 72999, 'AR'],
  [90000, 96699, 'CA'],
  [80000, 81999, 'CO'],
  [6000, 6389, 'CT'],
  [6391, 6999, 'CT'],
  [19700, 19999, 'DE'],
  [32000, 34999, 'FL'],
  [30000, 31999, 'GA'],
  [39800, 39999, 'GA'],
  [96700, 96999, 'HI'],
  [83200, 83413, 'ID'],
  [83415, 83999, 'ID'],
  [60000, 62999, 'IL'],
  [46000, 47999, 'IN'],
  [50000, 52999, 'IA'],
  [66000, 67999, 'KS'],
  [40000, 42999, 'KY'],
  [70000, 71599, 'LA'],
  [3900, 4999, 'ME'],
  [20600, 21999, 'MD'],
  [1000, 2799, 'MA'],
  [5501, 5501, 'MA'],
  [5544, 5544, 'MA'],
  [48000, 49999, 'MI'],
  [55000, 56899, 'MN'],
  [38600, 39999, 'MS'],
  [63000, 65999, 'MO'],
  [59000, 59999, 'MT'],
  [27000, 28999, 'NC'],
  [58000, 58999, 'ND'],
  [68000, 69999, 'NE'],
  [88900, 89999, 'NV'],
  [3000, 3899, 'NH'],
  [7000, 8999, 'NJ'],
  [87000, 88499, 'NM'],
  [10000, 14999, 'NY'],
  [6390, 6390, 'NY'],
  [501, 501, 'NY'],
  [544, 544, 'NY'],
  [43000, 45999, 'OH'],
  [73000, 73199, 'OK'],
  [73400, 74999, 'OK'],
  [97000, 97999, 'OR'],
  [15000, 19699, 'PA'],
  [300, 999, 'PR'],
  [2800, 2999, 'RI'],
  [29000, 29999, 'SC'],
  [57000, 57999, 'SD'],
  [37000, 38599, 'TN'],
  [75000, 79999, 'TX'],
  [73301, 73399, 'TX'],
  [88500, 88599, 'TX'],
  [84000, 84999, 'UT'],
  [5000, 5999, 'VT'],
  [20100, 20199, 'VA'],
  [22000, 24699, 'VA'],
  [20598, 20598, 'VA'],
  [20000, 20099, 'DC'],
  [20200, 20599, 'DC'],
  [56900, 56999, 'DC'],
  [98000, 99499, 'WA'],
  [24700, 26999, 'WV'],
  [53000, 54999, 'WI'],
  [82000, 83199, 'WY'],
]

export const enum UpgradeItem {
  SUBSCRIPTION = 'subscription',
  CHIPOLO = 'chipolo_upsell',
}

export const UPGRADE_ITEMS = {
  BOTH: [UpgradeItem.SUBSCRIPTION, UpgradeItem.CHIPOLO],
  UPGRADE: [UpgradeItem.SUBSCRIPTION],
  NONE: [],
}

export const FEW_GOALS_IMG_ASPECT_RATIO = 375 / 322
export const DISCOUNT_UPSELL = 50
export const UNIQUE_VIEWS_NUMBER = 1

export const INITIAL_FORMATTED_TIME = {
  hours: '',
  minutes: '',
  seconds: '',
}

export const PLAN_ITEMS: Record<string, React.FC<ISelectPlanItemProps>> = {
  plan_item_variant_1: PlanItemVariant1,
  vat_plan_item_variant_1: VatPlanItemVariant1,
  trial_plan_item_variant_1: TrialPlanItemVariant1,
  trial_plan_item_variant_2: TrialPlanItemVariant2,
  trial_plan_item_variant_3: TrialPlanItemVariant3,
}

export const CANCEL_OFFER_TRIAL_COHORTS: string[] = [
  Cohort.MAIN_4,
  Cohort.MAIN_4_UPD,
  Cohort.SAFETY_2,
]
