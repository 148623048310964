import { UserGoal } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

export const USER_GOAL_BENEFITS: Record<
  string,
  { id: number; text: string }[]
> = {
  [UserGoal.PARTNER]: [
    { id: 1, text: 'subscriptions.benefits.partnerList.track' },
    { id: 2, text: 'subscriptions.benefits.partnerList.alerts' },
    { id: 3, text: 'subscriptions.benefits.partnerList.sos' },
    { id: 4, text: 'subscriptions.benefits.partnerList.reduce' },
  ],
  [UserGoal.KID]: [
    { id: 1, text: 'subscriptions.benefits.kidList.notification' },
    { id: 2, text: 'subscriptions.benefits.kidList.track' },
    { id: 3, text: 'subscriptions.benefits.kidList.sos' },
    { id: 4, text: 'subscriptions.benefits.kidList.driving' },
    { id: 5, text: 'subscriptions.benefits.kidList.stress' },
  ],
  [UserGoal.FRIEND]: [
    { id: 1, text: 'subscriptions.benefits.friendList.informed' },
    { id: 2, text: 'subscriptions.benefits.friendList.notification' },
    { id: 3, text: 'subscriptions.benefits.friendList.driving' },
    { id: 4, text: 'subscriptions.benefits.friendList.connection' },
    { id: 5, text: 'subscriptions.benefits.friendList.sos' },
  ],
}

export const USER_MULTI_GOAL_BENEFITS: { id: number; text: string }[] = [
  { id: 1, text: 'subscriptions.benefits.multiList.notification' },
  { id: 2, text: 'subscriptions.benefits.multiList.track' },
  { id: 3, text: 'subscriptions.benefits.multiList.alerts' },
  { id: 4, text: 'subscriptions.benefits.multiList.driving' },
  { id: 5, text: 'subscriptions.benefits.multiList.connection' },
  { id: 6, text: 'subscriptions.benefits.multiList.stress' },
]

export const COMMON_BENEFITS: Record<string, { id: number; text: string }[]> = {
  [PageId.SUBSCRIPTIONS_3]: [
    { id: 1, text: 'subscriptions.variant3.alerts' },
    { id: 2, text: 'subscriptions.variant3.notifications' },
    { id: 3, text: 'subscriptions.variant3.safety' },
    { id: 4, text: 'subscriptions.variant3.stressFree' },
  ],
  [PageId.SUBSCRIPTIONS_5]: [
    { id: 1, text: 'subscriptions.variant5.benefits.track' },
    { id: 2, text: 'subscriptions.variant5.benefits.alerts' },
    { id: 3, text: 'subscriptions.variant5.benefits.sos' },
    { id: 4, text: 'subscriptions.variant5.benefits.reduce' },
  ],
  [PageId.SUBSCRIPTIONS_6]: [
    { id: 1, text: 'subscriptions.variant10.benefits.realTimeLocation' },
    { id: 2, text: 'subscriptions.variant10.benefits.sos' },
    { id: 3, text: 'subscriptions.variant10.benefits.chat' },
    { id: 4, text: 'subscriptions.variant10.benefits.data' },
  ],
  [PageId.SUBSCRIPTIONS_8]: [
    { id: 1, text: 'subscriptions.benefits.teenList.notification' },
    { id: 2, text: 'subscriptions.benefits.teenList.track' },
    { id: 3, text: 'subscriptions.benefits.teenList.sos' },
    { id: 4, text: 'subscriptions.benefits.teenList.battery' },
    { id: 5, text: 'subscriptions.benefits.teenList.stress' },
  ],
  [PageId.SUBSCRIPTIONS_10]: [
    { id: 1, text: 'subscriptions.variant10.benefits.realTimeLocation' },
    { id: 2, text: 'subscriptions.variant10.benefits.sos' },
    { id: 3, text: 'subscriptions.variant10.benefits.chat' },
    { id: 4, text: 'subscriptions.variant10.benefits.data' },
  ],
}

export const SAFETY3_COHORT_BENEFITS = {
  [UserGoal.PARTNER]: {
    title: 'subscriptions.benefits.alternativeTitle',
    benefits: [
      'subscriptions.benefits.partnerList.track',
      'subscriptions.benefits.partnerList.alerts',
      'subscriptions.benefits.partnerList.sos2',
      'subscriptions.benefits.partnerList.reduce',
    ],
  },
  [UserGoal.KID]: {
    title: 'subscriptions.benefits.title',
    benefits: [
      'subscriptions.benefits.kidList.notification',
      'subscriptions.benefits.kidList.track',
      'subscriptions.benefits.kidList.sos',
      'subscriptions.benefits.kidList.driving',
      'subscriptions.benefits.kidList.stress',
    ],
  },
  [UserGoal.FRIEND]: {
    title: 'subscriptions.benefits.title',
    benefits: [
      'subscriptions.benefits.friendList.informed',
      'subscriptions.benefits.friendList.notification',
      'subscriptions.benefits.friendList.driving',
      'subscriptions.benefits.friendList.connection',
      'subscriptions.benefits.friendList.sos',
    ],
  },
  [UserGoal.OTHER]: {
    title: 'subscriptions.benefits.title',
    benefits: [
      'subscriptions.benefits.friendList.informed',
      'subscriptions.benefits.friendList.notification',
      'subscriptions.benefits.friendList.driving',
      'subscriptions.benefits.friendList.connection',
      'subscriptions.benefits.friendList.sos',
    ],
  },
}
