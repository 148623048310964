import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  setIsInContextShownAction,
  setLanguageAction,
} from 'root-redux/actions/common'
import { selectIsInContextShown } from 'root-redux/selects/common'

import { Language, SUPPORTED_LOCALES } from 'root-constants/common'

import { StyledInContextControlPanel as S } from './InContextControlPanel.styles'

export const InContextControlPanel: React.FC = () => {
  const dispatch = useDispatch()
  const isInContextShown = useSelector(selectIsInContextShown)

  const handleChangeInContextVisibility = useCallback(() => {
    dispatch(setIsInContextShownAction(true))
  }, [dispatch])

  const handleChangeLanguage = useCallback(
    ({ target: { value } }) => {
      dispatch(setLanguageAction(value))
    },
    [dispatch],
  )

  return (
    <S.Wrapper>
      {isInContextShown ? (
        <S.Select onChange={handleChangeLanguage}>
          <option value={Language.IN_CONTEXT}>In-context</option>
          {SUPPORTED_LOCALES.map((locale) => (
            <option key={locale} value={locale}>
              {locale.toUpperCase()}
            </option>
          ))}
        </S.Select>
      ) : (
        <S.Button type="button" onClick={handleChangeInContextVisibility}>
          Turn on In-Context
        </S.Button>
      )}
    </S.Wrapper>
  )
}
