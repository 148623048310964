import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { selectCurrency } from 'modules/payment/redux/selects'

import { CURRENCY_SYMBOLS, Language, ProductKey } from 'root-constants/common'

import { StyledPlanItemVariant2 as S } from './PlanItemVariant2.styles'
import { INTRO_OFFER_PERIODS } from './constants'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}

export const PlanItemVariant2: React.FC<TProps> = ({
  subscription: {
    isDefault,
    product,
    mainPrices: { fullPrice },
    trialPrices: { fullPrice: trialPrice, durationDays, daily, oldPrices },
  },
  isSelected,
}) => {
  const { t } = useTranslation()
  const currency = useSelector(selectCurrency)
  const language = useSelector(selectLanguage)

  const [integerCustomDailyPrice, fractionalCustomDailyPrice = '00'] = useMemo(
    () => daily.toFixed(2).split('.'),
    [daily],
  )

  return (
    <S.Wrapper
      isDefault={isDefault}
      isSelected={isSelected}
      labelText={t`subscriptions.planBlock.mostPopular`}
    >
      <S.Checkbox isSelected={isSelected} />
      <S.MainPricesContainer>
        <S.Period>
          {t('payment.variant5.planPeriodName', {
            periodQuantity: INTRO_OFFER_PERIODS[durationDays],
            periodName:
              product === ProductKey.ONE_WEEK
                ? t('payment.variant5.planPeriods.week')
                : t('payment.variant5.planPeriods.month', {
                    count: INTRO_OFFER_PERIODS[durationDays],
                  }),
          })}
        </S.Period>
        <S.OldPrice>
          {t('subscriptions.planBlock.price', {
            price:
              product === ProductKey.ONE_WEEK
                ? oldPrices.beforeCustomDiscount.fullPrice
                : fullPrice,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          })}
        </S.OldPrice>
        <S.CurrentPrice>
          {t('subscriptions.planBlock.price', {
            price: trialPrice,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          })}
        </S.CurrentPrice>
        <S.OldCustomPrice>
          {t('subscriptions.planBlock.price', {
            price: oldPrices.beforeCustomDiscount.daily,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          })}
        </S.OldCustomPrice>
      </S.MainPricesContainer>

      <S.CustomPriceContainer isSelected={isSelected}>
        {language === Language.EN && (
          <S.Currency>{CURRENCY_SYMBOLS[currency]}</S.Currency>
        )}
        <S.CustomPriceInteger>{integerCustomDailyPrice}</S.CustomPriceInteger>
        <S.CustomPriceFractional>
          {fractionalCustomDailyPrice}{' '}
          {language === Language.ES && CURRENCY_SYMBOLS[currency]}
        </S.CustomPriceFractional>
        <S.CustomPricePeriod>
          {t('subscriptions.planBlock.perPeriod', {
            interval: t('subscriptions.planBlock.perDay'),
          })}
        </S.CustomPricePeriod>
      </S.CustomPriceContainer>
    </S.Wrapper>
  )
}
