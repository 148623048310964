import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import {
  resetErrorAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantCohortToUse,
  selectHasCancelOffer,
  selectLanguage,
  selectScreenName,
} from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useProductId } from 'hooks/useProductId'
import { useVatInfo } from 'hooks/useVatInfo'

import { ScreenName, eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { Divider } from 'modules/payment/components/Divider'
import { PayPalContainer } from 'modules/payment/components/PayPalContainer'
import { PaymentRequestButton } from 'modules/payment/components/PaymentRequestButton'

import paymentServicesDarkTheme from 'assets/images/payment-services-dark-theme.png'
import paymentServices from 'assets/images/payment-services.png'

import { goBack, replaceHistory } from 'browser-history'
import { Cohort, Language } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StripePaymentForm } from '../StripePaymentForm'
import { TotalAmount } from '../TotalAmount'
import { VatTotalAmount } from '../VatTotalAmount'
import { StyledPaymentFlows as S } from './PaymentFlows.styles'

export const PaymentFlows: React.FC = () => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const dispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const screenName = useSelector(selectScreenName)
  const hasCancelOffer = useSelector(selectHasCancelOffer)
  const language = useSelector(selectLanguage)

  const productId = useProductId()
  const hasIncludedVAT = useVatInfo()
  const { title } = useDynamicPaywallConfig()

  const isPaypalHidden = useFeatureIsOn('abtest_car_758_paypal')

  const hasPaypal = useMemo(
    () => cohort !== Cohort.MAIN_4_UPD && !isPaypalHidden,
    [cohort, isPaypalHidden],
  )

  const handleBack = useCallback(() => {
    dispatch(resetErrorAction())
    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName,
    })

    if (hasCancelOffer && language !== Language.DE) {
      screenName !== ScreenName.CANCEL_OFFER &&
        dispatch(setSubscriptionListAction([]))
      replaceHistory({ pathname: PageId.CANCEL_OFFER_SUBSCRIPTIONS_1, search })
      return
    }
    goBack()
  }, [
    cohort,
    dispatch,
    hasCancelOffer,
    language,
    pathname,
    productId,
    screenName,
    search,
  ])

  return (
    <>
      <S.TitleWrapper>
        <S.CloseButton
          data-testid="close-btn"
          type="button"
          onClick={handleBack}
        />
        <S.Title>{title || t`payment.checkout`}</S.Title>
      </S.TitleWrapper>
      {hasIncludedVAT ? <VatTotalAmount /> : <TotalAmount />}

      <StripePaymentForm hasLaptopStyles />
      {hasPaypal && <Divider />}

      <S.PaymentButtonsContainer>
        {hasPaypal && <PayPalContainer hasLaptopStyles />}
        <PaymentRequestButton hasLaptopStyles isDividerVisible={!hasPaypal} />
      </S.PaymentButtonsContainer>

      <S.PaymentServiceImage
        width="256px"
        src={
          cohortToUse === Cohort.FRIENDS_1
            ? paymentServicesDarkTheme
            : paymentServices
        }
        decoding="async"
        alt="payments services"
      />
      <S.Guarantee>{t`payment.guarantee`}</S.Guarantee>
      <S.Description>{t`payment.description`}</S.Description>
    </>
  )
}
