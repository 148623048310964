import React, { useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectCurrentVariantCohortToUse,
  selectIsFetching,
} from 'root-redux/selects/common'
import { selectEmail } from 'root-redux/selects/user'

import { useConfirmationPasswordInputField } from 'hooks/useConfirmationPasswordInputField'
import { useEmailInputField } from 'hooks/useEmailInputField'
import { usePasswordInputField } from 'hooks/usePasswordInputField'

import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import { eventLogger } from 'services/eventLogger.service'

import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { Cohort, LoginMethod, PlatformOS } from 'root-constants/common'

import {
  registerWithEmailFirebaseAction,
  resetEmailErrorMessageAction,
  resetPasswordErrorMessageAction,
} from '../../redux/actions'
import {
  selectEmailErrorMessage,
  selectPasswordErrorMessage,
} from '../../redux/selects'
import { StyledEmailAccount as S } from './EmailAccount.styles'

type TProps = {
  onBack: () => void
}

export const EmailAccount: React.FC<TProps> = ({ onBack }) => {
  const dispatch = useDispatch()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const isFetching = useSelector(selectIsFetching)
  const emailErrorMessage = useSelector(selectEmailErrorMessage)
  const passwordErrorMessage = useSelector(selectPasswordErrorMessage)
  const userOnboardingEmail = useSelector(selectEmail)

  const isAndroid = getMobileOperatingSystem() === PlatformOS.ANDROID

  const [email, setEmail, validateEmail] = useEmailInputField(
    userOnboardingEmail,
    emailErrorMessage,
    () => dispatch(resetEmailErrorMessageAction()),
  )
  const [password, setPassword, validatePassword] = usePasswordInputField(
    passwordErrorMessage,
    () => dispatch(resetPasswordErrorMessageAction()),
  )
  const { t } = useTranslation()
  const [
    confirmationPassword,
    setConfirmationPassword,
    validateConfirmationPassword,
  ] = useConfirmationPasswordInputField(password)

  const isComplete = useMemo(
    () =>
      email.isValid &&
      email.value !== '' &&
      password.isValid &&
      password.value !== '' &&
      confirmationPassword.isValid &&
      confirmationPassword.value !== '',
    [
      confirmationPassword.isValid,
      confirmationPassword.value,
      email.isValid,
      email.value,
      password.isValid,
      password.value,
    ],
  )

  useEffect(() => {
    if (isAndroid) {
      eventLogger.logLoginMethodSelected({ method: LoginMethod.EMAIL })
    }
  }, [isAndroid])

  return (
    <S.Wrapper>
      {!isAndroid && <S.BackButton onClick={onBack} />}

      {!isAndroid && (
        <S.Title>
          {cohortToUse === Cohort.FRIENDS_1
            ? t`login.createAccount`
            : t`login.titleAccount`}
        </S.Title>
      )}

      {isAndroid && (
        <>
          <S.Title isAndroid>{t`login.titleWelcome`}</S.Title>
          <S.Text>
            <Trans i18nKey="login.subtitle" />
          </S.Text>
        </>
      )}

      <Input
        value={email.value}
        isValid={email.isValid}
        validationText={email.validationText}
        labelName={t`login.email`}
        placeholder={t`login.placeholderEmail`}
        onChange={({ target: { value } }) => {
          setEmail((prevState) => ({
            ...prevState,
            value: value.toLowerCase().trim(),
            isValid: true,
            validationText: '',
          }))
        }}
        onBlur={({ target: { value } }) => validateEmail(value)}
      />
      <Input
        value={password.value}
        isValid={password.isValid}
        validationText={password.validationText}
        labelName={t`login.password`}
        placeholder={t`login.placeholderPassword`}
        hasVisibilityControl
        onChange={({ target: { value } }) => {
          setPassword((prevState) => ({
            ...prevState,
            value,
            isValid: true,
            validationText: '',
          }))
        }}
        onBlur={({ target: { value } }) => validatePassword(value)}
      />
      <Input
        value={confirmationPassword.value}
        isValid={confirmationPassword.isValid}
        validationText={confirmationPassword.validationText}
        hasVisibilityControl
        labelName={t`login.repeatPassword`}
        placeholder={t`login.placeholderPassword`}
        onChange={({ target: { value } }) => {
          setConfirmationPassword((prevState) => ({
            ...prevState,
            value,
            isValid: true,
            validationText: '',
          }))
        }}
        onBlur={({ target: { value } }) => validateConfirmationPassword(value)}
      />
      <S.Footer>
        <S.Description>
          {t`login.agreement`} <TermsOfUseLink title={t`login.terms`} />{' '}
          {t`actions.and`} <PrivacyPolicyLink />
        </S.Description>
        <Button
          disabled={!isComplete || isFetching}
          onClick={() => {
            dispatch(
              registerWithEmailFirebaseAction(
                email.value.toLowerCase(),
                password.value,
              ),
            )
          }}
        >
          {t`actions.signUp`}
        </Button>
      </S.Footer>
    </S.Wrapper>
  )
}
