import { DefaultTheme } from 'styled-components'

import checkboxGreen from 'assets/images/checkbox-green.svg'
import checkboxViolet from 'assets/images/checkbox-violet.svg'
import closedEyeWhiteIcon from 'assets/images/closed-eye-white.svg'
import closedEyeIcon from 'assets/images/closed-eye.svg'
import openedEyeWhiteIcon from 'assets/images/opened-eye-white.svg'
import openedEyeIcon from 'assets/images/opened-eye.svg'

import { HEADER_HEIGHT } from 'root-constants/common'

export const COMMON_THEME: DefaultTheme = {
  common: {
    inputBorder: 'transparent',
    headerBorder: '1px solid #ccdce5',
    progressTitleFontWeight: 800,
    priceFontWeight: 800,
    progressTitleFontSize: '14px',
    progressPercentFontSize: '11px',
    subtitleFontSize: '16px',
    autoCompleteButtonBorder: '1px solid #00000019',
    filter: 'drop-shadow(0px 7px 20px #c8d4d8)',
  },
  colors: {
    activeBackground: '#6336ff',
    activeTextColor: '#fff',
    defaultTextColor: '#0a324d',
    defaultBackground: '#eff5f7',
    disabledButton: 'rgba(99, 54, 255, 0.3)',
    primaryText: '#0a324d',
    secondaryText: '#7b8898',
    tertiaryText: '#0a324d',
    burgerBackground: '#fff',
    burgerLinksColor: '#153e83',
    progressPercentColor: '#7b8898',
    progressBarBackground: '#eff5f7',
    inputPlaceholder: '#bcc9d0',
    inputBackground: '#eff5f7',
    timerBackground: '#0a324d',
    disclaimerText: '#7b8898',
    guaranteeBackground: '#eff5f7',
    guaranteeText: 'rgba(10, 50, 77, 0.7)',
    paymentModalBackground: '#fff',
    priceColor: '#199cf3',
    appleLoginButtonBackground: '#041a28',
    emailTipsBackground: '#fff',
  },
  arrangements: {
    columnVerticalAlignment: 'flex-start',
    questionTextAlignment: 'start',
  },
  dimensions: {
    columnHeight: 'auto',
    flexGrow: 0,
  },
  images: {
    checkboxIcon: checkboxViolet,
    openedEyeIcon,
    closedEyeIcon,
  },
}

export const SAFETY_THEME: DefaultTheme = {
  common: {
    inputBorder: 'transparent',
    headerBorder: '1px solid #ccdce5',
    progressTitleFontWeight: 800,
    priceFontWeight: 800,
    progressTitleFontSize: '14px',
    progressPercentFontSize: '11px',
    subtitleFontSize: '16px',
    autoCompleteButtonBorder: '1px solid #00000019',
    filter: 'drop-shadow(0px 7px 20px #c8d4d8)',
  },
  colors: {
    activeBackground: '#6336ff',
    activeTextColor: '#fff',
    defaultTextColor: '#0a324d',
    defaultBackground: '#eff5f7',
    disabledButton: 'rgba(99, 54, 255, 0.3)',
    primaryText: '#0a324d',
    secondaryText: '#7b8898',
    tertiaryText: '#0a324d',
    burgerBackground: '#fff',
    burgerLinksColor: '#153e83',
    progressPercentColor: '#7b8898',
    progressBarBackground: '#eff5f7',
    inputPlaceholder: '#bcc9d0',
    inputBackground: '#eff5f7',
    timerBackground: '#0a324d',
    disclaimerText: '#7b8898',
    guaranteeBackground: '#eff5f7',
    guaranteeText: 'rgba(10, 50, 77, 0.7)',
    paymentModalBackground: '#fff',
    priceColor: '#199cf3',
    appleLoginButtonBackground: '#041a28',
    emailTipsBackground: '#fff',
  },
  arrangements: {
    columnVerticalAlignment: 'space-between',
    questionTextAlignment: 'center',
  },
  dimensions: {
    columnHeight: `calc(100svh - ${HEADER_HEIGHT}px)`,
    flexGrow: 1,
  },
  images: {
    checkboxIcon: checkboxViolet,
    openedEyeIcon,
    closedEyeIcon,
  },
}

export const FRIENDS_THEME: DefaultTheme = {
  common: {
    inputBorder: '2px solid #a194c9',
    headerBorder: 'none',
    progressTitleFontWeight: 700,
    priceFontWeight: 400,
    progressTitleFontSize: '16px',
    progressPercentFontSize: '13px',
    subtitleFontSize: '18px',
    autoCompleteButtonBorder: '1px solid #a198ac',
    filter: 'none',
  },
  colors: {
    activeBackground: '#dbf301',
    activeTextColor: '#15042a',
    defaultTextColor: '#fff',
    defaultBackground: 'transparent',
    disabledButton: 'rgba(219, 243, 1, 0.5)',
    primaryText: '#fff',
    secondaryText: '#bcc9d0',
    tertiaryText: '#bcc9d0',
    burgerBackground:
      'conic-gradient(from 103deg at 30.4% 28.11%, #250f41 36.59208744764328deg, #0e041c 60.85077166557312deg, #000 88.37330460548401deg, rgba(75, 37, 184, 0.55) 173.03385257720947deg, rgba(86, 42, 211, 0.60) 185.23337602615356deg, rgba(83, 41, 152, 0.85) 203.9219570159912deg, rgba(129, 47, 165, 0.50) 231.51240348815918deg, #12051f 281.50644063949585deg, #210d3b 341.75859689712524deg), #0b0217',
    burgerLinksColor: '#fff',
    progressPercentColor: '#fff',
    progressBarBackground: '#312b4b',
    inputPlaceholder: '#a198ac',
    inputBackground: '#312b4b',
    timerBackground: '#5c90ff',
    disclaimerText: '#969ea7',
    guaranteeBackground: '#312b4b',
    guaranteeText: '#bcc9d0',
    paymentModalBackground: '#3c3659',
    priceColor: '#bcc9d0',
    appleLoginButtonBackground: '#fff',
    emailTipsBackground: '#312b4b',
  },
  arrangements: {
    columnVerticalAlignment: 'flex-start',
    questionTextAlignment: 'center',
  },
  dimensions: {
    columnHeight: 'auto',
    flexGrow: 0,
  },
  images: {
    checkboxIcon: checkboxGreen,
    openedEyeIcon: openedEyeWhiteIcon,
    closedEyeIcon: closedEyeWhiteIcon,
  },
}
