import styled from 'styled-components'

import { Button } from 'components/Button'
import { Input } from 'components/Input'

import { basePageStyles, baseTitleStyles } from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledEmailVariant3 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    height: calc(100dvh - ${HEADER_HEIGHT}px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 32px;
    background-color: #f5f5ff;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Disclaimer: styled.p`
    margin-bottom: 32px;
    padding: 8px 12px;
    border-radius: 6px;
    background-color: #fdeccd;
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    text-align: center;

    svg {
      vertical-align: text-top;
    }
  `,
  Title: styled.h2`
    ${baseTitleStyles};
    margin-bottom: 4px;

    strong {
      color: ${Color.ACCENT_VIOLET};
    }
  `,
  Subtitle: styled.h3`
    margin-bottom: 16px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: ${Color.LIGHT_TEXT};
  `,
  InputWrapper: styled.div`
    width: 100%;
    position: relative;
  `,
  Input: styled(Input)`
    background-color: ${Color.WHITE};
    border: 1px solid #cbc3e7;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  `,
  AutoCompleteWrapper: styled.div`
    width: 100%;
    max-height: 160px;
    padding: 0 15px;
    position: absolute;
    top: 56px;
    left: 0;
    border-radius: 12px;
    overflow-y: auto;
    background-color: #fff;
    filter: drop-shadow(0px 7px 20px #c8d4d8);
    z-index: 2;
    transform: translateZ(0);
  `,
  AutoCompleteButton: styled.button`
    height: 50px;
    width: 100%;
    padding: 0;
    display: block;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 50px;
    text-decoration: none;
    text-align: left;
    color: ${Color.DEFAULT_TEXT};
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    direction: rtl;

    :not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  `,
  AutoCompleteButtonTextWrapper: styled.span`
    unicode-bidi: plaintext;
  `,
  TipsContainer: styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 24px;
  `,
  TipsButton: styled.button`
    height: 34px;
    padding: 8px 10px;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    cursor: pointer;
    border-radius: 16px;
    border: 1px solid #cbc3e7;
    outline: none;
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  `,
  Button: styled(Button)`
    margin-bottom: 24px;
  `,
  SecurityInfoContainer: styled.div`
    display: grid;
    grid-template-columns: 150px 172px;
    column-gap: 12px;
    justify-content: center;
  `,
  SecurityInfo: styled.p`
    display: flex;
    align-items: center;
    padding: 4px 8px 4px 4px;
    border-radius: 8px;
    background-color: #e7e6ff;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;

    img {
      width: 24px;
      height: 24px;
    }
  `,
}
