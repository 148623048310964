import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import firebase from 'firebase/app'

import { resetErrorAction } from 'root-redux/actions/common'
import { bindUserAction } from 'root-redux/actions/user'
import {
  selectError,
  selectIsFetching,
  selectLanguage,
} from 'root-redux/selects/common'

import { useAuthObserver } from 'hooks/useAuthObserver'
import { useGetRedirectResult } from 'hooks/useGetRedirectResult'
import { useInitFirebase } from 'hooks/useInitFirebase'

import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import { eventLogger } from 'services/eventLogger.service'

import { DesktopProvider } from 'components/DesktopProvider'
import { Modal } from 'components/Modal'
import { Spinner } from 'components/Spinner'
import { SvgImage } from 'components/SvgImage'

import laptopImage from 'assets/images/post-payment-page-img.jpg'
import appleIcon from 'assets/images/sprite/continue-with-apple.svg'
import emailIcon from 'assets/images/sprite/continue-with-email.svg'

import {
  IS_ACCOUNT_VISITED_SESSION_STORAGE_KEY,
  Language,
  LoginMethod,
  PlatformOS,
} from 'root-constants/common'

import { StyledAccount as S, StyledAccountButton } from './Account.styles'
import { EmailAccount } from './components/EmailAccount'

const signInFirebase = (provider) =>
  firebase.auth().signInWithRedirect(provider)

export const Account: React.FC = () => {
  const dispatch = useDispatch()
  const error = useSelector(selectError)
  const isFetching = useSelector(selectIsFetching)
  const language = useSelector(selectLanguage)

  const isAndroid = getMobileOperatingSystem() === PlatformOS.ANDROID

  const [isModalShown, setIsModalShown] = useState(false)
  const [isEmailLoginShown, setIsEmailLoginShown] = useState<boolean>(isAndroid)
  const [isFirebaseDataLoading, setIsFirebaseDataLoading] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    const isAccountVisited = JSON.parse(
      sessionStorage.getItem(IS_ACCOUNT_VISITED_SESSION_STORAGE_KEY) || 'false',
    )

    if (isAccountVisited) return

    eventLogger.logCreateAccountShown()
    sessionStorage.setItem(IS_ACCOUNT_VISITED_SESSION_STORAGE_KEY, 'true')
  }, [])

  useEffect(() => {
    error && setIsModalShown(true)
  }, [error])

  const handleContinueWithApple = useCallback(async () => {
    eventLogger.logLoginMethodSelected({ method: LoginMethod.APPLE })

    await signInFirebase(new firebase.auth.OAuthProvider('apple.com'))
  }, [])

  const handleContinueWithEmail = useCallback(() => {
    setIsEmailLoginShown(true)

    eventLogger.logLoginMethodSelected({ method: LoginMethod.EMAIL })
  }, [])

  const handleButtonsClick = useCallback(
    (event) => {
      if (error) {
        event.stopPropagation()
        setIsModalShown(true)
      }
    },
    [error],
  )

  const authStateChangeHandler = useCallback(
    (token: string) => {
      dispatch(bindUserAction(token))
    },
    [dispatch],
  )

  useInitFirebase()
  useGetRedirectResult(authStateChangeHandler, setIsFirebaseDataLoading)
  useAuthObserver(authStateChangeHandler)

  return (
    <DesktopProvider imageSrc={laptopImage}>
      <S.Wrapper>
        {(isFetching || isFirebaseDataLoading) && <Spinner />}
        {isEmailLoginShown ? (
          <EmailAccount
            onBack={() => {
              setIsEmailLoginShown(false)
            }}
          />
        ) : (
          <S.Column>
            <S.Title>{t`login.titleWelcome`}</S.Title>
            <S.Text>
              <Trans i18nKey="login.subtitle" />
            </S.Text>
            <S.Buttons
              onClickCapture={handleButtonsClick}
              data-is-es-lang={language === Language.ES}
            >
              <StyledAccountButton.Email onClick={handleContinueWithEmail}>
                <SvgImage svg={emailIcon} width={20} />
                <span>{t`login.buttonEmail`}</span>
              </StyledAccountButton.Email>
              {!isAndroid && (
                <StyledAccountButton.Apple onClick={handleContinueWithApple}>
                  <SvgImage svg={appleIcon} width={18} />
                  <span>{t`login.buttonApple`}</span>
                </StyledAccountButton.Apple>
              )}
            </S.Buttons>
          </S.Column>
        )}

        <Modal
          onClose={() => {
            setIsModalShown(false)
            dispatch(resetErrorAction())
          }}
          isShown={isModalShown}
        >
          {error}
        </Modal>
      </S.Wrapper>
    </DesktopProvider>
  )
}
