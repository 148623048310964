import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentVariantSteps } from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { StyledProgressbarWithNumeration as S } from './ProgressbarWithNumeration.styles'

export const ProgressbarWithNumeration: React.FC = () => {
  const { t } = useTranslation()
  const steps = useSelector(selectCurrentVariantSteps)
  const { currentPageId, hasProgressbar } = useGetPageInfo()

  const questionPages = useMemo(
    () => steps.filter(({ isQuestion }) => isQuestion),
    [steps],
  )

  const currentPageIndex = useMemo(
    () => questionPages.findIndex(({ id }) => id === currentPageId),
    [currentPageId, questionPages],
  )

  return !hasProgressbar ? null : (
    <S.Wrapper>
      <S.TextContainer>
        <S.Text>{t`commonComponents.beSureInSafety`}</S.Text>
        <S.Text>
          <strong>{currentPageIndex + 1}</strong>/{questionPages.length}
        </S.Text>
      </S.TextContainer>

      <S.Progress max={questionPages.length} value={currentPageIndex + 1} />
    </S.Wrapper>
  )
}
