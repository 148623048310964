import React from 'react'

import { TPageProps } from 'models/common.model'

import { RadioOptionPage } from 'pages/options-page/RadioOptionPage'

import { INTEREST_OPTION_DATA } from 'root-constants/common'

import { TITLE_PATH } from './constants'

export const PrivateChatVariant1: React.FC<TPageProps> = (props) => (
  <RadioOptionPage
    {...props}
    options={INTEREST_OPTION_DATA}
    titlePath={TITLE_PATH}
    marginBottom={56}
  />
)
