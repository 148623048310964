import styled, { css, keyframes } from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

const fadein = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const progressValueStyles = css`
  border-radius: 10px;
  transition: all 0.2s ease-out;
`
export const StyledPreparingFlowVariant3 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 24px 0 40px;
  `,
  RoundedProgressContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 32px;
  `,
  RoundedProgressText: styled.p`
    padding-left: 16px;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    animation: ${fadein} 0.3s ease-in-out;
  `,
  Title: styled.h2`
    margin-bottom: 32px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
  `,
  ProgressContainer: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 30px;
    grid-template-rows: auto;
    grid-row-gap: 12px;
    grid-template-areas: 'title progressValue' 'progress progress';
    align-items: center;
    margin-bottom: 32px;
  `,
  ProgressTitle: styled.h3`
    grid-area: title;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  `,
  ProgressValue: styled.span`
    grid-area: progressValue;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.LIGHT_TEXT};
  `,
  Progressbar: styled.progress<{ color: string }>`
    grid-area: progress;
    width: 100%;
    height: 6px;
    appearance: none;
    border: none;
    display: block;
    border-radius: 10px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.progressBarBackground};

    &::-webkit-progress-bar {
      background-color: ${({ theme }) => theme.colors.progressBarBackground};
    }
    &::-webkit-progress-value {
      ${progressValueStyles};
      background-color: ${({ color }) => color};
    }
    &::-moz-progress-bar {
      ${progressValueStyles};
      background-color: ${({ color }) => color};
    }
    &::-ms-fill {
      ${progressValueStyles};
      background-color: ${({ color }) => color};
    }
  `,
}
