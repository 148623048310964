import { PageId } from 'root-constants/pages'

export const PAGE_NAME_3_MALE = 'Social proof 3 male'
export const PAGE_NAME_3_FEMALE = 'Social proof 3 female'

export const PAGE_NAMES = {
  [PageId.SOCIAL_PROOF_1]: 'Social proof 1',
  [PageId.SOCIAL_PROOF_2]: 'Social proof 2',
  [PageId.SOCIAL_PROOF_4]: 'Social proof kids',
  [PageId.SOCIAL_PROOF_5]: 'Social proof famio',
  [PageId.SOCIAL_PROOF_7]: 'Social proof 7',
  [PageId.SOCIAL_PROOF_8]: 'Social proof 8',
  [PageId.SOCIAL_PROOF_9]: 'Social proof kids',
  [PageId.SOCIAL_PROOF_10]: 'Social proof - gained piece',
  [PageId.SOCIAL_PROOF_11]: 'Social proof friends',
  [PageId.SOCIAL_PROOF_12]: 'Social proof - gained piece',
  [PageId.SOCIAL_PROOF_13]: 'Social proof find friends',
  [PageId.SOCIAL_PROOF_14]: 'Social proof stay connected',
  [PageId.SOCIAL_PROOF_15]: 'Social proof feel secure',
}

export const CHILD_FLOW_TEXTS_DATA = {
  FIRST_FLOW: {
    firstText: 'onboarding.socialProof.variant20.publicData',
    secondText: 'onboarding.socialProof.variant20.feelSecure',
    marginBottom: 80,
  },
  SECOND_FLOW: {
    firstText: 'onboarding.socialProof.variant20.roadTraffic',
    secondText: 'onboarding.socialProof.variant20.familyTool',
    marginBottom: 28,
  },
  THIRD_FLOW: {
    firstText: 'onboarding.socialProof.variant20.missingDaily',
    secondText: 'onboarding.socialProof.variant20.securityTool',
    marginBottom: 50,
  },
}
