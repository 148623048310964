import React, { useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { useProductId } from 'hooks/useProductId'
import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useVatInfo } from 'hooks/useVatInfo'

import { ISubscription } from 'models/subscriptions.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { Divider } from 'modules/payment/components/Divider'
import { PlanItemVariant2 } from 'modules/payment/components/PlanItem'
import { SelectPlanBlockVariant1 } from 'modules/payment/components/SelectPlanBlock'
import { StripePaymentFormVariant2 } from 'modules/payment/components/StripePaymentForm'
import { Timer } from 'modules/payment/components/Timer'

import { Spinner } from 'components/Spinner'
import { StripePaymentProcessing } from 'components/StripePaymentProcessing'
import { SupportLink } from 'components/SupportLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import paymentServices from 'assets/images/payment-services.png'

import {
  CURRENCY_SYMBOLS,
  INTRO_OFFER_FULL_PERIOD_NAMES,
  PagePath,
  SubscriptionTag,
  TRIAL_QUANTITY_TO_MARKUP_MAP,
} from 'root-constants/common'

import { StyledPaymentVariant5 as S } from './PaymentVariant5.styles'

export const PaymentVariant5: React.FC = () => {
  const { t } = useTranslation()

  const {
    email,
    cohort,
    uuid,
    subscriptions,
    selectedSubscription,
    selectedSubscriptionId,
    trialPrice,
    currentPrice,
    trialPeriodDays,
    currency,
    isPaymentFlowsShown,
    paymentRequestButtonType,
    hasSubscription,
    stripeAccountName,
    stripeAccountId,
    threeDSecureIframeUrl,
  } = usePurchaseStore()

  const { hasPrices, handleSelectSubscription } = usePaywall('')
  const hasIncludedVAT = useVatInfo()
  const productId = useProductId()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  useGetPrices({
    tags: hasIncludedVAT ? SubscriptionTag.TAX : SubscriptionTag.NO_TAX,
  })

  useEffect(() => {
    if (!subscriptions.length) return

    window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })
    googleAnalyticsLogger.logCheckoutStarted(subscriptions)
  }, [subscriptions, uuid])

  useEffect(() => {
    if (hasSubscription || !selectedSubscription) return

    eventLogger.logPurchaseShown({
      productId,
      screenName: ScreenName.ONBOARDING,
      stripeAccountName,
      stripeAccountId,
    })
  }, [
    hasSubscription,
    productId,
    selectedSubscription,
    stripeAccountId,
    stripeAccountName,
  ])

  const sendEvents = useCallback(() => {
    window.fbq('track', 'AddToCart', {}, { eventID: uuid })
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    window.ttq.track('AddToCart')
    googleAnalyticsLogger.logPageView(`${PagePath.SUBSCRIPTIONS}/${cohort}`)
    googleAnalyticsLogger.logAddingToCart(selectedSubscription as ISubscription)
  }, [cohort, email, selectedSubscription, uuid, isPersonalDataAllowed])

  return (
    <S.Wrapper>
      {threeDSecureIframeUrl ? (
        <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
      ) : (
        <S.Content>
          <S.Column>
            <S.Title>{t`payment.variant5.title`}</S.Title>
            <Timer />
            <SelectPlanBlockVariant1
              onSelect={handleSelectSubscription}
              SelectPlanItem={PlanItemVariant2}
            />
            <S.BenefitsContainer>
              <S.BenefitsTitle>{t`onboarding.intro8.youGet`}</S.BenefitsTitle>
              {(
                t('payment.variant5.benefits', {
                  returnObjects: true,
                }) as string[]
              ).map((text) => (
                <S.BenefitsText key={text}>{text}</S.BenefitsText>
              ))}
            </S.BenefitsContainer>
            <S.PaymentServiceImage
              width="256px"
              src={paymentServices}
              decoding="async"
              alt="payments services"
            />
            <S.GuaranteeText>{t`payment.guarantee`}</S.GuaranteeText>
            <S.PaymentRequestButton key={selectedSubscriptionId} />
            {!!paymentRequestButtonType && <Divider />}
            <StripePaymentFormVariant2
              onSendEvents={sendEvents}
              inputBorderColor="#cbc3e7"
            />
            <S.Guarantee />

            <S.Disclaimer>
              <Trans
                i18nKey="payment.variant5.disclaimer"
                values={{
                  price: trialPrice,
                  minimumFractionDigits: 2,
                  currencySymbol: CURRENCY_SYMBOLS[currency],
                  period: t(TRIAL_QUANTITY_TO_MARKUP_MAP[trialPeriodDays]),
                  periodName: t(INTRO_OFFER_FULL_PERIOD_NAMES[trialPeriodDays]),
                  vatInfo: hasIncludedVAT ? t('commonComponents.inclVat') : '',
                  currentPrice: currentPrice.toFixed(2),
                }}
                components={{
                  termsOfUse: <TermsOfUseLink />,
                  supportLink: <SupportLink />,
                }}
              />
            </S.Disclaimer>
          </S.Column>
        </S.Content>
      )}
      <StripePaymentProcessing />
      {(!isPaymentFlowsShown || !hasPrices) && <Spinner />}
    </S.Wrapper>
  )
}
