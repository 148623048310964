import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { FixedContainer } from 'components/FixedContainer'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'

import { Language } from 'root-constants/common'

import { StyledReceiveInformationVariant1 as S } from './ReceiveInformationVariant1.styles'
import { OPTION_VALUES } from './constants'

export const ReceiveInformationVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.receiveInformation.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value, isChecked) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [answers, pageId],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.receiveInformation.title`}</S.Title>
        <S.Subtitle>{t`onboarding.receiveInformation.canChoose`}</S.Subtitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.PARTNER_RIGHT_NOW}
            checked={answers.includes(OPTION_VALUES.PARTNER_RIGHT_NOW)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {t`onboarding.receiveInformation.answers.partnerNow`}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.PARTNER_THROUGHOUT_DAY}
            checked={answers.includes(OPTION_VALUES.PARTNER_THROUGHOUT_DAY)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {t`onboarding.receiveInformation.answers.partnerThroughout`}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.PARTNER_TRUTHFUL}
            checked={answers.includes(OPTION_VALUES.PARTNER_TRUTHFUL)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {t`onboarding.receiveInformation.answers.partnerTruthful`}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.DANGEROUS_SITUATIONS}
            checked={answers.includes(OPTION_VALUES.DANGEROUS_SITUATIONS)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {t`onboarding.receiveInformation.answers.dangerousSituations`}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.SAFE_AT_MOMENT}
            checked={answers.includes(OPTION_VALUES.SAFE_AT_MOMENT)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {t`onboarding.receiveInformation.answers.safeAtMoment`}
            </S.QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <FixedContainer>
          <Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
          >
            {t`actions.continue`}
          </Button>
        </FixedContainer>
      </S.Column>
    </S.Wrapper>
  )
}
