import { IAppState } from 'models/store.model'

import { getPurchaseCommonEventParams } from 'modules/payment/helpers/getPurchaseCommonEventParams'

export const getPurchaseFailedEventParams = (state: IAppState) => {
  const {
    email,
    productId,
    price,
    trialPriceId,
    trialPeriodDays,
    currency,
    screenName,
    stripeAccountName,
    stripeAccountId,
    stripePriceId,
    isPersonalDataAllowed,
  } = getPurchaseCommonEventParams(state)

  return {
    email,
    productId: trialPriceId ? productId : stripePriceId,
    priceDetails: {
      price,
      trial: !!trialPriceId,
      currency,
    },
    isTrialActive: !!trialPeriodDays,
    screenName,
    stripeAccountName,
    stripeAccountId,
    isPersonalDataAllowed,
  }
}
