import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { useUserData } from 'hooks/useUserData'
import { useVatInfo } from 'hooks/useVatInfo'

import { compareSubscriptionList } from 'helpers/compareSubscriptionList'
import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { BenefitsVariant2 } from 'modules/subscriptions/components/benefits'
import { SubheaderWithTimer } from 'modules/subscriptions/components/common/SubheaderWithTimer'
import { SubscriptionDescriptionTrial } from 'modules/subscriptions/components/common/SubscriptionDescription'
import { BenefitsVariant1 } from 'modules/subscriptions/components/google/Benefits'
import { SAFETY_6_BENEFITS_DATA } from 'modules/subscriptions/components/google/Benefits/constants'
import { StepsVariant1 } from 'modules/subscriptions/components/google/Steps'
import { PLAN_ITEMS } from 'modules/subscriptions/constants'

import { Security } from 'components/Security'
import { Spinner } from 'components/Spinner'

import stepsLine from 'assets/images/average-line.png'
import kidImage from 'assets/images/subscriptions-kid-2.png'
import teenImage from 'assets/images/subscriptions-teen.png'

import { ChildFlow, SubscriptionTag } from 'root-constants/common'

import { StyledSubscriptionsVariant6 as S } from './SubscriptionsVariant6.styles'
import { SLIDER_DATA, STEPS_DATA } from './constants'

export const SubscriptionsVariant6: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const scrollToElement = useRef<HTMLDivElement>(null)
  const timerTriggerElementRef = useRef(null)
  const subscriptions = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )

  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)
  const { subscriptionBlockType, subscriptionBlockTitle } =
    useDynamicPaywallConfig()
  const { childFlow } = useUserData()
  const hasIncludedVat = useVatInfo()

  useGetPrices({
    tags: hasIncludedVat ? SubscriptionTag.TAX : SubscriptionTag.NO_TAX,
  })

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
    googleAnalyticsLogger.logCheckoutStarted(subscriptions)
  }, [subscriptions])

  const handleTimerButtonClick = useCallback(() => {
    scrollToElement.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }, [])

  return !hasPrices ? (
    <Spinner />
  ) : (
    <>
      <SubheaderWithTimer
        elemForComparisonRef={timerTriggerElementRef}
        onClick={handleTimerButtonClick}
      />
      <S.Wrapper>
        <S.Column>
          <S.Timer />
          <S.Title>{t`subscriptions.variant6.title`}</S.Title>
          <S.Subtitle>{t`subscriptions.variant6.subtitle`}</S.Subtitle>
        </S.Column>

        <S.ImageWrapper>
          <img
            ref={timerTriggerElementRef}
            src={childFlow === ChildFlow.KID ? kidImage : teenImage}
            alt="kid"
          />
        </S.ImageWrapper>

        <S.Column>
          <BenefitsVariant2 title={t`subscriptions.variant6.benefitsTitle`} />
          <S.SubscriptionsTitle>
            {subscriptionBlockTitle ||
              t`subscriptions.variant6.subscriptionsBlockTitle`}
          </S.SubscriptionsTitle>
          <S.SelectPlanBlock
            ref={scrollToElement}
            onSelect={handleSelectSubscription}
            SelectPlanItem={PLAN_ITEMS[subscriptionBlockType]}
          />
          <SubscriptionDescriptionTrial />
          <S.Button onClick={handleShowPayment}>{t`actions.getPlan`}</S.Button>
        </S.Column>

        <BenefitsVariant1 benefits={SAFETY_6_BENEFITS_DATA} marginBottom={48} />

        <S.Column>
          <S.ReviewSlider reviews={SLIDER_DATA} />
          <S.Button onClick={handleShowPayment}>{t`actions.start`}</S.Button>
          <S.SubscriptionsTitle>
            {subscriptionBlockTitle ||
              t`subscriptions.variant5.subscriptionsTitle`}
          </S.SubscriptionsTitle>
          <S.SelectPlanBlock
            onSelect={handleSelectSubscription}
            SelectPlanItem={PLAN_ITEMS[subscriptionBlockType]}
          />
          <SubscriptionDescriptionTrial />
          <S.Button onClick={handleShowPayment}>{t`actions.getPlan`}</S.Button>
          <S.HowItWorksTitle>{t`onboarding.introGoogle4.howItWorks`}</S.HowItWorksTitle>
          <StepsVariant1
            stepsData={STEPS_DATA}
            height={406}
            image={stepsLine}
            marginBottom={48}
          />
          <Security />
        </S.Column>
      </S.Wrapper>
    </>
  )
}
