import { DefaultTheme } from 'styled-components'

import checkVioletIcon from 'assets/images/check-violet.svg'
import checkYellowIcon from 'assets/images/check-yellow.svg'
import checkboxGreen from 'assets/images/checkbox-green.svg'
import checkboxViolet from 'assets/images/checkbox-violet.svg'
import checkboxYellow from 'assets/images/checkbox-yellow.svg'
import closeButtonWhite from 'assets/images/close-button-white.svg'
import closeButtonGray from 'assets/images/close-icon.svg'
import closedEyeWhiteIcon from 'assets/images/closed-eye-white.svg'
import closedEyeIcon from 'assets/images/closed-eye.svg'
import famyLocateHeaderLogo from 'assets/images/famylocate-header-logo.png'
import headerLogo from 'assets/images/logo.png'
import openedEyeWhiteIcon from 'assets/images/opened-eye-white.svg'
import openedEyeIcon from 'assets/images/opened-eye.svg'

import { HEADER_HEIGHT } from 'root-constants/common'

export const COMMON_THEME: DefaultTheme = {
  common: {
    inputBorder: 'transparent',
    headerBorder: '1px solid #ccdce5',
    progressTitleFontWeight: 800,
    priceFontWeight: 800,
    progressTitleFontSize: '14px',
    progressPercentFontSize: '11px',
    subtitleFontSize: '16px',
    autoCompleteButtonBorder: '1px solid #00000019',
    filter: 'drop-shadow(0px 7px 20px #c8d4d8)',
    headerPadding: '13px 0 12px',
    burgerLinksFontSize: '22px',
    burgerLinksFontWeight: '900',
    burgerLinksLineHeight: '30px',
    buttonLineHeight: '62px',
    inputBorderRadius: '12px',
    stripeInputFormMarginBottom: '8px',
    accountButtonFontSize: '15px',
  },
  colors: {
    activeBackground: '#6336ff',
    activeTextColor: '#fff',
    defaultTextColor: '#0a324d',
    defaultBackground: '#eff5f7',
    disabledButton: 'rgba(99, 54, 255, 0.3)',
    primaryText: '#0a324d',
    secondaryText: '#7b8898',
    tertiaryText: '#0a324d',
    burgerBackground: '#fff',
    burgerLinksColor: '#153e83',
    progressPercentColor: '#7b8898',
    progressBarBackground: '#eff5f7',
    inputPlaceholder: '#bcc9d0',
    inputBackground: '#eff5f7',
    timerBackground: '#0a324d',
    disclaimerText: '#7b8898',
    guaranteeBackground: '#eff5f7',
    guaranteeText: 'rgba(10, 50, 77, 0.7)',
    paymentModalBackground: '#fff',
    priceColor: '#199cf3',
    appleLoginButtonBackground: '#041a28',
    emailTipsBackground: '#fff',
    locationPopupBackground: '#fff',
    defaultProgressBarBackground: '#eff1f4',
    errorTextColor: '#ff0000',
    footerLinkColor: '#bcc9d0',
    accountButtonsColors: '#fff',
    accountTitleColor: '#0a324d',
    modalTextColor: '#0a324d',
  },
  arrangements: {
    columnVerticalAlignment: 'flex-start',
    questionTextAlignment: 'start',
  },
  dimensions: {
    columnHeight: 'auto',
    flexGrow: 0,
    headerLogoHeight: 30,
    buttonHeight: '62px',
  },
  images: {
    checkboxIcon: checkboxViolet,
    openedEyeIcon,
    closedEyeIcon,
    checkIcon: checkVioletIcon,
    headerLogo,
    closeBurgerMenuButton: closeButtonGray,
  },
}

export const SAFETY_THEME: DefaultTheme = {
  common: {
    inputBorder: 'transparent',
    headerBorder: '1px solid #ccdce5',
    progressTitleFontWeight: 800,
    priceFontWeight: 800,
    progressTitleFontSize: '14px',
    progressPercentFontSize: '11px',
    subtitleFontSize: '16px',
    autoCompleteButtonBorder: '1px solid #00000019',
    filter: 'drop-shadow(0px 7px 20px #c8d4d8)',
    headerPadding: '13px 0 12px',
    burgerLinksFontSize: '22px',
    burgerLinksFontWeight: '900',
    burgerLinksLineHeight: '30px',
    buttonLineHeight: '62px',
    inputBorderRadius: '12px',
    stripeInputFormMarginBottom: '8px',
    accountButtonFontSize: '15px',
  },
  colors: {
    activeBackground: '#6336ff',
    activeTextColor: '#fff',
    defaultTextColor: '#0a324d',
    defaultBackground: '#eff5f7',
    disabledButton: 'rgba(99, 54, 255, 0.3)',
    primaryText: '#0a324d',
    secondaryText: '#7b8898',
    tertiaryText: '#0a324d',
    burgerBackground: '#fff',
    burgerLinksColor: '#153e83',
    progressPercentColor: '#7b8898',
    progressBarBackground: '#eff5f7',
    inputPlaceholder: '#bcc9d0',
    inputBackground: '#eff5f7',
    timerBackground: '#0a324d',
    disclaimerText: '#7b8898',
    guaranteeBackground: '#eff5f7',
    guaranteeText: 'rgba(10, 50, 77, 0.7)',
    paymentModalBackground: '#fff',
    priceColor: '#199cf3',
    appleLoginButtonBackground: '#041a28',
    emailTipsBackground: '#fff',
    errorTextColor: '#ff0000',
    footerLinkColor: '#bcc9d0',
    accountButtonsColors: '#fff',
    accountTitleColor: '#0a324d',
    modalTextColor: '#0a324d',
  },
  arrangements: {
    columnVerticalAlignment: 'space-between',
    questionTextAlignment: 'center',
  },
  dimensions: {
    columnHeight: `calc(100svh - ${HEADER_HEIGHT}px)`,
    flexGrow: 1,
    headerLogoHeight: 30,
    buttonHeight: '62px',
  },
  images: {
    checkboxIcon: checkboxViolet,
    openedEyeIcon,
    closedEyeIcon,
    headerLogo,
    closeBurgerMenuButton: closeButtonGray,
  },
}

export const FRIENDS_THEME: DefaultTheme = {
  common: {
    inputBorder: '2px solid #a194c9',
    headerBorder: 'none',
    progressTitleFontWeight: 700,
    priceFontWeight: 400,
    progressTitleFontSize: '16px',
    progressPercentFontSize: '13px',
    subtitleFontSize: '18px',
    autoCompleteButtonBorder: '1px solid #a198ac',
    filter: 'none',
    headerPadding: '13px 0 12px',
    burgerLinksFontSize: '22px',
    burgerLinksFontWeight: '900',
    burgerLinksLineHeight: '30px',
    buttonLineHeight: '62px',
    inputBorderRadius: '12px',
    stripeInputFormMarginBottom: '8px',
    accountButtonFontSize: '15px',
  },
  colors: {
    activeBackground: '#dbf301',
    activeTextColor: '#15042a',
    defaultTextColor: '#fff',
    defaultBackground: 'transparent',
    disabledButton: 'rgba(219, 243, 1, 0.5)',
    primaryText: '#fff',
    secondaryText: '#bcc9d0',
    tertiaryText: '#bcc9d0',
    burgerBackground:
      'conic-gradient(from 103deg at 30.4% 28.11%, #250f41 36.59208744764328deg, #0e041c 60.85077166557312deg, #000 88.37330460548401deg, rgba(75, 37, 184, 0.55) 173.03385257720947deg, rgba(86, 42, 211, 0.60) 185.23337602615356deg, rgba(83, 41, 152, 0.85) 203.9219570159912deg, rgba(129, 47, 165, 0.50) 231.51240348815918deg, #12051f 281.50644063949585deg, #210d3b 341.75859689712524deg), #0b0217',
    burgerLinksColor: '#fff',
    progressPercentColor: '#fff',
    progressBarBackground: '#312b4b',
    inputPlaceholder: '#a198ac',
    inputBackground: '#312b4b',
    timerBackground: '#5c90ff',
    disclaimerText: '#969ea7',
    guaranteeBackground: '#312b4b',
    guaranteeText: '#bcc9d0',
    paymentModalBackground: '#3c3659',
    priceColor: '#bcc9d0',
    appleLoginButtonBackground: '#fff',
    emailTipsBackground: '#312b4b',
    errorTextColor: '#ff0000',
    footerLinkColor: '#bcc9d0',
    accountButtonsColors: '#15042a',
    accountTitleColor: '#fff',
    modalTextColor: '#fff',
  },
  arrangements: {
    columnVerticalAlignment: 'flex-start',
    questionTextAlignment: 'center',
  },
  dimensions: {
    columnHeight: 'auto',
    flexGrow: 0,
    headerLogoHeight: 30,
    buttonHeight: '62px',
  },
  images: {
    checkboxIcon: checkboxGreen,
    openedEyeIcon: openedEyeWhiteIcon,
    closedEyeIcon: closedEyeWhiteIcon,
    headerLogo,
    closeBurgerMenuButton: closeButtonGray,
  },
}

export const FAMYLOCATE_THEME: DefaultTheme = {
  ...COMMON_THEME,
  common: {
    ...COMMON_THEME.common,
    headerBorder: '1px solid rgba(255, 255, 255, 0.10)',
    headerPadding: '8px 0',
    burgerLinksFontSize: '20px',
    burgerLinksFontWeight: '600',
    burgerLinksLineHeight: '24px',
    buttonLineHeight: '56px',
    inputBorderRadius: '30px',
    stripeInputFormMarginBottom: '12px',
    accountButtonFontSize: '14px',
  },
  colors: {
    ...COMMON_THEME.colors,
    locationPopupBackground:
      'linear-gradient(180deg, #574FCF 2.34%, #3734A6 100%)',
    activeBackground: '#ffd651',
    disabledButton: 'rgba(255, 214, 81, 0.5)',
    defaultTextColor: '#fff',
    defaultProgressBarBackground: 'rgba(255, 255, 255, 0.10)',
    activeTextColor: '#35319f',
    errorTextColor: '#fc5774',
    burgerBackground: '#e8f1ff',
    burgerLinksColor: '#2d3240',
    tertiaryText: '#fff',
    inputPlaceholder: '#616a77',
    footerLinkColor: '#dfecff',
    accountButtonsColors: '#fff',
    secondaryText: '#bcc9d0',
    defaultBackground: 'transparent',
    accountTitleColor: '#fff',
    modalTextColor: '#0a324d',
  },
  dimensions: {
    ...COMMON_THEME.dimensions,
    headerLogoHeight: 36,
    buttonHeight: '56px',
  },
  images: {
    ...COMMON_THEME.images,
    checkboxIcon: checkboxYellow,
    checkIcon: checkYellowIcon,
    headerLogo: famyLocateHeaderLogo,
    closeBurgerMenuButton: closeButtonWhite,
  },
}
