import React, { useCallback } from 'react'

import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { ISelectPlanItemProps } from 'models/common.model'
import { ISubscription } from 'models/subscriptions.model'

import { OptionType } from 'components/Option'

import { StyledSelectPlanBlockVariant1 as S } from './SelectPlanBlockVariant1.styles'

type TProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onSelect'> & {
  onSelect: (subscription?: ISubscription) => void
  SelectPlanItem: React.FC<ISelectPlanItemProps>
}

export const SelectPlanBlockVariant1: React.FC<TProps> = ({
  onSelect,
  SelectPlanItem,
}) => {
  const { subscriptions, selectedSubscriptionId } = usePurchaseStore()

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = subscriptions.find(
        (subscription) => subscription.id === value,
      )
      onSelect(checkedSubscription)
    },
    [onSelect, subscriptions],
  )

  return (
    <S.Wrapper>
      {subscriptions.map((subscription) => (
        <S.Option
          type={OptionType.RADIO}
          name="subscription"
          value={subscription.id}
          key={subscription.id}
          onChange={handleChange}
        >
          <SelectPlanItem
            subscription={subscription}
            isSelected={subscription.id === selectedSubscriptionId}
          />
        </S.Option>
      ))}
    </S.Wrapper>
  )
}
