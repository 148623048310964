import React, { useEffect, useMemo } from 'react'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { useProductId } from 'hooks/useProductId'
import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { TPageProps } from 'models/common.model'
import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import {
  PaymentFlowsGoogleVariant2,
  PaymentFlowsGoogleVariant3,
} from 'modules/payment/components/PaymentFlowsGoogle'

import { Spinner } from 'components/Spinner'
import { StripePaymentProcessing } from 'components/StripePaymentProcessing'

import { Cohort, PagePath, SubscriptionTag } from 'root-constants/common'

import { StyledPaymentVariant3 as S } from './PaymentVariant3.styles'

export const PaymentVariant3: React.FC<TPageProps> = () => {
  const {
    email,
    cohort,
    uuid,
    selectedSubscription,
    hasSubscription,
    isPaymentFlowsShown,
    stripeAccountId,
    stripeAccountName,
    screenName,
    threeDSecureIframeUrl,
  } = usePurchaseStore()

  const { hasPrices } = usePaywall('')
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  useGetPrices({ tags: SubscriptionTag.NO_TAX })
  const productId = useProductId()

  const isThirteenOrFourteenCohort = useMemo(
    () => cohort === Cohort.GOOGLE_13 || cohort === Cohort.GOOGLE_14,
    [cohort],
  )

  useEffect(() => {
    if (!selectedSubscription) return

    window.fbq('track', 'AddToCart', {}, { eventID: uuid })
    window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })
    googleAnalyticsLogger.logPageView(`${PagePath.SUBSCRIPTIONS}/${cohort}`)
    googleAnalyticsLogger.logAddingToCart(selectedSubscription as ISubscription)
  }, [cohort, selectedSubscription, uuid])

  useEffect(() => {
    if (hasSubscription || !selectedSubscription) return

    eventLogger.logPurchaseShown({
      productId,
      screenName,
      stripeAccountName,
      stripeAccountId,
    })
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    window.ttq.track('AddToCart')
  }, [
    email,
    hasSubscription,
    productId,
    screenName,
    selectedSubscription,
    stripeAccountId,
    stripeAccountName,
    isPersonalDataAllowed,
  ])

  return (
    <S.Wrapper>
      {threeDSecureIframeUrl ? (
        <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
      ) : (
        <>
          <StripePaymentProcessing />
          <S.Content>
            {isThirteenOrFourteenCohort ? (
              <PaymentFlowsGoogleVariant3 />
            ) : (
              <PaymentFlowsGoogleVariant2 />
            )}
          </S.Content>
        </>
      )}
      {(!isPaymentFlowsShown || !hasPrices) && <Spinner />}
    </S.Wrapper>
  )
}
