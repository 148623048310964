import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledDownloadAppVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Title: styled.h2`
    margin: 40px 0 38px;
    color: ${Color.WHITE};
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
  `,
  List: styled.ul`
    margin-bottom: 20px;

    li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
  `,
  ListLabel: styled.div`
    flex-shrink: 0;
    width: 26px;
    height: 26px;
    margin-right: 14px;
    font-size: 16px;
    font-weight: 800;
    line-height: 28px;
    text-align: center;
    border-radius: 8px;
    color: ${Color.WHITE};
    background-color: #312b4b;
  `,
  ListText: styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${Color.WHITE};
  `,
  ImageWrapper: styled.div`
    width: 100%;
    margin-bottom: 30px;
    aspect-ratio: 313/189;
  `,
}
