import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { resetErrorAction } from 'root-redux/actions/common'
import {
  getShortenedOneLinkAction,
  sendUserAnswersAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import { selectAmplitudeApiKey, selectError } from 'root-redux/selects/common'
import { selectShortenedOneLink } from 'root-redux/selects/user'

import { getOperatingSystemVersion } from 'helpers/getOperatingSystemVersion'

import { eventLogger } from 'services/eventLogger.service'

import { Button } from 'components/Button'
import { Modal } from 'components/Modal'
import { Spinner } from 'components/Spinner'

import friendsImage from 'assets/images/download-friends.png'

import { StyledDownloadAppVariant2 as S } from './DownloadAppVariant2.styles'

export const DownloadAppVariant2: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const amplitudeApiKey = useSelector(selectAmplitudeApiKey)
  const shortenedOneLink = useSelector(selectShortenedOneLink)
  const error = useSelector(selectError)

  const [isErrorModalShown, setIsErrorModalShown] = useState(false)

  useLayoutEffect(() => {
    dispatch(getShortenedOneLinkAction(() => setIsErrorModalShown(true)))
  }, [dispatch])

  useEffect(() => {
    eventLogger.logGettingAppShown()
  }, [])

  useEffect(() => {
    dispatch(sendUserAnswersAction(true))
    dispatch(
      sendUserConfigAction({
        is_download_visited: true,
        operating_system: getOperatingSystemVersion(),
      }),
    )
  }, [dispatch])

  const handleContinue = useCallback(() => {
    window.location.href = shortenedOneLink
  }, [shortenedOneLink])

  const handleClick = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault()

      if (amplitudeApiKey) {
        eventLogger.logDownloadClicked(handleContinue)
        return
      }

      eventLogger.logDownloadClicked()
      handleContinue()
    },
    [handleContinue, amplitudeApiKey],
  )

  return (
    <>
      {!shortenedOneLink && <Spinner />}
      {shortenedOneLink && (
        <S.Wrapper>
          <S.Column>
            <S.Title>
              <Trans i18nKey="download.title2" />
            </S.Title>

            <S.List>
              <li>
                <S.ListLabel>1</S.ListLabel>
                <S.ListText>{t`download.item1`}</S.ListText>
              </li>
              <li>
                <S.ListLabel>2</S.ListLabel>
                <S.ListText>{t`download.item2`}</S.ListText>
              </li>
            </S.List>

            <S.ImageWrapper>
              <img src={friendsImage} alt="friends" />
            </S.ImageWrapper>

            <Button type="button" onClick={handleClick}>
              {t`download.button`}
            </Button>
          </S.Column>
        </S.Wrapper>
      )}
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
      >
        {error}
      </Modal>
    </>
  )
}
