import React from 'react'

import { TPageProps } from 'models/common.model'

import { RadioOptionPage } from 'pages/options-page/RadioOptionPage'

import { LOW_CONFIDENCE_OPTION_DATA } from 'root-constants/common'

import { TITLE_PATH } from './constants'

export const DrivingHabitsVariant2: React.FC<TPageProps> = (props) => (
  <RadioOptionPage
    {...props}
    options={LOW_CONFIDENCE_OPTION_DATA}
    titlePath={TITLE_PATH}
    marginBottom={40}
    wrapperPaddingTop={100}
    columnPaddingTop={24}
  />
)
