import { Cohort } from 'root-constants/common'

export const EMAIL_DOMAINS = [
  'gmail.com',
  'hotmail.com',
  'yahoo.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'live.com',
  'msn.com',
  'me.com',
  'mac.com',
  'googlemail.com',
  'facebook.com',
  'verizon.net',
  'rediffmail.com',
  'zohomail.com',
  'zoho.com',
  'mail.com',
  'google.com',
  'comcast.com',
  'hotmail.co.uk',
  'yahoo.co.uk',
  'att.net',
  'gmz.com',
]

export const TOP_EMAIL_DOMAINS = ['@gmail.com', '@yahoo.com', '@hotmail.com']

export const EMAIL_DOMAIN_REGEXP = /@(.*)/
export const EMAIL_USERNAME_REGEXP = /(.*)@/
export const SUBTITLES = {
  [Cohort.SAFETY_4]: {
    common: 'onboarding.email.subtitle',
    alternative: 'onboarding.email.subtitle2',
  },
  [Cohort.SAFETY_6]: {
    common: 'onboarding.email.subtitle6',
  },
  [Cohort.GOOGLE_1]: {
    common: 'onboarding.email.subtitle',
  },
  [Cohort.GOOGLE_2]: {
    common: 'onboarding.email.subtitle',
  },
  [Cohort.GOOGLE_3]: {
    common: 'onboarding.email.subtitle',
  },
  [Cohort.GOOGLE_4]: {
    alternative: 'onboarding.email.subtitle4',
  },
  [Cohort.SPOUSE_1]: {
    alternative: 'onboarding.email.subtitle2',
  },
  [Cohort.FRIENDS_1]: {
    common: 'onboarding.email.subtitle3',
  },
  [Cohort.GOOGLE_16]: {
    common: 'onboarding.email.subtitle',
  },
}
