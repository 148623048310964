import React from 'react'

import { TPageProps } from 'models/common.model'

import { RadioOptionPage } from 'pages/options-page/RadioOptionPage'

import { SIMPLE_OPTION_DATA } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { TITLE_PATHS } from './constants'

export const LowBatteryVariant4: React.FC<TPageProps> = (props) => (
  <RadioOptionPage
    {...props}
    pageName={PageId.LOW_BATTERY_4}
    options={SIMPLE_OPTION_DATA}
    titlePath={TITLE_PATHS[PageId.LOW_BATTERY_4]}
    wrapperPaddingTop={100}
    columnPaddingTop={24}
    marginBottom={40}
  />
)
