import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { GET_SUBSCRIPTION_LIST } from 'root-redux/actions/common'
import { GET_STATUS } from 'root-redux/actions/user'
import {
  selectActionList,
  selectCurrentVariantCohort,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { ISubscription } from 'models/subscriptions.model'

import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { setSelectedSubscriptionAction } from 'modules/payment/redux/actions'
import { selectSubscription } from 'modules/payment/redux/selects'

import { goTo } from 'browser-history'
import { PagePath } from 'root-constants/common'

export const usePaywall = (
  nextPagePath: string,
): {
  hasPrices: boolean
  handleShowPayment: () => void
  handleSelectSubscription: () => void
} => {
  const dispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort)
  const selectedSubscription = useSelector(selectSubscription)
  const uuid = useSelector(selectUUID)
  const subscriptionsList = useSelector(selectSubscriptionList)
  const fetchingActionsList = useSelector(selectActionList)

  const hasPrices = useMemo(
    () =>
      !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST) &&
      !fetchingActionsList?.includes(GET_STATUS) &&
      !!subscriptionsList.length,
    [fetchingActionsList, subscriptionsList],
  )

  const handleShowPayment = useCallback(() => {
    window.fbq('track', 'AddToCart', {}, { eventID: uuid })
    window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })
    googleAnalyticsLogger.logPageView(`${PagePath.SUBSCRIPTIONS}/${cohort}`)
    googleAnalyticsLogger.logAddingToCart(selectedSubscription as ISubscription)

    goTo(nextPagePath)
  }, [cohort, nextPagePath, selectedSubscription, uuid])

  const handleSelectSubscription = useCallback(
    (subscription?: ISubscription): void => {
      subscription && dispatch(setSelectedSubscriptionAction(subscription))
    },
    [dispatch],
  )

  return {
    hasPrices,
    handleShowPayment,
    handleSelectSubscription,
  }
}
