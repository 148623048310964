import styled from 'styled-components'

import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import friendsImage from 'assets/images/friends-intro.webp'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { MediaBreakpoint } from 'root-constants/common'

export const StyledIntroVariant5 = {
  Wrapper: styled.div`
    ${basePageStyles};
    width: 100%;
    background: #0b0217;
    height: 100dvh;
  `,
  ImageContainer: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
    min-height: 402px;
    background-image: url(${friendsImage});
    background-size: 100% auto;
    background-repeat: no-repeat;
    aspect-ratio: 375/402;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  FixedContainer: styled.div`
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 100px 0 18px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    text-align: center;
    margin: 0 auto;
  `,
  Title: styled.h1`
    font-weight: 800;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 32px;
    color: ${({ theme }) => theme.colors.primaryText};
  `,
  OptionsContainer: styled(StyledOption.OptionsContainer)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 12px;

    div {
      min-width: 150px;
      flex-shrink: 0;
    }
  `,
  QuestionButton: styled(QuestionButton)`
    justify-content: center;
  `,
}
