import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { selectTaxAmount } from 'root-redux/selects/common'
import { selectGeneralSubscriptionTrialPeriod } from 'root-redux/selects/user'

import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'

import { getPriceWithoutTaxes } from 'helpers/getPriceWithoutTaxes'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { ChipoloPlanItems } from 'modules/payment/components/ChipoloPlanItems'
import { DeliveryForm } from 'modules/payment/components/DeliveryForm'
import { ChipoloColor } from 'modules/payment/constants'
import { purchaseUpgradeV2 } from 'modules/payment/redux/actions'
import {
  selectSubscriptionFullPrice,
  selectUpgradeAmountToPay,
} from 'modules/payment/redux/selects'

import { Spinner } from 'components/Spinner'
import { StripePaymentProcessing } from 'components/StripePaymentProcessing'

import { goTo } from 'browser-history'
import {
  DISCLAIMER_CONTEXTS,
  SubscriptionListType,
  SubscriptionTag,
  TrialPeriod,
} from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StyledChipoloCheckout as S } from './ChipoloCheckout.styles'

export const ChipoloCheckout: React.FC = () => {
  const { search } = useLocation()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const fullPrice = useSelector(selectSubscriptionFullPrice)
  const upgradePrice = useSelector(selectUpgradeAmountToPay)
  const taxAmount = useSelector(selectTaxAmount)
  const trialPeriodDays = useSelector(selectGeneralSubscriptionTrialPeriod)

  const [chipoloColor, setChipoloColor] = useState<string>(ChipoloColor.BLUE)

  const { hasPrices } = usePaywall('')

  useGetPrices({
    subscriptionType: SubscriptionListType.UPGRADE,
    tags: SubscriptionTag.TAX,
    screenName: ScreenName.UPGRADE,
  })

  const upgradePriceWithoutTaxes = useMemo(
    () =>
      getPriceWithoutTaxes({
        price:
          trialPeriodDays === TrialPeriod.ONE_WEEK ? fullPrice : upgradePrice,
        taxAmount,
      }),
    [fullPrice, taxAmount, trialPeriodDays, upgradePrice],
  )

  useEffect(() => {
    eventLogger.logUpsellChipoloCheckoutShown()

    return () => {
      eventLogger.logUpsellChipoloCheckoutClosed()
    }
  }, [])

  const upgradePlan = useCallback(() => {
    dispatch(purchaseUpgradeV2(chipoloColor))
  }, [chipoloColor, dispatch])

  const handleClose = useCallback(() => {
    goTo({ pathname: PageId.ACCOUNT, search })
  }, [search])

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.CloseButton type="button" onClick={handleClose} />
      <S.Column>
        <S.Title>{t`subscriptions.chipolo.checkout.title`}</S.Title>
        <ChipoloPlanItems
          chipoloColor={chipoloColor}
          onSetChipoloColor={setChipoloColor}
          upgradePriceWithoutTaxes={upgradePriceWithoutTaxes}
        />

        <S.SubscriptionInfo>
          <S.SubscriptionInfoText>{t`subscriptions.chipolo.checkout.subtotal`}</S.SubscriptionInfoText>
          <S.SubscriptionInfoText justifySelf="flex-end">
            <strong>${upgradePriceWithoutTaxes}</strong>
          </S.SubscriptionInfoText>

          <S.SubscriptionInfoText>{t`subscriptions.chipolo.checkout.shipping`}</S.SubscriptionInfoText>
          <S.SubscriptionInfoText justifySelf="flex-end">{t`subscriptions.chipolo.checkout.free`}</S.SubscriptionInfoText>

          <S.SubscriptionInfoText fontSize={16}>
            <Trans
              i18nKey="subscriptions.chipolo.checkout.totalWithVat"
              components={{ span: <span /> }}
            />
          </S.SubscriptionInfoText>
          <S.SubscriptionInfoText justifySelf="flex-end" fontSize={16}>
            <strong>
              $
              {(trialPeriodDays === TrialPeriod.ONE_WEEK
                ? fullPrice
                : upgradePrice
              ).toFixed(2)}
            </strong>
          </S.SubscriptionInfoText>
        </S.SubscriptionInfo>

        <S.Disclaimer>
          {trialPeriodDays !== TrialPeriod.ONE_WEEK && (
            <li>{t`subscriptions.chipolo.checkout.autoRenewable`}</li>
          )}

          <li>
            {t('subscriptions.chipolo.checkout.disclaimer', {
              context: DISCLAIMER_CONTEXTS[trialPeriodDays],
            })}
          </li>
        </S.Disclaimer>

        <DeliveryForm onUpgradePlan={upgradePlan} />
      </S.Column>
      <StripePaymentProcessing onTryAgain={upgradePlan} hasChipolo />
    </S.Wrapper>
  )
}
