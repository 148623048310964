import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { TAnswer } from 'models/common.model'

import {
  ChildAge,
  ChildAgeRange,
  ChildFlow,
  Frequency,
  Gender,
  SINGLE_GOAL_LENGTH,
  UserGoal,
} from 'root-constants/common'
import { PageId } from 'root-constants/pages'

export const useUserData = (): {
  userGender: string
  isFemale: boolean
  hasConcernsAboutPartnerSafety: boolean
  userGoalsFormatted: TAnswer
  singleUserGoal: string
  isTeenPath: boolean
  userPhoneNumber: string
  userGoals: TAnswer
  childFlow: string
} => {
  const answers = useSelector(selectAnswers)

  const userGender = useMemo(
    () => answers?.[PageId.GENDER_1] as string,
    [answers],
  )

  const isFemale = useMemo(
    () => answers?.[PageId.GENDER_1] === Gender.FEMALE,
    [answers],
  )

  const hasConcernsAboutPartnerSafety = useMemo(
    () =>
      answers?.[PageId.CONCERNS_1] === Frequency.QUITE_OFTEN ||
      answers?.[PageId.CONCERNS_1] === Frequency.SOMETIMES,
    [answers],
  )

  const userGoals = useMemo(
    () => answers?.[PageId.INTRO_2] || answers?.[PageId.INTRO_4] || [],
    [answers],
  )

  const userGoalsFormatted = useMemo(() => {
    if (!userGoals.includes(UserGoal.OTHER)) {
      return userGoals
    }

    const specificGoals = (userGoals as string[]).filter(
      (goal) => goal !== UserGoal.OTHER,
    )

    return userGoals.includes(UserGoal.FRIEND)
      ? specificGoals
      : [...specificGoals, UserGoal.FRIEND]
  }, [userGoals])

  const singleUserGoal = useMemo(
    () =>
      userGoalsFormatted.length === SINGLE_GOAL_LENGTH
        ? userGoalsFormatted[0]
        : '',
    [userGoalsFormatted],
  )

  const isTeenPath = useMemo(() => {
    const childAge = answers?.[PageId.CHILD_AGE_1]
    return (
      singleUserGoal === UserGoal.KID &&
      (childAge === ChildAgeRange.TEENAGER || childAge === ChildAgeRange.ADULT)
    )
  }, [answers, singleUserGoal])

  const userPhoneNumber = useMemo(() => {
    const phonePageId =
      [
        PageId.PHONE_NUMBER_1,
        PageId.PHONE_NUMBER_2,
        PageId.INTRO_GOOGLE_4,
        PageId.SUBSCRIPTIONS_GOOGLE_1,
        PageId.SUBSCRIPTIONS_GOOGLE_2,
        PageId.SUBSCRIPTIONS_GOOGLE_3,
        PageId.SUBSCRIPTIONS_GOOGLE_4,
        PageId.SUBSCRIPTIONS_GOOGLE_5,
        PageId.INTRO_8,
      ].find((page) => answers?.[page]) || ''
    return answers?.[phonePageId] as string
  }, [answers])

  const childFlow = useMemo(() => {
    const childAge = (answers?.[PageId.AGE_RANGE_1] as string[]) || []
    const isKidFlow = childAge.every((item) => +item < ChildAge.FIFTEEN_YEARS)
    const isTeenageFlow = childAge.every((item) => +item > ChildAge.TEN_YEARS)
    if (isKidFlow) {
      return ChildFlow.KID
    }
    if (isTeenageFlow) {
      return ChildFlow.TEENAGE
    }
    return ChildFlow.MIXED
  }, [answers])

  return {
    userGender,
    isFemale,
    hasConcernsAboutPartnerSafety,
    userGoalsFormatted,
    singleUserGoal,
    isTeenPath,
    userPhoneNumber,
    userGoals,
    childFlow,
  }
}
