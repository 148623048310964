import styled from 'styled-components'

import { QuestionButton } from 'components/QuestionButton'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color } from 'root-constants/common'

export const StyledFeelAnxiousVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: 100px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 24px 0 29px;
  `,
  QuestionButton: styled(QuestionButton)`
    max-height: 76px;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
  `,
  Text: styled.p`
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: ${Color.LIGHT_TEXT};
    margin-top: 12px;
    text-align: center;
    margin-bottom: 24px;
  `,
}
