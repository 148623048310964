import React, { useEffect, useLayoutEffect } from 'react'
import { useLocation } from 'react-router'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useProductId } from 'hooks/useProductId'
import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { DesktopProvider } from 'components/DesktopProvider/DesktopProvider'
import { Spinner } from 'components/Spinner'
import { StripePaymentProcessing } from 'components/StripePaymentProcessing'

import { replaceHistory } from 'browser-history'

import { PaymentFlows } from '../../components/PaymentFlows'
import { StyledPaymentVariant1 as S } from './PaymentVariant1.styles'

export const PaymentVariant1: React.FC<TPageProps> = () => {
  const { search } = useLocation()

  const {
    email,
    selectedSubscriptionId,
    hasSubscription,
    isPaymentFlowsShown,
    stripeAccountId,
    stripeAccountName,
    screenName,
    threeDSecureIframeUrl,
  } = usePurchaseStore()

  const productId = useProductId()
  const { currentSubscriptionPageId } = useGetPageInfo()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  useLayoutEffect(() => {
    if (!selectedSubscriptionId) {
      replaceHistory({ pathname: currentSubscriptionPageId, search })
    }
  }, [currentSubscriptionPageId, search, selectedSubscriptionId])

  useEffect(() => {
    if (hasSubscription || !selectedSubscriptionId) return

    eventLogger.logPurchaseShown({
      productId,
      screenName,
      stripeAccountName,
      stripeAccountId,
    })
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    window.ttq.track('AddToCart')
  }, [
    email,
    hasSubscription,
    productId,
    screenName,
    selectedSubscriptionId,
    stripeAccountId,
    stripeAccountName,
    isPersonalDataAllowed,
  ])

  return (
    <DesktopProvider>
      <S.Wrapper>
        {threeDSecureIframeUrl ? (
          <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
        ) : (
          <>
            <StripePaymentProcessing />
            <S.Content>
              <PaymentFlows />
            </S.Content>
          </>
        )}
        {!isPaymentFlowsShown && <Spinner />}
      </S.Wrapper>
    </DesktopProvider>
  )
}
