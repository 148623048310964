import styled from 'styled-components'

import { Option } from 'components/Option'

export const StyledSelectPlanBlockVariant1 = {
  Wrapper: styled.div`
    width: 100%;
    margin-bottom: 12px;
  `,
  Option: styled(Option)`
    width: 100%;
  `,
}
