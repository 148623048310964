import styled from 'styled-components'

import { BackButton, baseColumnStyles } from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants/common'

type TProps = {
  isAndroid?: boolean
}
export const StyledEmailAccount = {
  Wrapper: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
    position: relative;
    color: var(--base-text-color);

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      position: relative;
      width: 400px;
      height: calc(100vh - 56px);
      padding-top: 67px;
    }
  `,
  Title: styled.h1<TProps>`
    margin-bottom: ${({ isAndroid }) => (isAndroid ? 20 : 42)}px;
    font-size: 26px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: ${({ theme }) => theme.colors.primaryText};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-weight: 800;
      font-size: 48px;
      line-height: 65px;
    }
  `,
  Text: styled.p`
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 87px;
    }
  `,
  BackButton: styled(BackButton)`
    position: absolute;
    left: -16px;
    top: 20px;
  `,
  Description: styled.span`
    position: relative;
    display: block;
    margin-bottom: 12px;
    font-size: 11px;
    line-height: 20px;
    text-align: center;
    opacity: 0.6;

    a {
      color: inherit;
      text-decoration: underline;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 13px;
      margin-bottom: 20px;
    }
  `,
  Footer: styled.footer`
    margin-top: 30px;
    color: ${({ theme }) => theme.colors.tertiaryText};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      button {
        width: 460px;
        height: 76px;
        font-size: 24px;
        line-height: 76px;
      }
    }
  `,
}
