import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserData } from 'hooks/useUserData'

import { formatPhoneNumber } from 'helpers/phone'

import { StyledPhoneNumberLabel as S } from './PhoneNumberLabel.styles'

export const PhoneNumberLabel: React.FC = () => {
  const { t } = useTranslation()
  const { userPhoneNumber } = useUserData()

  const formattedPhoneNumber = useMemo(
    () => formatPhoneNumber(userPhoneNumber),
    [userPhoneNumber],
  )

  return (
    <S.Wrapper>
      <S.Text>{t`commonComponents.locating`}</S.Text>
      <S.Text>
        <strong>{formattedPhoneNumber}</strong>
      </S.Text>
    </S.Wrapper>
  )
}
