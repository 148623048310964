import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserData } from 'hooks/useUserData'

import { formatPhoneNumber } from 'helpers/phone'

import greyCheckIcon from 'assets/images/check-icon-grey.svg'
import violetCheckIcon from 'assets/images/check-icon-violet.svg'

import { StyledSearchUser as S } from './SearchUser.styles'

type TProps = {
  isFinished: boolean
  counter: number
}
export const SearchUser: React.FC<TProps> = ({ isFinished, counter }) => {
  const { t } = useTranslation()
  const { userPhoneNumber } = useUserData()

  const formattedPhoneNumber = useMemo(
    () => formatPhoneNumber(userPhoneNumber),
    [userPhoneNumber],
  )

  return (
    <S.Wrapper isFinished={isFinished}>
      <S.PhoneNumberWrapper>
        <S.Title>{t`onboarding.locationSearch.searchingFor`}</S.Title>
        <S.PhoneNumber>
          {t(`onboarding.locationSearch.phoneNumber`, {
            phoneNumber: formattedPhoneNumber,
          })}
        </S.PhoneNumber>
      </S.PhoneNumberWrapper>
      <S.StepsWrapper>
        <S.Steps>
          <img
            src={counter > 30 ? violetCheckIcon : greyCheckIcon}
            alt="icon"
          />
          <S.Text>{t`onboarding.locationSearch.connectBaseStation`}</S.Text>
        </S.Steps>
        <S.Steps>
          <img
            src={counter > 60 ? violetCheckIcon : greyCheckIcon}
            alt="icon"
          />
          <S.Text>{t`onboarding.locationSearch.networkOperator`}</S.Text>
        </S.Steps>
        <S.Steps>
          <img
            src={counter > 90 ? violetCheckIcon : greyCheckIcon}
            alt="icon"
          />
          <S.Text>{t`onboarding.locationSearch.connectingPhone`}</S.Text>
        </S.Steps>
      </S.StepsWrapper>
      <S.ProgressBarContainer>
        <S.ProgressBar max={100} value={counter} />
      </S.ProgressBarContainer>
    </S.Wrapper>
  )
}
