import React, { useCallback, useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { useVatInfo } from 'hooks/useVatInfo'

import { compareSubscriptionList } from 'helpers/compareSubscriptionList'
import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { BenefitsVariant3 } from 'modules/subscriptions/components/benefits/BenefitsVariant3'
import { SelectPlanBlockVariant1 } from 'modules/subscriptions/components/common/SelectPlanBlockVariant1'
import { SubheaderWithTimer } from 'modules/subscriptions/components/common/SubheaderWithTimer'
import { Timer } from 'modules/subscriptions/components/common/Timer'
import { PLAN_ITEMS } from 'modules/subscriptions/constants'

import { Guarantee } from 'components/Guarantee'
import { ReviewsSlider2 } from 'components/ReviewsSlider'
import { REVIEWS_DATA } from 'components/ReviewsSlider/constants'
import { Spinner } from 'components/Spinner'

import friendsImage from 'assets/images/subscriptions-friends-2.png'

import { SubscriptionTag, UserGoal } from 'root-constants/common'

import { StyledSubscriptionsVariant4 as S } from './SubscriptionsVariant4.styles'

export const SubscriptionsVariant4: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const scrollToElement = useRef<HTMLDivElement>(null)
  const timerTriggerElementRef = useRef(null)
  const subscriptions = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )

  const hasIncludedVAT = useVatInfo()
  const { subscriptionBlockType, subscriptionBlockTitle } =
    useDynamicPaywallConfig()
  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)

  useGetPrices({
    tags: hasIncludedVAT ? SubscriptionTag.TAX : SubscriptionTag.NO_TAX,
  })

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
      onboardingId: UserGoal.FRIEND,
    })
    googleAnalyticsLogger.logCheckoutStarted(subscriptions)
  }, [subscriptions])

  const handleTimerButtonClick = useCallback(() => {
    scrollToElement.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }, [])

  return !hasPrices ? (
    <Spinner />
  ) : (
    <>
      <SubheaderWithTimer
        elemForComparisonRef={timerTriggerElementRef}
        onClick={handleTimerButtonClick}
      />
      <S.Wrapper>
        <S.Column>
          <S.Title>{t`subscriptions.variant2.titleFriend`}</S.Title>
          <S.Subtitle>
            <Trans i18nKey="subscriptions.variant2.subtitleFriend2" />
          </S.Subtitle>
          <Timer />
        </S.Column>
        <S.ImageWrapper>
          <img ref={timerTriggerElementRef} src={friendsImage} alt="friends" />
        </S.ImageWrapper>
        <S.Column>
          <BenefitsVariant3 userGoal={UserGoal.FRIEND} />
          <S.SubscriptionsTitle>
            {subscriptionBlockTitle ||
              t`subscriptions.variant5.subscriptionsTitle`}
          </S.SubscriptionsTitle>
          <SelectPlanBlockVariant1
            ref={scrollToElement}
            onSelect={handleSelectSubscription}
            SelectPlanItem={PLAN_ITEMS[subscriptionBlockType]}
          />
          <S.Button onClick={handleShowPayment}>{t`actions.getPlan`}</S.Button>
          <S.SubscriptionDescriptionTrial />
          <Guarantee />
          <ReviewsSlider2 reviews={REVIEWS_DATA.friendGoalReviews} />
          <S.Button onClick={handleShowPayment}>{t`actions.start`}</S.Button>
          <S.Security />
        </S.Column>
      </S.Wrapper>
    </>
  )
}
