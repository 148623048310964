import accelerationIndication from 'assets/images/acceleration-indications.png'
import accelerationIcon from 'assets/images/acceseleration-icon.png'
import tracksIcon from 'assets/images/bracking-tracks-icon.png'
import brakingTracks from 'assets/images/bracking-tracks.png'
import speedIcon from 'assets/images/high-speed-icon.png'
import phoneMonitoring from 'assets/images/phone-monitoring.png'
import phoneIcon from 'assets/images/phone-usage-icon.png'
import speedAlerts from 'assets/images/speed-alerts.png'

export const BENEFITS_DATA = [
  { image: speedIcon, text: 'subscriptions.upsell.benefitsData.speedAlerts' },
  {
    image: tracksIcon,
    text: 'subscriptions.upsell.benefitsData.brakingTracks',
  },
  {
    image: accelerationIcon,
    text: 'subscriptions.upsell.benefitsData.accelerationIndications',
  },
  { image: phoneIcon, text: 'subscriptions.upsell.benefitsData.phoneUsage' },
]

export const PRODUCTS_DATA = [
  {
    image: speedAlerts,
    name: 'subscriptions.upsell.benefitsData.speedAlerts',
    text: 'subscriptions.upsell.productsDescription.drivingParameters',
  },
  {
    image: brakingTracks,
    name: 'subscriptions.upsell.benefitsData.brakingTracks',
    text: 'subscriptions.upsell.productsDescription.drivingHabits',
  },
  {
    image: accelerationIndication,
    name: 'subscriptions.upsell.benefitsData.accelerationIndications',
    text: 'subscriptions.upsell.productsDescription.drivingStyle',
  },
  {
    image: phoneMonitoring,
    name: 'subscriptions.upsell.benefitsData.phoneUsage',
    text: 'subscriptions.upsell.productsDescription.drivingBehavior',
  },
]
