import { IRequestOptions, IResponseResult } from 'models/api.model'
import { TAnswers } from 'models/common.model'
import {
  IBindUserResponseRaw,
  IDeliveryInfo,
  IGetUserStatusResponseRaw,
  TUserConfig,
} from 'models/user.model'

import { ApiService } from 'services/api.service'

import {
  APP_NAME,
  RequestMethod,
  TEST_ENV_QUERY_PARAM,
} from 'root-constants/common'

export class UserApi {
  private readonly baseUrlV1 = 'v1/flow'
  private readonly baseUrlV2 = 'v2/leads'
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  bindUser({
    token,
    uuid,
  }: {
    token: string
    uuid: string
  }): Promise<IResponseResult<IBindUserResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        token,
      },
    }

    return this.api.makeRequest<IBindUserResponseRaw>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/signup`,
      options,
    )
  }

  getUserStatus(
    uuid: string,
  ): Promise<IResponseResult<IGetUserStatusResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<string>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}`,
      options,
    )
  }

  getUUID({
    cohort,
    appId,
    locale,
    promoCode = '',
    utm,
  }: {
    cohort: string
    appId: string
    locale: string
    promoCode?: string
    utm: Record<string, string>
  }): Promise<IResponseResult<string>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        cohort,
        locale,
        app_id: appId,
        app_name: APP_NAME,
        utm,
        promo_code: promoCode,
        is_test_environment:
          document.location.search.includes(TEST_ENV_QUERY_PARAM),
      },
    }

    return this.api.makeRequest<IGetUserStatusResponseRaw>(
      this.baseUrlV2,
      options,
    )
  }

  saveFacebookParams({
    fbc,
    fbp,
    uuid,
  }: {
    uuid: string
    fbc: string
    fbp: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        fbc,
        fbp,
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/facebook`,
      options,
    )
  }

  saveUserAnswers({
    uuid,
    answers,
    isFinished = false,
    isSkipped = false,
  }: {
    uuid: string
    answers: TAnswers
    isFinished?: boolean
    isSkipped?: boolean
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        is_finished: isFinished,
        is_skipped: isSkipped,
        quiz: answers,
        version: '2.0',
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/quiz`,
      options,
    )
  }

  saveUserConfig({
    uuid,
    config,
  }: {
    uuid: string
    config: TUserConfig
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        config,
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/config`,
      options,
    )
  }

  saveUserEmail({
    uuid,
    email,
  }: {
    uuid: string
    email: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        email: email.toLowerCase(),
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/email`,
      options,
    )
  }

  savePlanAdditions({
    uuid,
    planAdditions,
  }: {
    uuid: string
    planAdditions: string[]
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: { addons: planAdditions },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/addons`,
      options,
    )
  }

  saveDeliveryUserInfo({
    uuid,
    chipoloColor,
    deliveryInfo: {
      country,
      firstName,
      lastName,
      address,
      optionalAddress,
      city,
      state,
      zipCode,
      phone,
    },
  }: {
    uuid: string
    chipoloColor: string
    deliveryInfo: IDeliveryInfo
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        device_color: chipoloColor,
        country,
        first_name: firstName,
        last_name: lastName,
        address_line1: address,
        city,
        state,
        zip_code: zipCode,
        phone,
        ...(optionalAddress && {
          address_line2: optionalAddress,
        }),
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/address`,
      options,
    )
  }

  getAutologinToken(
    uuid: string,
  ): Promise<IResponseResult<{ status: boolean; custom_token: string }>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        uuid,
        app_name: APP_NAME,
      },
    }

    return this.api.makeRequest<never>(`${this.baseUrlV1}/login`, options)
  }
}
