import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useCounter } from 'hooks/useCounter'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'
import { useProgressBarList } from 'hooks/useProgressBarList'

import { TPageProps } from 'models/common.model'

import { ProgressRounded } from 'components/ProgressRounded'

import { StyledPreparingFlowVariant3 as S } from './PreparingFlowVariant3.styles'
import {
  PAGE_NAME,
  PROGRESS_DURATION,
  ROUNDED_PROGRESS_DURATION,
  ROUNDED_PROGRESS_TEXTS,
} from './constants'

export const PreparingFlowVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [progressText, setProgressText] = useState('')

  const { title } = useDynamicOBConfig()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  const {
    firstCounterPercent,
    secondCounterPercent,
    thirdCounterPercent,
    fourthCounterPercent,
    isFourthProcessingFinished,
  } = useProgressBarList(true, PROGRESS_DURATION)

  useEffect(() => {
    if (!isFourthProcessingFinished) return
    handleContinue('')
  }, [handleContinue, isFourthProcessingFinished])

  const { linearCounterValue } = useCounter(true, {
    duration: ROUNDED_PROGRESS_DURATION,
  })

  const barsList = useMemo(
    () => [
      {
        listTitle: 'onboarding.preparingFlow.firstProgressBar4',
        color: '#199cf3',
        counter: firstCounterPercent,
      },
      {
        listTitle: 'onboarding.preparingFlow.secondProgressBar3',
        color: '#7879f1',
        counter: secondCounterPercent,
      },
      {
        listTitle: 'onboarding.preparingFlow.thirdProgressBar4',
        color: '#f24281',
        counter: thirdCounterPercent,
      },
      {
        listTitle: 'onboarding.preparingFlow.fourthProgressBar2',
        color: '#0fb600',
        counter: fourthCounterPercent,
      },
    ],
    [
      firstCounterPercent,
      secondCounterPercent,
      thirdCounterPercent,
      fourthCounterPercent,
    ],
  )

  useEffect(() => {
    const loaderPoint = ROUNDED_PROGRESS_TEXTS.find(
      ({ threshold }) => threshold === linearCounterValue,
    )

    if (loaderPoint) {
      setProgressText(loaderPoint.text)
    }
  }, [linearCounterValue])

  return (
    <S.Wrapper>
      <S.Column>
        <S.RoundedProgressContainer>
          <ProgressRounded value={linearCounterValue} />
          <S.RoundedProgressText key={progressText}>
            {t(progressText)}...
          </S.RoundedProgressText>
        </S.RoundedProgressContainer>

        <S.Title>{title || t`onboarding.preparingFlow.title`}</S.Title>
        {barsList.map(({ color, counter, listTitle }) => (
          <S.ProgressContainer key={listTitle}>
            <S.ProgressTitle>
              <Trans i18nKey={listTitle} />
            </S.ProgressTitle>
            <S.ProgressValue>{counter}%</S.ProgressValue>
            <S.Progressbar max={100} value={counter} color={color} />
          </S.ProgressContainer>
        ))}
      </S.Column>
    </S.Wrapper>
  )
}
