import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { useVatInfo } from 'hooks/useVatInfo'

import { compareSubscriptionList } from 'helpers/compareSubscriptionList'
import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { BenefitsVariant2 } from 'modules/subscriptions/components/benefits'
import { BenefitsVariant1 } from 'modules/subscriptions/components/google/Benefits'
import { SAFETY_6_BENEFITS_DATA } from 'modules/subscriptions/components/google/Benefits/constants'
import { StepsVariant1 } from 'modules/subscriptions/components/google/Steps'
import {
  PLAN_ITEMS,
  SAFETY_6_STEPS_DATA,
} from 'modules/subscriptions/constants'

import { ReviewsSlider2 } from 'components/ReviewsSlider'
import { Spinner } from 'components/Spinner'

import stepsLine from 'assets/images/average-line.png'
import benefitsCheckbox from 'assets/images/checkbox-violet.svg'
import coupleImg from 'assets/images/subscriptions-couple.webp'

import { SubscriptionTag } from 'root-constants/common'

import { SubheaderWithTimer } from '../../components/common/SubheaderWithTimer'
import { Timer } from '../../components/common/Timer'
import { StyledSubscriptionsVariant10 as S } from './SubscriptionsVariant10.styles'

export const SubscriptionsVariant10: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const scrollToElement = useRef<HTMLDivElement>(null)
  const timerTriggerElementRef = useRef(null)
  const subscriptions = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )

  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)
  const { subscriptionBlockType, subscriptionBlockTitle } =
    useDynamicPaywallConfig()
  const hasIncludedVat = useVatInfo()

  useGetPrices({
    tags: hasIncludedVat ? SubscriptionTag.TAX : SubscriptionTag.NO_TAX,
  })

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  const handleTimerButtonClick = useCallback(() => {
    scrollToElement.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }, [])

  return !hasPrices ? (
    <Spinner />
  ) : (
    <>
      <SubheaderWithTimer
        elemForComparisonRef={timerTriggerElementRef}
        onClick={handleTimerButtonClick}
      />
      <S.Wrapper>
        <S.Title>{t`subscriptions.variant10.title`}</S.Title>
        <S.Column>
          <S.Subtitle>{t`subscriptions.variant10.subtitle`}</S.Subtitle>
          <Timer />
        </S.Column>
        <S.ImageWrapper>
          <img ref={timerTriggerElementRef} src={coupleImg} alt="couple" />
        </S.ImageWrapper>
        <S.Column>
          <BenefitsVariant2
            title={t`subscriptions.variant10.benefits.title`}
            checkboxIcon={benefitsCheckbox}
          />
          <S.SubscriptionsTitle>
            {subscriptionBlockTitle ||
              t`subscriptions.variant5.subscriptionsTitle`}
          </S.SubscriptionsTitle>
          <S.SelectPlanBlock
            ref={scrollToElement}
            onSelect={handleSelectSubscription}
            SelectPlanItem={PLAN_ITEMS[subscriptionBlockType]}
          />
          <S.SubscriptionDescriptionTrial />
          <S.Button onClick={handleShowPayment}>{t`actions.getPlan`}</S.Button>
        </S.Column>
        <BenefitsVariant1 benefits={SAFETY_6_BENEFITS_DATA} marginBottom={48} />
        <S.Column>
          <ReviewsSlider2 />
          <S.Button onClick={handleShowPayment}>{t`actions.start`}</S.Button>
          <S.SubscriptionsTitle>
            {subscriptionBlockTitle ||
              t`subscriptions.variant5.subscriptionsTitle`}
          </S.SubscriptionsTitle>
          <S.SelectPlanBlock
            onSelect={handleSelectSubscription}
            SelectPlanItem={PLAN_ITEMS[subscriptionBlockType]}
          />
          <S.SubscriptionDescriptionTrial />
          <S.Button onClick={handleShowPayment}>{t`actions.getPlan`}</S.Button>
          <S.CommonTitle>{t`onboarding.introGoogle4.howItWorks`}</S.CommonTitle>
          <StepsVariant1
            stepsData={SAFETY_6_STEPS_DATA}
            height={406}
            image={stepsLine}
          />
          <S.Security />
        </S.Column>
      </S.Wrapper>
    </>
  )
}
