import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import socialProof from 'assets/images/social-proof-16.webp'

import { StyledSocialProofVariant16 as S } from './SocialProofVariant16.styles'

export const SocialProofVariant16: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.ImageContainer>
        <img src={socialProof} alt="couple" />
      </S.ImageContainer>
      <S.Column>
        <S.Percents>
          <Trans i18nKey="onboarding.socialProof.variant16.title" />
        </S.Percents>
        <S.Text>
          <Trans i18nKey="onboarding.socialProof.variant16.note" />
        </S.Text>
        <Button type="button" onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </Button>
      </S.Column>
    </S.Wrapper>
  )
}
