import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import YouTube from 'react-youtube'

import {
  selectLanguage,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import { selectHasUserEmail } from 'root-redux/selects/user'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { useScrollToAnchor } from 'hooks/useScrollToAnchor'
import { useViewportValue } from 'hooks/useViewportValue'

import { compareSubscriptionList } from 'helpers/compareSubscriptionList'
import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import {
  BenefitsVariant1,
  BenefitsVariant2,
  BenefitsVariant3,
} from 'modules/subscriptions/components/google/Benefits'
import { PhoneForm } from 'modules/subscriptions/components/google/PhoneForm'
import { StepsVariant1 } from 'modules/subscriptions/components/google/Steps'
import { SubscriptionsBlockVariant1 } from 'modules/subscriptions/components/google/SubscriptionsBlockVariant1'
import {
  UNIQUE_VIEWS_NUMBER,
  YOUTUBE_VIDEO_ID,
} from 'modules/subscriptions/constants'

import { Security } from 'components/Security'
import { Spinner } from 'components/Spinner'

import { goTo } from 'browser-history'
import {
  CDN_FOLDER_LINK,
  Images,
  Language,
  SubscriptionTag,
} from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledSubscriptionsGoogleVariant2 as S } from './SubscriptionsGoogleVariant2.styles'

export const SubscriptionsGoogleVariant2: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const scrollToElement = useRef<HTMLParagraphElement>(null)
  const subscriptions = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )
  const language = useSelector(selectLanguage)
  const hasUserEmail = useSelector(selectHasUserEmail)

  const [phoneNumber, setPhoneNumber] = useState('')
  const [videoStartCounter, setVideoStartCounter] = useState(0)

  useGetPrices({ tags: SubscriptionTag.NO_TAX })
  useViewportValue()
  const { hasPrices } = usePaywall(nextPagePath)
  const { currentPaymentPageId } = useGetPageInfo()
  useScrollToAnchor(hasPrices)

  useLayoutEffect(() => {
    if (hasUserEmail) {
      goTo({ pathname: currentPaymentPageId, search })
    }
  }, [search, hasUserEmail, currentPaymentPageId])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  const handleButtonScroll = useCallback(() => {
    scrollToElement.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }, [])

  const handleVideoClick = useCallback(() => {
    setVideoStartCounter((prevState) => prevState + 1)
    if (videoStartCounter < UNIQUE_VIEWS_NUMBER) {
      eventLogger.logVideoPlayStarted()
    }
  }, [videoStartCounter])

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper id="1">
      <S.BurgerMenu />
      <S.WideColumn>
        <S.Title>
          <Trans i18nKey="subscriptions.subscriptionsGoogle.variant1.getLocation" />
        </S.Title>
        <S.Text ref={scrollToElement}>
          <Trans i18nKey="subscriptions.subscriptionsGoogle.variant1.allYouNeed" />
        </S.Text>
      </S.WideColumn>
      <PhoneForm
        buttonText="subscriptions.subscriptionsGoogle.variant1.locateTheNumber"
        pageId={CustomPageId.PHONE_NUMBER}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
      />
      <S.PhoneImageContainer>
        <img
          src={
            language === Language.DE
              ? `${CDN_FOLDER_LINK}${Images.GOOGLE_SUBS_PHONE}_${Language.EN}.jpg`
              : `${CDN_FOLDER_LINK}${Images.GOOGLE_SUBS_PHONE}_${language}.jpg`
          }
          alt="phone"
        />
      </S.PhoneImageContainer>
      <BenefitsVariant1 />
      <S.Column>
        <BenefitsVariant2 />
        <BenefitsVariant3 />
        <S.Button type="button" onClick={handleButtonScroll}>
          {t`subscriptions.subscriptionsGoogle.variant1.subscribe`}
        </S.Button>
      </S.Column>
      <S.VideoContainer>
        <YouTube videoId={YOUTUBE_VIDEO_ID} onPlay={handleVideoClick} />
      </S.VideoContainer>
      <S.Column id="3">
        <S.CommonTitle>{t`onboarding.introGoogle4.howItWorks`}</S.CommonTitle>
        <StepsVariant1 />
        <S.EmailSubtitle id="4">{t`onboarding.introGoogle4.steps.enterThePhone`}</S.EmailSubtitle>
      </S.Column>
      <PhoneForm
        buttonText="onboarding.introGoogle4.phoneButton"
        pageId={CustomPageId.PHONE_NUMBER}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
      />
      <SubscriptionsBlockVariant1 ref={scrollToElement} />
      <S.Column>
        <Security />
      </S.Column>
    </S.Wrapper>
  )
}
