import { TrackingData } from 'root-constants/common'

export const PAGE_NAME = 'Preparing Personal Flow'
export const ROUNDED_PROGRESS_DURATION = 10000
export const CHILD_FLOW_PROGRESS_DURATION = 5000
export const PROGRESS_DURATION = 2500

export const ROUNDED_PROGRESS_TEXTS = [
  {
    text: 'onboarding.preparingFlow.firstProgressBar4',
    threshold: 0,
  },
  {
    text: 'onboarding.preparingFlow.secondProgressBar3',
    threshold: 25,
  },
  {
    text: 'onboarding.preparingFlow.thirdProgressBar4',
    threshold: 50,
  },
  {
    text: 'onboarding.preparingFlow.fourthProgressBar2',
    threshold: 75,
  },
]

export const CHILD_FLOW_PROGRESS_TEXTS = {
  [TrackingData.MOVEMENT_HISTORY]: 'onboarding.data.answers.movementHistory',
  [TrackingData.LOCATION_NOTIFICATIONS]:
    'onboarding.data.answers.locationNotifications',
  [TrackingData.SOS_NOTIFICATIONS]: 'onboarding.data.answers.sosNotifications',
  [TrackingData.BATTERY_ALERTS]: 'onboarding.data.answers.batteryAlerts',
  [TrackingData.DRIVING_MODE]: 'onboarding.data.answers.drivingMode2',
  [TrackingData.PROXIMITY_ALERTS]:
    'onboarding.data.answers.proximityAlertsShort',
}
