import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { useGetUserPosition } from 'hooks/useGetUserPosition'
import { useUserData } from 'hooks/useUserData'

import { formatPhoneNumber } from 'helpers/phone'

import checkVioletIcon from 'assets/images/check-violet.svg'
import locationIcon from 'assets/images/location-violet-icon.svg'

import { StyledFoundUser as S } from './FoundUser.styles'

type TProps = {
  isFinished: boolean
  handleContinue: (answer: string) => void
}

export const FoundUser: React.FC<TProps> = ({ isFinished, handleContinue }) => {
  const { t } = useTranslation()
  const answers = useSelector(selectAnswers)

  const { userPhoneNumber } = useUserData()
  const { timeZone } = useGetUserPosition()

  const formattedPhoneNumber = useMemo(
    () => formatPhoneNumber(userPhoneNumber),
    [userPhoneNumber],
  )

  return (
    <S.Wrapper isFinished={isFinished}>
      <S.LocationIcon src={locationIcon} alt="icon" />
      <S.PhoneNumberWrapper>
        <S.Title>{t`onboarding.email.numberLocated`}</S.Title>
        <S.PhoneNumber>
          {t(`onboarding.locationSearch.phoneNumber`, {
            phoneNumber: formattedPhoneNumber,
          })}
        </S.PhoneNumber>
      </S.PhoneNumberWrapper>
      <S.LocationDataWrapper>
        <S.LocationData>
          <S.Text>{t`onboarding.locationSearch.country`}</S.Text>
          <S.Text>{answers?.userPhoneCountry}</S.Text>
        </S.LocationData>
        <S.LocationData>
          <S.Text>{t`onboarding.locationSearch.timezone`}</S.Text>
          <S.Text>
            {t(`onboarding.locationSearch.gmt`, {
              timeZone,
            })}
          </S.Text>
        </S.LocationData>
        <S.LocationData>
          <S.Text>{t`onboarding.locationSearch.city`}</S.Text>
          <S.Text isBlurred>Chicago</S.Text>
        </S.LocationData>
        <S.LocationData>
          <S.Text>{t`onboarding.locationSearch.location`}</S.Text>
          <img src={checkVioletIcon} alt="icon" />
          <S.Defined>{t`onboarding.locationSearch.defined`}</S.Defined>
        </S.LocationData>
      </S.LocationDataWrapper>
      <S.Button type="button" onClick={() => handleContinue('')}>
        {t`actions.continue`}
      </S.Button>
    </S.Wrapper>
  )
}
