import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  selectCurrentVariantCohort,
  selectGiaApiKeys,
  selectLanguage,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import { selectUUID, selectUtmTags } from 'root-redux/selects/user'

import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { getEnvironment } from 'helpers/getEnvironment'

import { Amplitude } from 'services/amplitude.service'
import { eventLogger } from 'services/eventLogger.service'
import { UserFlowTracker } from 'services/userFlowTracker.service'

import {
  CurrentEnvironment,
  EventLoggerInstanceName,
  TEST_ENV_QUERY_PARAM,
} from 'root-constants/common'

function getGIAAmplitudeOptions() {
  const btoaAuthCredentials = 'cHJveHktdXNlcjozQyk9Q3YncTd5L0A4Pjcp'

  return {
    apiEndpoint:
      getCurrentEnv() === CurrentEnvironment.PROD
        ? 'analytics.gismart.xyz/events'
        : 'analytics.gidev.xyz/events',
    headers: {
      Authorization: `Bearer ${btoaAuthCredentials}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    forceHttps: true,
  }
}

export const useGiaFlowTrackerInitialization = (): void => {
  const { search } = useLocation()
  const uuid = useSelector(selectUUID)
  const cohort = useSelector(selectCurrentVariantCohort)
  const giaApiKeys = useSelector(selectGiaApiKeys)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const stripeAccountName = useSelector(selectStripeAccountName)
  const language = useSelector(selectLanguage)
  const utmTags = useSelector(selectUtmTags)

  const [giaInstance, setGiaInstance] = useState<Amplitude | null>(null)

  const URLParams = new URLSearchParams(search)
  const isTestEnvironment = URLParams.has(TEST_ENV_QUERY_PARAM)
  const utmTagsFromUrl = Object.fromEntries(URLParams.entries())
  const { isProdEnvironment } = getEnvironment()

  useEffect(() => {
    if (uuid && giaApiKeys) {
      const giaService = new Amplitude({
        apiKey: giaApiKeys[language],
        instanceName: EventLoggerInstanceName.GIA,
        options: getGIAAmplitudeOptions(),
        userId: uuid,
        utmTags: utmTagsFromUrl.utm_source ? utmTagsFromUrl : utmTags,
        isTestEnvironment,
        isDevEnvironment: !isProdEnvironment,
      })

      const userFlowTracker = new UserFlowTracker({
        instanceName: EventLoggerInstanceName.USER_FLOW_TRACKER,
        userId: uuid,
      })

      giaService.configure({ cohort, language })
      userFlowTracker.configure({ cohort })

      eventLogger.init(giaService, userFlowTracker)
      setGiaInstance(giaService)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giaApiKeys, uuid])

  useEffect(() => {
    if (!stripeAccountId || !stripeAccountName) return

    giaInstance?.updateUserProperties(stripeAccountId, stripeAccountName)
  }, [giaInstance, stripeAccountId, stripeAccountName])
}
