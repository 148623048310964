import styled from 'styled-components'

import { BurgerMenu } from 'components/BurgerMenu'
import { Button } from 'components/Button'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledSubscriptionsGoogleVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
    padding-bottom: 25px;
  `,
  BurgerMenu: styled(BurgerMenu)`
    button {
      top: 6px;
      right: 6px;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        top: 3px;
        right: 3px;
      }
    }
  `,
  WideColumn: styled.div`
    ${baseColumnStyles};
    max-width: 331px;
    width: 100%;
  `,
  Title: styled.h1`
    color: #0a324d;
    font-size: 32px;
    font-weight: 800;
    line-height: 38px;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 12px;

    strong {
      font-size: 32px;
      font-weight: 800;
      line-height: 38px;
      color: #5c90ff;
    }
  `,
  Text: styled.p`
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 24px;
  `,
  PhoneImageContainer: styled.div`
    width: 100%;
    aspect-ratio: 375/415;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
  `,
  KidImageContainer: styled.div`
    width: 100%;
    max-width: 375px;
    aspect-ratio: 375/375;
    margin-top: 48px;
  `,
  Button: styled(Button)`
    line-height: 27px;
  `,
  CommonTitle: styled.h2`
    font-size: 24px;
    font-weight: 800;
    line-height: 30px;
    margin-top: 48px;
  `,
  EmailSubtitle: styled.p`
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    margin: 48px 0 16px;
    text-align: center;
  `,
}
