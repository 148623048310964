import { StripeError } from '@stripe/stripe-js'

import { IRequestOptions, IResponseResult } from 'models/api.model'
import {
  IPrimerClientSessionResponseRaw,
  IPurchaseResponseRaw,
  IPurchaseRetrySubscriptionResponseRaw,
  IUpgradeResponseRaw,
} from 'models/payment.model'

import { ApiService } from 'services/api.service'

import { APP_NAME, RequestMethod } from 'root-constants/common'

export class PaymentApi {
  private readonly baseUrl = 'v2/purchases'
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  createSubscription({
    uuid,
    planId,
    cohort,
    trialPeriodDays,
    promoCode,
  }: {
    uuid: string
    planId: number
    cohort: string
    trialPeriodDays: number
    promoCode?: string
  }): Promise<IResponseResult<IPurchaseResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        cohort,
        plan_id: planId,
        trial_duration: trialPeriodDays,
        promo_code: promoCode,
      },
    }

    return this.api.makeRequest<IPurchaseResponseRaw>(
      `${this.baseUrl}/stripe/${uuid}/${APP_NAME}`,
      options,
    )
  }

  upgradeSubscription({
    uuid,
    planId,
    cohort,
  }: {
    uuid: string
    planId: number
    cohort: string
  }): Promise<IResponseResult<IUpgradeResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        plan_id: planId,
        cohort,
      },
    }

    return this.api.makeRequest<IUpgradeResponseRaw>(
      `${this.baseUrl}/stripe/${uuid}/${APP_NAME}/upgrade`,
      options,
    )
  }

  retryPayment({
    uuid,
    stripeError,
  }: {
    uuid: string
    stripeError: StripeError
  }): Promise<IResponseResult<IPurchaseRetrySubscriptionResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        payment_error: stripeError,
      },
    }
    return this.api.makeRequest<IPurchaseRetrySubscriptionResponseRaw>(
      `${this.baseUrl}/stripe/${uuid}/${APP_NAME}/retry`,
      options,
    )
  }

  createPaypalSubscription({
    uuid,
    paypalPlanId,
    cohort,
  }: {
    uuid: string
    paypalPlanId: string | null
    cohort: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        paypal_plan_id: paypalPlanId,
        cohort,
      },
    }
    return this.api.makeRequest<never>(
      `${this.baseUrl}/paypal/${uuid}/${APP_NAME}`,
      options,
    )
  }

  getPrimerClientSessionToken({
    uuid,
    priceId,
    trialPriceId,
    trialDuration,
  }: {
    uuid: string
    priceId: string
    trialPriceId: string
    trialDuration: number
  }): Promise<IResponseResult<IPrimerClientSessionResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        recurring_id: priceId,
        ...(!!trialPriceId && { intro_id: trialPriceId }),
        ...(!!trialDuration && { trial_duration: trialDuration }),
      },
    }
    return this.api.makeRequest<IPrimerClientSessionResponseRaw>(
      `${this.baseUrl}/primer/${uuid}/${APP_NAME}/token`,
      options,
    )
  }

  createPrimerSubscription({
    uuid,
    paymentId,
  }: {
    uuid: string
    paymentId: string
  }): Promise<IResponseResult<IPurchaseResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        payment_id: paymentId,
      },
    }
    return this.api.makeRequest<IPurchaseResponseRaw>(
      `${this.baseUrl}/primer/${uuid}/${APP_NAME}`,
      options,
    )
  }

  makeUpsell({
    uuid,
    planId,
    productId,
    promoCode,
  }: {
    uuid: string
    planId: number
    productId: string
    promoCode?: string
  }): Promise<IResponseResult<IPurchaseResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        plan_id: planId,
        description: productId,
        promo_code: promoCode,
      },
    }

    return this.api.makeRequest<IPurchaseResponseRaw>(
      `${this.baseUrl}/stripe/${uuid}/${APP_NAME}/upsell`,
      options,
    )
  }
}
