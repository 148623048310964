import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { PoliciesGroup } from 'components/PoliciesGroup'

import { Confidence, Language } from 'root-constants/common'

import { StyledIntroVariant6 as S } from './IntroVariant6.styles'

export const IntroVariant6: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.location.questionPartner', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <>
      <S.BurgerMenu />
      <S.Wrapper>
        <S.ImageContainer />
        <S.FixedContainer>
          <S.Column>
            <S.Title>{t`onboarding.location.questionPartner`}</S.Title>
            <S.OptionsContainer>
              <Option
                {...optionProps}
                value={Confidence.YES}
                disabled={isAnswersDisabled}
              >
                <S.QuestionButton>{t`commonComponents.yes`}</S.QuestionButton>
              </Option>
              <Option
                {...optionProps}
                value={Confidence.NO}
                disabled={isAnswersDisabled}
              >
                <S.QuestionButton>{t`commonComponents.no`}</S.QuestionButton>
              </Option>
            </S.OptionsContainer>
            <Option
              {...optionProps}
              value={Confidence.NOT_SURE}
              disabled={isAnswersDisabled}
            >
              <S.NotSureButton>{t`commonComponents.notSure`}</S.NotSureButton>
            </Option>
            <PoliciesGroup />
          </S.Column>
        </S.FixedContainer>
      </S.Wrapper>
    </>
  )
}
