import { PhoneInput } from 'react-international-phone'

import styled, { createGlobalStyle, css } from 'styled-components'

import { Button } from 'components/Button'

import arrow from 'assets/images/arrow.svg'
import backIcon from 'assets/images/back.svg'
import crossIcon from 'assets/images/cross.svg'
import richCrossIcon from 'assets/images/rich-cross.svg'

import { BASE_COLUMN_WIDTH, MediaBreakpoint } from 'root-constants/common'

export const BackgroundFriendsGlobalStyles = createGlobalStyle<{
  hasDarkenBg: boolean
}>`
  :root {
    --base-background: ${({ hasDarkenBg }) =>
      hasDarkenBg
        ? 'linear-gradient(166deg, #0B0217 0.89%, #261043 57.71%)'
        : `conic-gradient(from 103deg at 30.4% 28.11%, #250F41 36.59208744764328deg, #0E041C 60.85077166557312deg, #000 88.37330460548401deg, rgba(75, 37, 184, 0.55) 173.03385257720947deg, rgba(86, 42, 211, 0.60) 185.23337602615356deg, rgba(83, 41, 152, 0.85) 203.9219570159912deg, rgba(129, 47, 165, 0.50) 231.51240348815918deg, #12051F 281.50644063949585deg, #210D3B 341.75859689712524deg), #0B0217`}
  }
`

export const basePageStyles = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const baseColumnStyles = css`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: ${BASE_COLUMN_WIDTH}px;
`

export const baseAdaptiveColumnStyles = css`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 360px;
  padding: 0 25px;

  @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
    max-width: 510px;
  }
`

export const baseTitleStyles = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
`

export const baseAdaptiveTitleStyles = css`
  ${baseTitleStyles};

  @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
  }
`

export const baseInputStyles = css`
  display: block;
  width: 100%;
  height: 56px;
  padding: 17px;
  color: ${({ theme }) => theme.colors.primaryText};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  border-radius: 12px;
  border: 1px solid transparent;
  background-color: ${({ theme }) => theme.colors.inputBackground};
  outline: none;
  transition: border 0.2s ease-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.inputPlaceholder};
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.inputPlaceholder};
  }
`

export const ModalWrapperBase = css`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-y: scroll;
  padding: 40px 0;
`

export const ModalContainerBase = css`
  width: 288px;
  padding: 24px 24px 32px;
  margin: auto;
  background-color: ${({ theme }) => theme.colors.paymentModalBackground};
  border-radius: 16px;

  @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
    width: 538px;
    padding: 51px 69px 100px;
  }
`

export const CloseButton = styled.button`
  box-sizing: content-box;
  width: 24px;
  height: 24px;
  display: block;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  background-image: url(${crossIcon});
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`

export const BackButton = styled.button`
  box-sizing: content-box;
  width: 24px;
  height: 24px;
  display: block;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  background-image: url(${backIcon});
  background-size: 34px 34px;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`

export const StyledPhoneInput = styled(PhoneInput)`
  position: relative;
  width: 311px;
  display: flex;
  align-items: center;
  justify-content: start;
  border-radius: 12px;
  border: 1px solid #7854f8;
  background: #fff;
  padding: 0 16px;
  height: 56px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  button {
    border: none;
  }

  .react-international-phone-country-selector {
    position: static;
  }

  .react-international-phone-country-selector-button:hover {
    background-color: transparent;
  }

  .react-international-phone-country-selector-button__button-content {
    min-width: 62px;
    justify-content: space-between;
  }

  .react-international-phone-country-selector-button__dropdown-arrow {
    border: none;
    background-image: url(${arrow});
    width: 20px;
    height: 20px;
  }

  .react-international-phone-input {
    background-color: transparent;
    border: none;
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .react-international-phone-country-selector-dropdown {
    width: 285px;
    top: 56px;
    left: 50%;
    transform: translateX(-50%);
  }
`

export const StyledPaymentStatus = {
  Wrapper: styled.div`
    ${ModalWrapperBase};
  `,
  Container: styled.div`
    ${ModalContainerBase};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  `,
  Icon: styled.img`
    aspect-ratio: 108/108;
    width: 100%;
    max-width: 108px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 142px;
    }
  `,
  Title: styled.h2`
    margin: 16px 0 12px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: ${({ theme }) => theme.colors.primaryText};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: 21px;
      font-size: 32px;
      line-height: 36px;
    }
  `,
  Subtitle: styled.h3`
    max-width: 288px;
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: ${({ theme }) => theme.colors.tertiaryText};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 538px;
      margin-bottom: 41px;
      font-size: 18px;
      line-height: 24px;
    }
  `,
  Button: styled(Button)`
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      height: 76px;
      font-size: 24px;
      line-height: 76px;
      border-radius: 100px;
    }
  `,
  Disclaimer: styled.p`
    margin: 0 -8px;
    padding-top: 16px;
    color: ${({ theme }) => theme.colors.secondaryText};
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    line-height: normal;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 16px;
    }
  `,
  CloseButton: styled(CloseButton)`
    position: absolute;
    right: 15px;
    top: 15px;
    background-size: 25px 25px;
    background-image: url(${richCrossIcon});
  `,
}
