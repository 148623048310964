import React, { useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'
import { useProgressBarList } from 'hooks/useProgressBarList'

import { TPageProps } from 'models/common.model'

import { DesktopProvider } from 'components/DesktopProvider'

import { Cohort } from 'root-constants/common'

import { StyledPreparingFlow as S } from './PreparingFlow.styles'
import { PAGE_NAME } from './constants'

export const PreparingFlowVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const { title } = useDynamicOBConfig()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  const {
    firstCounterPercent,
    secondCounterPercent,
    thirdCounterPercent,
    fourthCounterPercent,
    isFourthProcessingFinished,
  } = useProgressBarList(true)

  const isFriendsCohort = useMemo(
    () => cohortToUse === Cohort.FRIENDS_1,
    [cohortToUse],
  )

  const barsList = useMemo(
    () => [
      {
        listTitle: isFriendsCohort
          ? 'onboarding.preparingFlow.firstProgressBar3'
          : 'onboarding.preparingFlow.firstProgressBar',
        color: '#199cf3',
        counter: firstCounterPercent,
      },
      {
        listTitle: 'onboarding.preparingFlow.secondProgressBar',
        color: '#7879f1',
        counter: secondCounterPercent,
      },
      {
        listTitle: isFriendsCohort
          ? 'onboarding.preparingFlow.thirdProgressBar3'
          : 'onboarding.preparingFlow.thirdProgressBar',
        color: '#f24281',
        counter: thirdCounterPercent,
      },
      {
        listTitle: 'onboarding.preparingFlow.fourthProgressBar',
        color: '#0fb600',
        counter: fourthCounterPercent,
      },
    ],
    [
      firstCounterPercent,
      secondCounterPercent,
      thirdCounterPercent,
      fourthCounterPercent,
      isFriendsCohort,
    ],
  )

  useEffect(() => {
    if (!isFourthProcessingFinished) return
    handleContinue('')
  }, [handleContinue, isFourthProcessingFinished])

  return (
    <DesktopProvider>
      <S.Wrapper>
        <S.Column>
          <S.Title>{title || t`onboarding.preparingFlow.title`}</S.Title>
          <S.ProgressBarsList>
            {barsList.map(({ color, counter, listTitle }) => (
              <S.ProgressContainer key={listTitle}>
                <S.ProgressHeader>
                  <S.ProgressTitle>
                    <Trans i18nKey={listTitle} />
                  </S.ProgressTitle>
                  <S.ProgressPercent>{counter}%</S.ProgressPercent>
                </S.ProgressHeader>
                <S.ProgressBar max={100} value={counter} color={color} />
              </S.ProgressContainer>
            ))}
          </S.ProgressBarsList>
        </S.Column>
      </S.Wrapper>
    </DesktopProvider>
  )
}
