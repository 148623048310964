import React, {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { resetErrorAction, setAnswersAction } from 'root-redux/actions/common'
import {
  GET_STATUS,
  SEND_USER_EMAIL,
  sendUserAnswersAction,
  sendUserEmailAction,
} from 'root-redux/actions/user'
import { selectActionList, selectError } from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

import { checkIsPhoneValid } from 'helpers/phone'

import { IInputFieldState } from 'models/inputField.model'

import { eventLogger } from 'services/eventLogger.service'

import { getFilteredEmailDomains } from 'modules/email/helpers'

import { Modal } from 'components/Modal'

import errorIcon from 'assets/images/error-icon.svg'
import gpsIcon from 'assets/images/gps-icon.svg'

import { CustomPageId } from 'root-constants/pages'

import { StyledEmailPhoneForm as S } from './EmailPhoneForm.styles'

type TProps = {
  buttonText: string
  pageId: CustomPageId
  phoneNumber: string
  setPhoneNumber: (phoneNumber: string) => void
  email: IInputFieldState
  validateEmail: (email: string) => void
}
export const EmailPhoneForm: React.FC<TProps> = ({
  buttonText,
  pageId,
  phoneNumber,
  setPhoneNumber,
  email,
  validateEmail,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const error = useSelector(selectError)
  const countryCode = useSelector(selectUserCountryCode)
  const fetchingActionsList = useSelector(selectActionList)
  const deferredEmail = useDeferredValue(email.value)
  const [isPhoneFocused, setIsPhoneFocused] = useState(false)
  const [areEmailTipsVisible, setAreEmailTipsVisible] = useState(false)
  const [isErrorModalShown, setIsErrorModalShown] = useState(false)
  const isPhoneValid = checkIsPhoneValid(phoneNumber)

  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const filteredEmailDomains = useMemo(
    () => getFilteredEmailDomains(deferredEmail),
    [deferredEmail],
  )

  const isStatusFetching = useMemo(
    () =>
      fetchingActionsList?.includes(SEND_USER_EMAIL) ||
      fetchingActionsList?.includes(GET_STATUS),
    [fetchingActionsList],
  )

  const isContinueDenied = useMemo(
    () => !email.value || !email.isValid || isStatusFetching,
    [email.isValid, email.value, isStatusFetching],
  )

  useEffect(() => {
    if (!isPhoneValid) return
    eventLogger.logEmailPageShown()
  }, [isPhoneValid])

  const handleChangeNumber = useCallback(
    (value: string) => {
      setPhoneNumber(value)
    },
    [setPhoneNumber],
  )

  const handleChangeEmail = useCallback(
    ({ target: { value } }) => {
      validateEmail(value)
      setAreEmailTipsVisible(true)
    },
    [validateEmail],
  )

  const handlePrefilledEmail = useCallback(
    ({ currentTarget: { value } }) => {
      validateEmail(value)
      setAreEmailTipsVisible(false)
    },
    [validateEmail],
  )

  const handlePhoneFocus = useCallback(() => {
    setIsPhoneFocused(true)
  }, [])

  const saveUserEmail = useCallback(() => {
    dispatch(
      sendUserEmailAction({
        email: email.value,
        isPersonalDataAllowed,
        unsuccessCallback: () => setIsErrorModalShown(true),
      }),
    )
  }, [dispatch, email.value, isPersonalDataAllowed])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      if (!isPhoneFocused && !isPhoneValid) {
        setIsPhoneFocused(true)
        return
      }
      if (isPhoneFocused && !isPhoneValid) {
        return
      }
      if (isContinueDenied) {
        return
      }

      dispatch(
        setAnswersAction({
          pageId,
          answers: phoneNumber,
        }),
      )
      dispatch(sendUserAnswersAction(false, saveUserEmail))
      eventLogger.logCheckNumberClicked()
    },
    [
      dispatch,
      isContinueDenied,
      isPhoneFocused,
      isPhoneValid,
      pageId,
      phoneNumber,
      saveUserEmail,
    ],
  )

  return (
    <>
      <S.PhoneForm onSubmit={handleSubmit}>
        <S.PhoneNumberInput
          defaultCountry={countryCode.toLowerCase()}
          value={phoneNumber}
          onChange={handleChangeNumber}
          onFocus={handlePhoneFocus}
        />
        {isPhoneFocused && !isPhoneValid && (
          <S.PhoneErrorText>
            {t`onboarding.introGoogle3.phoneError`}
          </S.PhoneErrorText>
        )}
      </S.PhoneForm>
      <S.EmailErrorWrapper isShown={isPhoneValid}>
        <img src={errorIcon} alt="error" />
        <S.Text>{t`subscriptions.subscriptionsGoogle.variant1.insertEmail`}</S.Text>
      </S.EmailErrorWrapper>
      <S.EmailForm isShown={isPhoneValid} onSubmit={handleSubmit}>
        <S.InputWrapper>
          <S.EmailInput
            value={email.value}
            isValid={email.isValid}
            validationText={email.validationText}
            placeholder={t`onboarding.email.placeholder`}
            autoComplete="off"
            onChange={handleChangeEmail}
          />
          {areEmailTipsVisible && (
            <S.AutoCompleteWrapper>
              {filteredEmailDomains.map((value) => (
                <S.AutoCompleteButton
                  type="button"
                  key={value}
                  value={value}
                  onMouseDown={handlePrefilledEmail}
                >
                  <S.AutoCompleteButtonTextWrapper>
                    {value}
                  </S.AutoCompleteButtonTextWrapper>
                </S.AutoCompleteButton>
              ))}
            </S.AutoCompleteWrapper>
          )}
        </S.InputWrapper>
      </S.EmailForm>
      <S.Button type="button" onClick={handleSubmit}>
        <img src={gpsIcon} alt="icon" />
        {t(buttonText)}
      </S.Button>
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
      >
        {error}
      </Modal>
    </>
  )
}
