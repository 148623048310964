import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useTheme } from 'styled-components'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useGetFlowInfo } from 'hooks/useGetFlowInfo'
import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { getIsMobileDevice } from 'helpers/getIsMobileDevice'

import { ProgressbarWithNumeration } from 'components/ProgressbarWithNumeration'

import { Cohort } from 'root-constants/common'

import { ProgressBar } from '../ProgressBar'
import { StyledHeader as S } from './Header.styles'

export const Header: React.FC = () => {
  const theme = useTheme()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const isMobile = getIsMobileDevice()
  const { hasHeader } = useGetPageInfo()
  const { hasDesktopVersion } = useGetFlowInfo()

  const isSafetyCohort = useMemo(
    () => cohortToUse === Cohort.SAFETY_6,
    [cohortToUse],
  )

  if (!hasHeader || (hasDesktopVersion && !isMobile)) return null

  return (
    <S.HeaderWrapper>
      <S.Header>
        <img
          height={theme.dimensions.headerLogoHeight}
          src={theme.images.headerLogo}
          alt="logo"
        />
      </S.Header>
      {isSafetyCohort ? <ProgressbarWithNumeration /> : <ProgressBar />}
    </S.HeaderWrapper>
  )
}
