import { PageId } from 'root-constants/pages'

export const APP_BENEFIT_DATA_2 = {
  [PageId.APP_BENEFIT_4]: {
    title: 'onboarding.appBenefit.title',
    subtitle: 'onboarding.appBenefit.subtitle',
    image: `/images/app-benefit-4`,
  },
  [PageId.APP_BENEFIT_5]: {
    title: 'onboarding.appBenefit.chatPrivately',
    subtitle: 'onboarding.appBenefit.communication',
    image: `/images/app-benefit-5`,
  },
  [PageId.APP_BENEFIT_6]: {
    title: 'onboarding.appBenefit.title3',
    subtitle: 'onboarding.appBenefit.subtitle3',
    image: `/images/app-benefit-6`,
  },
}
