import styled from 'styled-components'

import { Button } from 'components/Button'

import socialProof from 'assets/images/social-proof-12.webp'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { MediaBreakpoint } from 'root-constants/common'

export const StyledSocialProofVariant12 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100%;
    background: #0b0217;
  `,
  ImageContainer: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
    aspect-ratio: 375/364;
    min-height: 364px;
    background-image: url(${socialProof});
    background-size: 100% auto;
    background-repeat: no-repeat;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    position: fixed;
    bottom: 0;
    padding-bottom: 40px;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    font-size: 52px;
    font-weight: 800;
    line-height: 71px;
    margin-bottom: 7px;
    color: #dbf301;
  `,
  Note: styled.span`
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondaryText};
    margin-bottom: 29px;
  `,
  Button: styled(Button)`
    background-color: ${({ theme }) => theme.colors.activeBackground};
    color: ${({ theme }) => theme.colors.activeTextColor};
    line-height: 27px;
  `,
}
