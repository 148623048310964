import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  StripeCardCvcElement,
  StripeCardExpiryElement,
} from '@stripe/stripe-js'

import { resetErrorAction } from 'root-redux/actions/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { usePurchaseStore } from 'hooks/usePurchaseStore'

import {
  EMPTY_FIELD_ERROR,
  PaymentMethod,
  THREEDS_REDIRECT_SEARCH_PARAM,
} from 'modules/payment/constants'
import { getDefaultPaymentErrorsState } from 'modules/payment/helpers/getDefaultPaymentErrorsState'
import { getPaymentErrorState } from 'modules/payment/helpers/getPaymentErrorState'
import {
  check3DSecure,
  purchaseAction,
  setPaymentMethodAction,
} from 'modules/payment/redux/actions'
import { TCreditCardField, TPaymentErrorState } from 'modules/payment/types'

import { CardPaymentFieldName } from 'root-constants/common'

import {
  StyledStripePaymentFormVariant2 as S,
  stripeElementStyle,
} from './StripePaymentFormVariant2.styles'

type TProps = {
  onSendEvents: () => void
  inputBorderColor?: string
}

export const StripePaymentFormVariant2: React.FC<TProps> = ({
  onSendEvents,
  inputBorderColor = '#bcc9d0',
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { search } = useLocation()
  const stripe = useStripe()
  const elements = useElements()
  const cardExpiryElemRef = useRef<StripeCardExpiryElement | null>(null)
  const cvcElemRef = useRef<StripeCardCvcElement | null>(null)
  const cardholderNameElemRef = useRef<HTMLInputElement>(null)

  const [errors, setErrors] = useState<TPaymentErrorState>(() =>
    getDefaultPaymentErrorsState(),
  )
  const [name, setName] = useState('')

  const { isFetching, threeDSecureIframeUrl } = usePurchaseStore()
  const { currentPageId } = useGetPageInfo()
  const { buttonText } = useDynamicPaywallConfig()

  const hasErrors = useMemo(
    () => Object.values(errors).some((error) => error.isShown && error.error),
    [errors],
  )
  const hasUntouchedFields = useMemo(
    () =>
      Object.values(errors).some((error) => error.isShown && !error.isTouched),
    [errors],
  )
  const hasUncompletedFields = useMemo(
    () => Object.values(errors).some((field) => !field.isComplete),
    [errors],
  )
  const isFormValid = useMemo(
    () => !hasErrors && !hasUntouchedFields && !hasUncompletedFields,
    [hasErrors, hasUncompletedFields, hasUntouchedFields],
  )

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const isSuccess = URLParams.has(THREEDS_REDIRECT_SEARCH_PARAM)

    if (!isSuccess || !threeDSecureIframeUrl || !stripe) return

    dispatch(check3DSecure(stripe))
  }, [dispatch, search, stripe, threeDSecureIframeUrl])

  const handleChange = useCallback(
    ({
      fieldName,
      isEmpty,
      hasError,
      isComplete,
      nextElemRef,
    }: {
      fieldName: CardPaymentFieldName
      isEmpty: boolean
      hasError: boolean
      isComplete: boolean
      nextElemRef?: RefObject<TCreditCardField>
    }) => {
      dispatch(resetErrorAction())

      let error = ''

      if (hasError) {
        error = 'is invalid'
      }

      if (isEmpty) {
        error = EMPTY_FIELD_ERROR
      }

      if (nextElemRef && isComplete) {
        nextElemRef.current?.focus()
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: { isTouched: true, error, isComplete },
      }))
    },
    [dispatch],
  )

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      dispatch(resetErrorAction())

      if (hasUntouchedFields) {
        setErrors(getPaymentErrorState(errors))
        return
      }

      if (hasErrors) return

      const card = elements?.getElement(CardNumberElement)

      if (!stripe || !card || isFetching) return

      cardholderNameElemRef.current?.blur()
      onSendEvents()
      dispatch(setPaymentMethodAction(PaymentMethod.CREDIT_CARD))
      dispatch(
        purchaseAction({
          stripe,
          card,
          name,
          paymentPageId: currentPageId,
        }),
      )
    },
    [
      dispatch,
      hasUntouchedFields,
      hasErrors,
      elements,
      stripe,
      isFetching,
      onSendEvents,
      name,
      currentPageId,
      errors,
    ],
  )

  return (
    <S.Form onSubmit={handleSubmit}>
      <S.Label gridArea="cardNumberLabel">{t`payment.stripe.card`}</S.Label>
      <S.CardNumberElement
        options={{
          showIcon: false,
          placeholder: '1234 1234 1234 1234',
          style: stripeElementStyle,
        }}
        onChange={({ empty, error, complete }) => {
          handleChange({
            fieldName: CardPaymentFieldName.NUMBER,
            isEmpty: empty,
            hasError: !!error,
            isComplete: complete,
            nextElemRef: cardExpiryElemRef,
          })
        }}
        borderColor={inputBorderColor}
      />

      <S.Label gridArea="cardExpiryLabel">{t`payment.stripe.date`}</S.Label>
      <S.CardExpiryElement
        onReady={(elem) => {
          cardExpiryElemRef.current = elem
        }}
        options={{
          placeholder: t`payment.stripe.datePlaceholder`,
          style: stripeElementStyle,
        }}
        onChange={({ empty, error, complete }) => {
          handleChange({
            fieldName: CardPaymentFieldName.EXPIRY,
            isEmpty: empty,
            hasError: !!error,
            isComplete: complete,
            nextElemRef: cvcElemRef,
          })
        }}
        borderColor={inputBorderColor}
      />

      <S.Label gridArea="cardCvcLabel">{t`payment.stripe.cvc`}</S.Label>
      <S.CardCvcElement
        onReady={(elem) => {
          cvcElemRef.current = elem
        }}
        options={{
          placeholder: '111',
          style: stripeElementStyle,
        }}
        onChange={({ empty, error, complete }) => {
          handleChange({
            fieldName: CardPaymentFieldName.CVC,
            isEmpty: empty,
            hasError: !!error,
            isComplete: complete,
            nextElemRef: cardholderNameElemRef,
          })
        }}
        borderColor={inputBorderColor}
      />

      <S.Label gridArea="cardholderLabel">{t`payment.stripe.name`}</S.Label>
      <S.CardholderInput
        ref={cardholderNameElemRef}
        type="text"
        placeholder={t`payment.stripe.placeholder`}
        data-invalid={!!errors[CardPaymentFieldName.NAME].error}
        borderColor={inputBorderColor}
        onChange={(e) => {
          const value = e.target.value.trim()

          setName(value)

          handleChange({
            fieldName: CardPaymentFieldName.NAME,
            isEmpty: false,
            hasError: false,
            isComplete: true,
          })
        }}
      />
      <S.SubmitButton
        type="submit"
        disabled={!stripe || !isFormValid || isFetching}
      >
        {buttonText || t`actions.continue`}
      </S.SubmitButton>
    </S.Form>
  )
}
