import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { LiveChatWidget, useWidgetCustomerData } from '@livechat/widget-react'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { setLiveChatPropsAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantCohortToUse,
  selectLanguage,
  selectLivechatEndTime,
  selectLivechatStartTime,
} from 'root-redux/selects/common'
import {
  selectEmail,
  selectGeneralSubscriptionDuration,
  selectGeneralSubscriptionPrice,
  selectGeneralSubscriptionPriceId,
  selectGeneralSubscriptionTrialPeriod,
  selectGeneralSubscriptionTrialPrice,
  selectIsDiscountApplied,
  selectIsDownloadVisited,
  selectIsPrimerPayment,
  selectIsUpgradePassed,
  selectUUID,
  selectUserPaymentCurrency,
  selectUserPaymentMethod,
  selectUserStatus,
} from 'root-redux/selects/user'

import { getOperatingSystemVersion } from 'helpers/getOperatingSystemVersion'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { STRIPE_PAYMENT_METHODS } from 'modules/payment/constants'

import { Currency } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import {
  LIVECHAT_COHORTS,
  LIVE_CHAT_PUBLIC_KEY,
  LiveChatStatus,
} from './constants'

dayjs.extend(utc)
dayjs.extend(tz)

export const LiveChat: React.FC = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const language = useSelector(selectLanguage)
  const uuid = useSelector(selectUUID)
  const livechatStartTime = useSelector(selectLivechatStartTime)
  const livechatEndTime = useSelector(selectLivechatEndTime)
  const email = useSelector(selectEmail)
  const paymentCurrency = useSelector(selectUserPaymentCurrency)
  const paymentMethod = useSelector(selectUserPaymentMethod)
  const isDownloadVisited = useSelector(selectIsDownloadVisited)
  const price = useSelector(selectGeneralSubscriptionPrice)
  const priceId = useSelector(selectGeneralSubscriptionPriceId)
  const subscriptionDuration = useSelector(selectGeneralSubscriptionDuration)
  const trialPeriodDays = useSelector(selectGeneralSubscriptionTrialPeriod)
  const trialPrice = useSelector(selectGeneralSubscriptionTrialPrice)
  const isDiscountApplied = useSelector(selectIsDiscountApplied)
  const isPrimerPayment = useSelector(selectIsPrimerPayment)
  const isUpgradePassed = useSelector(selectIsUpgradePassed)
  const userStatus = useSelector(selectUserStatus)

  const currentTime = Number(dayjs().utc().format('H'))

  const customerData = useWidgetCustomerData()

  const isWorkingHours = useMemo(() => {
    if (livechatStartTime > livechatEndTime) {
      return livechatStartTime <= currentTime || currentTime < livechatEndTime
    }

    return livechatStartTime <= currentTime && currentTime < livechatEndTime
  }, [currentTime, livechatEndTime, livechatStartTime])

  const isUSDCurrency = useMemo(
    () => paymentCurrency === Currency.USD,
    [paymentCurrency],
  )

  const isStripePayment = useMemo(
    () => STRIPE_PAYMENT_METHODS.includes(paymentMethod),
    [paymentMethod],
  )

  const isDownloadPage = useMemo(
    () => pathname.includes(PageId.DOWNLOAD),
    [pathname],
  )

  const isAvailableCohort = useMemo(
    () => LIVECHAT_COHORTS[language]?.includes(cohortToUse),
    [cohortToUse, language],
  )

  const hasLivechat = useMemo(
    () =>
      isWorkingHours &&
      isUSDCurrency &&
      isStripePayment &&
      !isDownloadVisited &&
      !isDiscountApplied &&
      isAvailableCohort &&
      !isPrimerPayment &&
      !isUpgradePassed &&
      userStatus?.hasSubscription,
    [
      isWorkingHours,
      isUSDCurrency,
      isStripePayment,
      isDownloadVisited,
      isDiscountApplied,
      isAvailableCohort,
      isPrimerPayment,
      isUpgradePassed,
      userStatus?.hasSubscription,
    ],
  )

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>
    if (hasLivechat && isDownloadPage) {
      timerId = setTimeout(() => {
        document.documentElement.style.setProperty(
          '--livechat-visibility',
          'block',
        )

        eventLogger.logLiveChatOpened()
        googleAnalyticsLogger.logLiveChatOpened()
      }, 1000)
    }

    return () => {
      clearTimeout(timerId)
    }
  }, [hasLivechat, isDownloadPage])

  useEffect(() => {
    if (!customerData) return
    dispatch(setLiveChatPropsAction(customerData))
  }, [customerData, dispatch])

  const handleHideGreeting = useCallback(() => {
    eventLogger.logLiveChatClosed()
    googleAnalyticsLogger.logLiveChatClosed()
  }, [])

  const handleChatVisibilityChanged = useCallback(({ visibility }) => {
    if (visibility === LiveChatStatus.MINIMIZED) {
      eventLogger.logLiveChatClosed()
      googleAnalyticsLogger.logLiveChatClosed()
    }

    if (visibility === LiveChatStatus.MAXIMIZED) {
      eventLogger.logLiveChatOpened()
      googleAnalyticsLogger.logLiveChatOpened()
    }
  }, [])

  return !hasLivechat ? null : (
    <LiveChatWidget
      license={LIVE_CHAT_PUBLIC_KEY}
      customerEmail={uuid}
      visibility="maximized"
      customerName="No username"
      sessionVariables={{
        email,
        cohort,
        username: 'No username',
        subscription_price: price.toString(),
        subscription_duration: subscriptionDuration,
        price_id: priceId,
        trial_period: trialPeriodDays.toString(),
        trial_price: trialPrice.toString(),
        operating_system: getOperatingSystemVersion(),
      }}
      onGreetingHidden={handleHideGreeting}
      onVisibilityChanged={handleChatVisibilityChanged}
    />
  )
}
