import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { setMultipleAnswerAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { checkIsPhoneValid } from 'helpers/phone'

import { eventLogger } from 'services/eventLogger.service'

import gpsIcon from 'assets/images/gps-icon.svg'

import { goTo } from 'browser-history'
import { PageId } from 'root-constants/pages'

import { StyledPhoneForm as S } from './PhoneForm.styles'

type TProps = {
  buttonText: string
  pageId: PageId
  phoneNumber: string
  setPhoneNumber: (phoneNumber: string) => void
  className?: string
  pathname?: string
}
export const PhoneForm: React.FC<TProps> = ({
  buttonText,
  pageId,
  phoneNumber,
  setPhoneNumber,
  className,
  pathname = PageId.LOCATION_SEARCH_1,
}) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const countryCode = useSelector(selectUserCountryCode)
  const [isPhoneFocused, setIsPhoneFocused] = useState(false)
  const [userPhoneCountry, setUserPhoneCountry] = useState('')
  const isValid = checkIsPhoneValid(phoneNumber)

  const handleChangeNumber = useCallback(
    (value: string, { country }) => {
      setPhoneNumber(value)
      setUserPhoneCountry(country.name)
    },
    [setPhoneNumber],
  )

  const handlePhoneFocus = useCallback(() => {
    setIsPhoneFocused(true)
  }, [])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      if (!isPhoneFocused && !isValid) {
        setIsPhoneFocused(true)
        return
      }
      if (isPhoneFocused && !isValid) {
        return
      }

      dispatch(
        setMultipleAnswerAction({
          answers: {
            [pageId]: phoneNumber,
            userPhoneCountry,
          },
        }),
      )
      dispatch(sendUserAnswersAction())
      eventLogger.logCheckNumberClicked()
      goTo({ pathname, search })
    },
    [
      isPhoneFocused,
      isValid,
      dispatch,
      pageId,
      phoneNumber,
      userPhoneCountry,
      search,
      pathname,
    ],
  )

  return (
    <S.PhoneForm onSubmit={handleSubmit} className={className}>
      <S.PhoneNumberInput
        defaultCountry={countryCode.toLowerCase()}
        value={phoneNumber}
        onChange={handleChangeNumber}
        onFocus={handlePhoneFocus}
      />
      {isPhoneFocused && !isValid && (
        <S.PhoneErrorText>
          {t`onboarding.introGoogle3.phoneError`}
        </S.PhoneErrorText>
      )}
      <S.Button type="submit">
        <img src={gpsIcon} alt="icon" />
        {t(buttonText)}
      </S.Button>
    </S.PhoneForm>
  )
}
