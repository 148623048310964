import styled, { css } from 'styled-components'

import { StyledButtonBase } from 'components/Button/Button.styles'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import {
  BASE_COLUMN_WIDTH,
  HEADER_HEIGHT,
  MediaBreakpoint,
} from 'root-constants/common'

const StyledAccountButtonBase = css`
  ${StyledButtonBase};
  color: ${({ theme }) => theme.colors.accountButtonsColors};
  padding: 0 32px;
  height: 56px;
  line-height: 56px;
  font-size: ${({ theme }) => theme.common.accountButtonFontSize};
  background-image: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  :not(:last-child) {
    margin-bottom: 16px;
  }

  svg {
    position: absolute;
    left: 32px;
    fill: currentColor;
  }

  @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
    font-size: 18px;
    height: 76px;
    line-height: 76px;

    :not(:last-child) {
      margin-bottom: 24px;
    }

    svg {
      left: 100px;
    }
  }
`

export const StyledAccount = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 460px;
    }
  `,
  Title: styled.h1`
    margin-bottom: 12px;
    font-size: 26px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: ${({ theme }) => theme.colors.accountTitleColor};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-weight: 800;
      font-size: 48px;
      line-height: 65px;
      margin-bottom: 24px;
    }
  `,
  Text: styled.p`
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondaryText};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 87px;
    }
  `,
  Buttons: styled.div`
    width: ${BASE_COLUMN_WIDTH}px;

    &[data-is-es-lang='true'] {
      svg {
        left: 18px;
      }
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 460px;

      &[data-is-es-lang='true'] {
        svg {
          left: 60px;
        }
      }
    }
  `,
}

export const StyledAccountButton = {
  Email: styled.button`
    ${StyledAccountButtonBase};
    background-color: #7bc27e;
  `,
  Apple: styled.button`
    ${StyledAccountButtonBase};
    background-color: ${({ theme }) => theme.colors.appleLoginButtonBackground};
  `,
}
