import React from 'react'

import { TPageProps } from 'models/common.model'

import { RadioOptionPage } from 'pages/options-page/RadioOptionPage'

import { PageId } from 'root-constants/pages'

import { OPTION_DATA, TITLE_PATH } from './constants'

export const ShareLocationVariant1: React.FC<TPageProps> = (props) => (
  <RadioOptionPage
    {...props}
    pageName={PageId.SHARE_LOCATION_1}
    options={OPTION_DATA}
    titlePath={TITLE_PATH}
    wrapperPaddingTop={100}
    columnPaddingTop={24}
    marginBottom={40}
  />
)
