import styled, { css } from 'styled-components'

import { BASE_COLUMN_WIDTH, Color } from 'root-constants/common'

const progressValueStyles = css`
  background-color: ${Color.ACCENT_VIOLET};
  border-radius: 18px;
  transition: all 0.2s ease-out;
`

export const StyledProgressbarWithNumeration = {
  Wrapper: styled.div`
    width: ${BASE_COLUMN_WIDTH}px;
    position: absolute;
    top: 72px;
    left: 50%;
    transform: translateX(-50%);
  `,
  TextContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  `,
  Text: styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;

    strong {
      font-weight: 800;
      color: ${Color.ACCENT_VIOLET};
    }
  `,
  Progress: styled.progress`
    width: 100%;
    height: 4px;
    appearance: none;
    border: none;
    display: block;
    border-radius: 18px;
    overflow: hidden;
    background-color: ${Color.GREY};

    &::-webkit-progress-bar {
      background-color: ${Color.GREY};
    }
    &::-webkit-progress-value {
      ${progressValueStyles}
    }
    &::-moz-progress-bar {
      ${progressValueStyles}
    }
    &::-ms-fill {
      ${progressValueStyles}
    }
  `,
}
