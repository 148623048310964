import { Color } from 'root-constants/common'

export const EMPTY_FIELD_ERROR = `can't be blank`

export const DEFAULT_CARDHOLDER_NAME = ' '

export const enum StripeErrorCode {
  CARD_DECLINED = 'card_declined',
}

export const CARDHOLDER_NAME_MIN_LENGTH = 1

export const enum PrimerErrorCode {
  CARD_REQUIRED = 'cardRequired',
  CARD_IS_INCOMPLETE = 'cardIncomplete',
  CARD_IS_INVALID = 'cardInvalid',
  EXPIRY_REQUIRED = 'expiryRequired',
  CARD_IS_EXPIRED = 'cardExpired',
  CARD_EXPIRY_YEAR_INVALID = 'expiryYearInvalid',
  CARD_EXPIRY_MONTH_INVALID = 'expiryMonthInvalid',
  CVV_IS_INCOMPLETE = 'cvvIncomplete',
}

export const PRIMER_PAYMENT_ERRORS = {
  INSUFFICIENT_FUNDS: 'payment.insufficientFunds',
  PAYMENT_FAILED: 'payment.insufficientFunds',
}

export const enum PaymentMethod {
  CREDIT_CARD = 'credit_card',
  APPLE_PAY = 'apple_pay',
  GOOGLE_PAY = 'google_pay',
  PAYPAL = 'paypal',
}

export const enum PaymentSystem {
  STRIPE = 'stripe',
  PAYPAL = 'paypal',
  PRIMER = 'primer',
}

export const STRIPE_PAYMENT_METHODS = [
  PaymentMethod.CREDIT_CARD,
  PaymentMethod.APPLE_PAY,
  PaymentMethod.GOOGLE_PAY,
]

export const THREEDS_REDIRECT_SEARCH_PARAM = 'threeds'

export const enum StripeSoftDeclineReason {
  AUTHENTICATION_FAILURE = 'payment_intent_authentication_failure',
  DO_NOT_HONOR = 'do_not_honor',
  INSUFFICIENT_FUNDS = 'insufficient_funds',
  INVALID_AMOUNT = 'invalid_amount',
  PROCESSING_ERROR = 'processing_error',
  REENTER_TRANSACTION = 'reenter_transaction',
  SERVICE_NOT_ALLOWED = 'service_not_allowed',
  TRANSACTION_NOT_ALLOWED = 'transaction_not_allowed',
  CARD_VELOCITY_EXCEEDED = 'card_velocity_exceeded',
}

export const enum StripeDeclineReason {
  STOLEN_CARD = 'stolen_card',
  LOST_CARD = 'lost_card',
  STRIPE_GENERIC_DECLINE_ERROR = 'generic_decline',
}

export const STRIPE_SOFT_DECLINE_REASONS = [
  StripeSoftDeclineReason.AUTHENTICATION_FAILURE,
  StripeSoftDeclineReason.DO_NOT_HONOR,
  StripeSoftDeclineReason.INSUFFICIENT_FUNDS,
  StripeSoftDeclineReason.INVALID_AMOUNT,
  StripeSoftDeclineReason.PROCESSING_ERROR,
  StripeSoftDeclineReason.REENTER_TRANSACTION,
  StripeSoftDeclineReason.SERVICE_NOT_ALLOWED,
  StripeSoftDeclineReason.TRANSACTION_NOT_ALLOWED,
  StripeSoftDeclineReason.CARD_VELOCITY_EXCEEDED,
]

export const PRIMER_CARD_NUMBER_ID = 'checkout-card-number-input'
export const PRIMER_CARD_EXPIRY_ID = 'checkout-card-expiry-input'
export const PRIMER_CARD_CVC_ID = 'checkout-card-cvv-input'
export const PRIMER_GOOGLE_PAY_ID = 'google-pay-button'
export const PRIMER_APPLE_PAY_ID = 'apple-pay-button'

export const PRIMER_FORM_COMMON_STYLES = {
  input: {
    base: {
      paddingLeft: '16px',
      borderRadius: '12px',
      color: Color.DEFAULT_TEXT,
      fontFamily: 'Nunito, sans-serif',
      fontSize: '16px',
      fontWeight: '700',
      letterSpacing: '1px',
      placeholder: {
        fontWeight: '400',
        color: '#bcc9d0',
      },
    },
    error: {
      color: Color.ERROR,
      caretColor: Color.DEFAULT_TEXT,
    },
  },
}

export const INITIAL_PRIMER_CONFIG = {
  card: {
    cardholderName: {
      required: false,
    },
  },
  style: {
    stylesheets: [
      {
        href: 'https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap',
      },
    ],
  },
}

export const enum ChipoloColor {
  BLUE = 'blue',
  YELLOW = 'yellow',
  RED = 'red',
  WHITE = 'white',
  GREEN = 'green',
  BLACK = 'black',
}
