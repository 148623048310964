import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledSocialProofVariant16 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  ImageContainer: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    aspect-ratio: 375/300;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-bottom: 32px;
  `,
  Percents: styled.p`
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;

    strong {
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
      color: ${Color.ACCENT_VIOLET};
    }
  `,
  Text: styled.p`
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 24px;
  `,
}
