import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import socialProof from 'assets/images/social-proof-17.webp'

import { StyledSocialProofVariant17 as S } from './SocialProofVariant17.styles'

export const SocialProofVariant17: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.ImageContainer>
        <img src={socialProof} alt="couple" />
      </S.ImageContainer>
      <S.Column>
        <S.Text>{t`onboarding.socialProof.variant17.title`}</S.Text>
        <S.Note>{t`onboarding.socialProof.variant17.note`}</S.Note>
        <Button type="button" onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </Button>
      </S.Column>
    </S.Wrapper>
  )
}
