import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'
import { selectGeneralSubscriptionTrialPeriod } from 'root-redux/selects/user'

import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodQuantity,
} from 'modules/payment/redux/selects'
import { DISCOUNT_UPSELL } from 'modules/subscriptions/constants'

import { SvgImage } from 'components/SvgImage'

import checkIcon from 'assets/images/sprite/check-circle-blue.svg'

import { CURRENCY_SYMBOLS, Language } from 'root-constants/common'

import { StyledPlanBlockVariant2 as S } from './PlanBlockVariant2.styles'

export const PlanBlockVariant2: React.FC = () => {
  const { t } = useTranslation()
  const trialPeriodDays = useSelector(selectGeneralSubscriptionTrialPeriod)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const currency = useSelector(selectCurrency)
  const language = useSelector(selectLanguage)
  const amountToPay = useSelector(selectSubscriptionFullPrice)

  const oldPrice = useMemo(() => amountToPay * 2, [amountToPay])

  return (
    <S.Wrapper>
      <S.Title>{t('subscriptions.purchaseUpgrade.plan.title')}</S.Title>
      <S.PriceContainer>
        <S.PriceText gridArea="priceTodayText">{t`subscriptions.purchaseUpgrade.plan.priceToday`}</S.PriceText>
        <S.PricePeriod gridArea="oldPeriod" marginBottom={24}>
          {t('subscriptions.purchaseUpgrade.plan.trialDaysPeriod', {
            trialPeriodDays,
          })}
        </S.PricePeriod>
        <S.PurchaseLabel>
          <SvgImage svg={checkIcon} width={12} />
          <S.PurchaseLabelText>{t`subscriptions.purchaseUpgrade.plan.alreadyPurchased`}</S.PurchaseLabelText>
        </S.PurchaseLabel>
        <S.Divider gridArea="firstDivider" />

        <S.PriceText gridArea="priceAfterText">{t`subscriptions.purchaseUpgrade.plan.priceAfter`}</S.PriceText>
        <S.PricePeriod gridArea="newPeriod" marginBottom={8}>
          {t('subscriptions.purchaseUpgrade.plan.newPeriod', {
            periodQuantity,
          })}
        </S.PricePeriod>
        <S.Price>
          {t('subscriptions.planBlock.price', {
            price: amountToPay,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          })}
        </S.Price>
        <S.Discount maxWidth={language === Language.EN ? 70 : 90}>
          {t('subscriptions.purchaseUpgrade.plan.discount', {
            discount: DISCOUNT_UPSELL,
          })}
        </S.Discount>
        <S.OldPrice>
          {t('subscriptions.planBlock.price', {
            price: oldPrice,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          })}
        </S.OldPrice>
        <S.Divider gridArea="secondDivider" />
        <S.Disclaimer>
          {t('subscriptions.purchaseUpgrade.plan.disclaimer', {
            trialPeriodDays,
          })}
        </S.Disclaimer>
      </S.PriceContainer>
    </S.Wrapper>
  )
}
