import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { GrowthBook } from '@growthbook/growthbook-react'

import { selectGrowthBookExperiments } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { getCurrentEnv } from 'helpers/getCurrentEnv'

import { eventLogger } from 'services/eventLogger.service'

import { CurrentEnvironment } from 'root-constants/common'

const PROD_GROWTHBOOK_CLIENT_KEY = 'sdk-sThd75Q9PkVN8uxs'
const DEV_GROWTHBOOK_CLIENT_KEY = 'sdk-XnCG16FsT37eq2Yl'

const clientKey =
  getCurrentEnv() === CurrentEnvironment.PROD
    ? PROD_GROWTHBOOK_CLIENT_KEY
    : DEV_GROWTHBOOK_CLIENT_KEY

export const growthbook = new GrowthBook({
  enableDevMode: true,
  apiHost: 'https://cdn.growthbook.io',
  subscribeToChanges: true,
  clientKey,
})

export const useGrowthBook = (): void => {
  const uuid = useSelector(selectUUID)
  const experimentsMap = useSelector(selectGrowthBookExperiments)

  useEffect(() => {
    growthbook.loadFeatures()
  }, [])

  useEffect(() => {
    growthbook.setAttributes({
      id: uuid,
    })
  }, [uuid])

  useEffect(() => {
    growthbook.subscribe((experiment, result) => {
      if (!experimentsMap || !result.hashUsed) return

      const experimentKey = experiment.key || ''
      const variantId = String(result.variationId)

      eventLogger.logGrowthbookAbSegmentName({
        variantId,
        experimentKey,
        variantName: experimentsMap[experimentKey]?.[variantId],
      })
    })
  }, [experimentsMap])
}
