import React from 'react'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { FoundUser } from 'components/FoundUser'
import { Map } from 'components/Map'
import { SearchUser } from 'components/SearchUser'

import { StyledLocationVariant2 as S } from './LocationSearchVariant2.styles'

export const LocationSearchVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { counter, isFinished } = useCounter(true, {
    duration: 8000,
  })

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <>
      <Map />
      <S.BackgroundWrapper isFinished={isFinished} />
      <SearchUser isFinished={isFinished} counter={counter} />
      <FoundUser isFinished={isFinished} handleContinue={handleContinue} />
    </>
  )
}
