import React from 'react'
import { useSelector } from 'react-redux'

import { EffectCreative, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { selectLanguage } from 'root-redux/selects/common'

import { CDN_FOLDER_LINK, Images, Language } from 'root-constants/common'

import { StyledSwiperSlideVariant1 as S } from './SwiperSlideVariant1.styles'

export const SwiperSlideVariant1: React.FC = () => {
  const language = useSelector(selectLanguage)
  return (
    <S.SwiperContainer>
      <Carousel
        loop
        centeredSlides
        slidesPerView="auto"
        modules={[Pagination, EffectCreative]}
        pagination={{ clickable: true }}
        spaceBetween={12}
        effect="creative"
        creativeEffect={{
          prev: {
            scale: 0.86,
            translate: ['-100%', 0, 0],
          },
          next: {
            scale: 0.86,
            translate: ['100%', 0, 0],
          },
        }}
      >
        <SwiperSlide>
          <img
            src={
              language === Language.DE
                ? `${CDN_FOLDER_LINK}${Images.GOOGLE_PHONE_2}_${Language.EN}.png`
                : `${CDN_FOLDER_LINK}${Images.GOOGLE_PHONE_2}_${language}.png`
            }
            alt="phone"
          />
        </SwiperSlide>

        <SwiperSlide>
          <img
            src={
              language === Language.DE
                ? `${CDN_FOLDER_LINK}${Images.GOOGLE_PHONE_3}_${Language.EN}.png`
                : `${CDN_FOLDER_LINK}${Images.GOOGLE_PHONE_3}_${language}.png`
            }
            alt="phone"
          />
        </SwiperSlide>

        <SwiperSlide>
          <img
            src={
              language === Language.DE
                ? `${CDN_FOLDER_LINK}${Images.GOOGLE_PHONE_1}_${Language.EN}.png`
                : `${CDN_FOLDER_LINK}${Images.GOOGLE_PHONE_1}_${language}.png`
            }
            alt="phone"
          />
        </SwiperSlide>
      </Carousel>
    </S.SwiperContainer>
  )
}
