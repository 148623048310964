import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { selectCurrentVariantSteps } from 'root-redux/selects/common'

import { POST_PAYMENT_PAGES } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

export const useGetPageInfo = () => {
  const { pathname } = useLocation()
  const steps = useSelector(selectCurrentVariantSteps)

  const currentPageId = useMemo(() => pathname.slice(1), [pathname])

  const currentPageIndex = useMemo(
    () => steps.findIndex(({ id }) => id === currentPageId),
    [currentPageId, steps],
  )

  const isSecondPage = useMemo(
    () => steps.findIndex(({ id }) => id === currentPageId) === 1,
    [currentPageId, steps],
  )

  const isDownloadPage = useMemo(
    () => currentPageId === PageId.DOWNLOAD,
    [currentPageId],
  )

  const isPostPaymentPage = useMemo(
    () => POST_PAYMENT_PAGES.includes(currentPageId as PageId),
    [currentPageId],
  )

  const { hasHeader, hasProgressbar } = useMemo(
    () => ({
      hasHeader: steps[currentPageIndex]?.hasHeader || isPostPaymentPage,
      hasProgressbar: steps[currentPageIndex]?.hasProgressBar,
    }),
    [currentPageIndex, isPostPaymentPage, steps],
  )

  const currentSubscriptionPageId = useMemo(
    () => steps.find((page) => page.isSubscriptions)?.id as PageId,
    [steps],
  )

  const currentPaymentPageId = useMemo(
    () => steps.find((page) => page.isPayment)?.id || '',
    [steps],
  )

  const isPaymentPage = useMemo(
    () => currentPaymentPageId === currentPageId,
    [currentPageId, currentPaymentPageId],
  )

  return {
    steps,
    currentPageId,
    currentPageIndex,
    isSecondPage,
    isDownloadPage,
    isPostPaymentPage,
    hasHeader,
    hasProgressbar,
    currentSubscriptionPageId,
    currentPaymentPageId,
    isPaymentPage,
  }
}
