import React, { ReactNode, useCallback, useMemo } from 'react'

import { t } from 'i18next'

import { getEnvironment } from 'helpers/getEnvironment'

import { eventLogger } from 'services/eventLogger.service'

import { CONTACT_FORM_LINKS, CurrentEnvironment } from 'root-constants/common'

type TProps = {
  children?: ReactNode
  className?: string
}

export const SupportLink: React.FC<TProps> = ({
  children = t('actions.contactSupport'),
  className,
}) => {
  const currentEnvironment = getEnvironment()

  const handleClick = useCallback(() => {
    eventLogger.logNeedHelpClicked()
  }, [])

  const link = useMemo(() => {
    const { isProdEnvironment, isDevEnvironment, isStageEnvironment } =
      currentEnvironment

    switch (true) {
      case isProdEnvironment:
        return CONTACT_FORM_LINKS[CurrentEnvironment.PROD]

      case isDevEnvironment:
        return CONTACT_FORM_LINKS[CurrentEnvironment.DEV]

      case isStageEnvironment:
        return CONTACT_FORM_LINKS[CurrentEnvironment.STAGE]

      default:
        return CONTACT_FORM_LINKS[CurrentEnvironment.DEV]
    }
  }, [currentEnvironment])

  return (
    <a
      href={link}
      className={className}
      target="_blank"
      rel="noreferrer"
      onClick={handleClick}
    >
      {children}
    </a>
  )
}
