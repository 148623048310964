import styled from 'styled-components'

import { Button } from 'components/Button'

type TProps = {
  isFinished: boolean
}
type TTextProps = {
  isBlurred?: boolean
}
export const StyledFoundUser = {
  PhoneNumberWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Title: styled.p`
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors.defaultTextColor};
  `,
  PhoneNumber: styled.p`
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;
    color: ${({ theme }) => theme.colors.defaultTextColor};
  `,
  Wrapper: styled.div<TProps>`
    max-width: 327px;
    width: 100%;
    display: ${({ isFinished }) => (isFinished ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 24px;
    border-radius: 24px;
    background: ${({ theme }) => theme.colors.locationPopupBackground};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  `,
  LocationIcon: styled.img`
    width: 56px;
    height: 56px;
  `,
  LocationDataWrapper: styled.ul`
    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style: none;
    width: 100%;
    color: ${({ theme }) => theme.colors.defaultTextColor};

    li > span:last-of-type {
      order: 2;
    }

    img {
      order: 2;
      margin-right: 4px;
    }
  `,
  LocationData: styled.li`
    display: flex;
    justify-content: space-between;
    color: ${({ theme }) => theme.colors.defaultTextColor};

    ::before {
      content: '';
      border-bottom: 2px dotted rgb(220, 220, 220);
      order: 1;
      flex: 1 1 0;
      margin: 0 4px 8px;
    }
  `,
  Text: styled.span<TTextProps>`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    filter: ${({ isBlurred }) => (isBlurred ? 'blur(4px)' : 'none')};
    color: ${({ theme }) => theme.colors.defaultTextColor};
  `,
  Defined: styled.span`
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.activeBackground};
  `,
  Button: styled(Button)`
    max-width: 279px;
    width: 100%;
  `,
}
