import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import { GET_STATUS, sendUserConfigAction } from 'root-redux/actions/user'
import {
  selectActionList,
  selectCurrentVariantCohort,
  selectLanguage,
} from 'root-redux/selects/common'
import {
  selectGeneralSubscriptionTrialPeriod,
  selectUUID,
} from 'root-redux/selects/user'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { purchaseUpgrade } from 'modules/payment/redux/actions'
import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionLookupKey,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectUpgradeAmountToPay,
} from 'modules/payment/redux/selects'
import { PlanBlockVariant1 } from 'modules/subscriptions/components/upgrade/PlanBlockVariant1'
import { PlanBlockVariant2 } from 'modules/subscriptions/components/upgrade/PlanBlockVariant2'
import { PERIOD_QUANTITY_PURCHASE_UPGRADE } from 'modules/subscriptions/constants'
import { BENEFITS } from 'modules/subscriptions/pages/PurchaseUpgrade/constants'

import { DesktopProvider } from 'components/DesktopProvider'
import { Guarantee } from 'components/Guarantee'
import { Security } from 'components/Security'
import { Spinner } from 'components/Spinner'
import { StripePaymentProcessing } from 'components/StripePaymentProcessing'
import { SupportLink } from 'components/SupportLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import stars from 'assets/images/stars.svg'

import {
  BillingCycle,
  CDN_FOLDER_LINK,
  CURRENCY_SYMBOLS,
  Language,
  PagePath,
  SubscriptionListType,
  SubscriptionTag,
  TrialPeriod,
} from 'root-constants/common'

import { StyledPurchaseUpgrade as S } from './PurchaseUpgrade.styles'

export const PurchaseUpgradeVariant1: React.FC<TPageProps> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort)
  const uuid = useSelector(selectUUID)

  const priceId = useSelector(selectSubscriptionLookupKey)
  const price = useSelector(selectSubscriptionFullPrice)
  const currency = useSelector(selectCurrency)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const amountToPay = useSelector(selectUpgradeAmountToPay)
  const trialPeriodDays = useSelector(selectGeneralSubscriptionTrialPeriod)
  const fetchingActionsList = useSelector(selectActionList)
  const language = useSelector(selectLanguage)

  const hasPrices = useMemo(
    () =>
      !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST) &&
      !fetchingActionsList?.includes(GET_STATUS),
    [fetchingActionsList],
  )

  const hasSevenDayTrial = useMemo(
    () => trialPeriodDays === TrialPeriod.ONE_WEEK,
    [trialPeriodDays],
  )

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.LIVECHAT_UPGRADE))
    dispatch(
      getSubscriptionListAction({
        tags: SubscriptionTag.NO_TAX,
        subscriptionType: SubscriptionListType.UPGRADE,
      }),
    )
  }, [dispatch])

  useEffect(() => {
    if (!priceId) return

    eventLogger.logSalePageShown({
      productIds: [
        createProductId({
          periodName,
          periodQuantity,
          price,
        }),
      ],
      screenName: ScreenName.LIVECHAT_UPGRADE,
    })
  }, [periodName, periodQuantity, price, priceId])

  useEffect(() => {
    if (!amountToPay || !periodQuantity) return

    dispatch(
      sendUserConfigAction({
        subscription_upgrade_price: hasSevenDayTrial
          ? price.toFixed(2)
          : amountToPay.toFixed(2),
        subscription_upgrade_duration: periodQuantity,
      }),
    )
  }, [amountToPay, dispatch, periodQuantity, hasSevenDayTrial, price])

  const upgradePlan = useCallback(() => {
    dispatch(purchaseUpgrade())
  }, [dispatch])

  const handleClick = useCallback(() => {
    window.fbq('track', 'AddToCart', {}, { eventID: uuid })
    googleAnalyticsLogger.logPageView(`${PagePath.SUBSCRIPTIONS}/${cohort}`)
    upgradePlan()
  }, [cohort, upgradePlan, uuid])

  return !hasPrices ? (
    <Spinner />
  ) : (
    <DesktopProvider>
      <S.Wrapper>
        <S.Column>
          <S.Title>{t('subscriptions.purchaseUpgrade.title')}</S.Title>
          <S.Text>{t`subscriptions.purchaseUpgrade.subtitle`}</S.Text>
        </S.Column>

        <S.ImageContainer>
          <img
            src={
              language === Language.DE
                ? `${CDN_FOLDER_LINK}/images/subscription-few_${Language.EN}.webp`
                : `${CDN_FOLDER_LINK}/images/subscription-few_${language}.webp`
            }
            alt="map with locations"
          />
        </S.ImageContainer>

        <S.Column data-has-bg>
          <S.Review>
            <S.Name>
              {t('subscriptions.purchaseUpgrade.review.userName')}
            </S.Name>
            <S.Stars src={stars} alt="rating" />
            <S.ReviewText>
              {t('subscriptions.purchaseUpgrade.review.feedback')}
            </S.ReviewText>
          </S.Review>

          {hasSevenDayTrial ? <PlanBlockVariant2 /> : <PlanBlockVariant1 />}

          <S.Button onClick={handleClick}>{t`actions.start`}</S.Button>
          <S.Subtitle marginBottom={27}>
            {t('subscriptions.purchaseUpgrade.benefits.title')}
          </S.Subtitle>

          <S.BenefitsList>
            {BENEFITS.map((text) => (
              <S.Benefit key={text}>{t(text)}</S.Benefit>
            ))}
          </S.BenefitsList>

          <S.Button onClick={handleClick} marginBottom={12}>
            {t`actions.start`}
          </S.Button>

          {hasSevenDayTrial ? (
            <S.Disclaimer>
              {t('subscriptions.purchaseUpgrade.plan.disclaimer', {
                trialPeriodDays,
              })}
            </S.Disclaimer>
          ) : (
            <S.SubscriptionDescription>
              {t(
                PERIOD_QUANTITY_PURCHASE_UPGRADE[
                  periodQuantity || BillingCycle.ONE_MONTH
                ],
                {
                  price: amountToPay,
                  fullPrice: price,
                  currencySymbol: CURRENCY_SYMBOLS[currency],
                  minimumFractionDigits: 2,
                },
              )}
              <TermsOfUseLink /> {t`subscriptions.description.contact`}{' '}
              <SupportLink />
            </S.SubscriptionDescription>
          )}

          <Guarantee />
          <Security />
        </S.Column>
        <StripePaymentProcessing onTryAgain={upgradePlan} />
      </S.Wrapper>
    </DesktopProvider>
  )
}
