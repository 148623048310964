import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
} from '@stripe/stripe-js'

import { resetErrorAction } from 'root-redux/actions/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { getDefaultPaymentErrorsState } from 'modules/payment/helpers/getDefaultPaymentErrorsState'
import { getPaymentErrorState } from 'modules/payment/helpers/getPaymentErrorState'

import { CardPaymentFieldName, Cohort } from 'root-constants/common'

import {
  EMPTY_FIELD_ERROR,
  PaymentMethod,
  THREEDS_REDIRECT_SEARCH_PARAM,
} from '../../constants'
import {
  check3DSecure,
  purchaseAction,
  setPaymentMethodAction,
} from '../../redux/actions'
import { TCreditCardField, TPaymentErrorState } from '../../types'
import {
  StyledStripePaymentForm as S,
  stripeElementStyle,
  stripeElementStyleFamyLocateCohort,
  stripeElementStyleFriendCohort,
} from './StripePaymentForm.styles'

type TProps = {
  hasLaptopStyles?: boolean
  className?: string
}

export const StripePaymentForm: React.FC<TProps> = ({
  hasLaptopStyles = false,
  className,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { search } = useLocation()
  const elements = useElements()
  const stripe = useStripe()
  const cardNumberElemRef = useRef<StripeCardNumberElement | null>(null)
  const cardExpiryElemRef = useRef<StripeCardExpiryElement | null>(null)
  const cvcElemRef = useRef<StripeCardCvcElement | null>(null)
  const cardholderNameElemRef = useRef<HTMLInputElement>(null)

  const [errors, setErrors] = useState<TPaymentErrorState>(() =>
    getDefaultPaymentErrorsState(),
  )
  const [name, setName] = useState('')

  const { cohortToUse, isFetching, threeDSecureIframeUrl } = usePurchaseStore()
  const { currentPageId } = useGetPageInfo()
  const { buttonText } = useDynamicPaywallConfig()

  const hasErrors = useMemo(
    () => Object.values(errors).some((error) => error.isShown && error.error),
    [errors],
  )
  const hasUntouchedFields = useMemo(
    () =>
      Object.values(errors).some((error) => error.isShown && !error.isTouched),
    [errors],
  )
  const hasUncompletedFields = useMemo(
    () => Object.values(errors).some((field) => !field.isComplete),
    [errors],
  )
  const isFormValid = useMemo(
    () => !hasErrors && !hasUntouchedFields && !hasUncompletedFields,
    [hasErrors, hasUncompletedFields, hasUntouchedFields],
  )
  const isFriendsCohort = useMemo(
    () => cohortToUse === Cohort.FRIENDS_1,
    [cohortToUse],
  )
  const isFamyLocateCohort = useMemo(
    () => cohortToUse === Cohort.FAMYLOCATE_V1,
    [cohortToUse],
  )

  const inputStyles = useMemo(() => {
    if (isFriendsCohort) {
      return stripeElementStyleFriendCohort
    }
    if (isFamyLocateCohort) {
      return stripeElementStyleFamyLocateCohort
    }
    return stripeElementStyle
  }, [isFriendsCohort, isFamyLocateCohort])

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const isSuccess = URLParams.has(THREEDS_REDIRECT_SEARCH_PARAM)

    if (!isSuccess || !threeDSecureIframeUrl || !stripe) return

    dispatch(check3DSecure(stripe))
  }, [dispatch, search, stripe, threeDSecureIframeUrl])

  const handleChange = useCallback(
    ({
      fieldName,
      isEmpty,
      hasError,
      isComplete,
      nextElemRef,
    }: {
      fieldName: CardPaymentFieldName
      isEmpty: boolean
      hasError: boolean
      isComplete: boolean
      nextElemRef?: RefObject<TCreditCardField>
    }) => {
      dispatch(resetErrorAction())

      let error = ''

      if (hasError) {
        error = 'is invalid'
      }

      if (isEmpty) {
        error = EMPTY_FIELD_ERROR
      }

      if (nextElemRef && isComplete) {
        nextElemRef.current?.focus()
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: { isTouched: true, error, isComplete },
      }))
    },
    [dispatch],
  )

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      dispatch(resetErrorAction())

      if (hasUntouchedFields) {
        setErrors(getPaymentErrorState(errors))
        return
      }

      if (hasErrors) return

      const card = elements?.getElement(CardNumberElement)

      if (!stripe || !card || isFetching) return

      cardholderNameElemRef.current?.blur()
      dispatch(setPaymentMethodAction(PaymentMethod.CREDIT_CARD))
      dispatch(
        purchaseAction({
          stripe,
          card,
          name,
          paymentPageId: currentPageId,
        }),
      )
    },
    [
      dispatch,
      hasUntouchedFields,
      hasErrors,
      elements,
      stripe,
      name,
      currentPageId,
      errors,
      isFetching,
    ],
  )

  return (
    <S.Form onSubmit={handleSubmit} className={className}>
      <S.Label gridArea="cardNumberLabel">{t`payment.stripe.card`}</S.Label>
      <S.CardNumberElement
        onReady={(elem) => {
          elem.focus()
          cardNumberElemRef.current = elem
        }}
        options={{
          showIcon: false,
          placeholder: '1234 1234 1234 1234',
          style: inputStyles,
        }}
        onChange={({ empty, error, complete }) => {
          handleChange({
            fieldName: CardPaymentFieldName.NUMBER,
            isEmpty: empty,
            hasError: !!error,
            isComplete: complete,
            nextElemRef: cardExpiryElemRef,
          })
        }}
      />

      <S.Label gridArea="cardExpiryLabel">{t`payment.stripe.date`}</S.Label>
      <S.CardExpiryElement
        onReady={(elem) => {
          cardExpiryElemRef.current = elem
        }}
        options={{
          placeholder: t`payment.stripe.datePlaceholder`,
          style: inputStyles,
        }}
        onChange={({ empty, error, complete }) => {
          handleChange({
            fieldName: CardPaymentFieldName.EXPIRY,
            isEmpty: empty,
            hasError: !!error,
            isComplete: complete,
            nextElemRef: cvcElemRef,
          })
        }}
      />

      <S.Label gridArea="cardCvcLabel">{t`payment.stripe.cvc`}</S.Label>
      <S.CardCvcElement
        onReady={(elem) => {
          cvcElemRef.current = elem
        }}
        options={{
          placeholder: '111',
          style: inputStyles,
        }}
        onChange={({ empty, error, complete }) => {
          handleChange({
            fieldName: CardPaymentFieldName.CVC,
            isEmpty: empty,
            hasError: !!error,
            isComplete: complete,
            nextElemRef: cardholderNameElemRef,
          })
        }}
      />

      <S.Label gridArea="cardholderLabel">{t`payment.stripe.name`}</S.Label>
      <S.CardholderInput
        ref={cardholderNameElemRef}
        type="text"
        placeholder={t`payment.stripe.placeholder`}
        data-invalid={!!errors[CardPaymentFieldName.NAME].error}
        onChange={(e) => {
          const value = e.target.value.trim()

          setName(value)

          handleChange({
            fieldName: CardPaymentFieldName.NAME,
            isEmpty: false,
            hasError: false,
            isComplete: true,
          })
        }}
      />
      <S.SubmitButton
        type="submit"
        disabled={!stripe || !isFormValid || isFetching}
        data-has-laptop-styles={hasLaptopStyles}
      >
        {buttonText || t`actions.continue`}
      </S.SubmitButton>
    </S.Form>
  )
}
