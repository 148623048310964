import styled from 'styled-components'

import { Button } from 'components/Button'

export const StyledSubheaderWithTimer = {
  TimerContainer: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    height: 80px;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.timerBackground};

    &[data-is-visible='true'] {
      display: flex;
    }
  `,
  TimerContent: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  `,
  Logo: styled.img`
    display: none;
  `,
  Timer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;

    strong {
      font-size: 38px;
      font-weight: 700;
      line-height: 40px;
    }

    em {
      font-size: 12px;
      line-height: 18px;
      font-style: normal;
    }
  `,
  Button: styled(Button)`
    width: 185px;
    height: 40px;
    line-height: 40px;
    padding: 0;

    &[data-is-de-lang='true'] {
      font-size: 16px;
    }
  `,
}
