import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import { paymentApi } from 'api'

import { sendUserConfigAction } from 'root-redux/actions/user'
import { selectCurrentVariantCohort } from 'root-redux/selects/common'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { eventLogger } from 'services/eventLogger.service'

import { PaymentMethod, PaymentSystem } from 'modules/payment/constants'
import { logFailedPayment } from 'modules/payment/helpers/logFailedPayment'
import { logSuccessfulPayment } from 'modules/payment/helpers/logSuccessfulPayment'
import { setPaymentMethodAction } from 'modules/payment/redux/actions'

import { Modal } from 'components/Modal'
import { PaymentSuccess } from 'components/PaymentSuccess'

import { StyledPayPalContainer as S } from './PayPalContainer.styles'

type TProps = {
  hasLaptopStyles?: boolean
}

export const PayPalContainer: React.FC<TProps> = ({
  hasLaptopStyles = false,
}) => {
  const dispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort)
  const [isPaymentStatusShown, setIsPaymentStatusShown] = useState(false)
  const [isErrorModalShown, setIsErrorModalShown] = useState(false)

  const {
    email,
    uuid,
    trialPrice,
    trialPeriodDays,
    currentPrice,
    currency,
    periodName,
    periodQuantity,
    screenName,
    paypalPlanId,
    paypalClientId,
  } = usePurchaseStore()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const handleResetError = useCallback(() => {
    setIsPaymentStatusShown(false)
    setIsErrorModalShown(false)
  }, [])

  const handlePaymentApprove = useCallback(
    async (data) => {
      logSuccessfulPayment({
        email,
        subscriptionId: data.subscriptionID,
        productId: paypalPlanId,
        uuid,
        price: currentPrice,
        trialPrice,
        trialPeriodDays,
        currency,
        paymentMethod: PaymentMethod.PAYPAL,
        paymentSystem: PaymentSystem.PAYPAL,
        screenName,
        isPersonalDataAllowed,
      })

      const response = await paymentApi.createPaypalSubscription({
        uuid,
        paypalPlanId,
        cohort,
      })

      if (response.status) {
        dispatch(
          sendUserConfigAction({
            payment_currency: currency,
            payment_method: PaymentMethod.PAYPAL,
            is_download_visited: false,
            subscription_price: currentPrice,
            subscription_duration: `${periodQuantity}${periodName}`,
            price_id: paypalPlanId,
            trial_price: trialPrice,
            trial_period: trialPeriodDays,
          }),
        )

        setIsPaymentStatusShown(true)
        setIsErrorModalShown(false)
      }
    },
    [
      currency,
      currentPrice,
      dispatch,
      email,
      screenName,
      paypalPlanId,
      periodName,
      periodQuantity,
      trialPeriodDays,
      trialPrice,
      uuid,
      cohort,
      isPersonalDataAllowed,
    ],
  )

  const handlePaymentError = useCallback(
    (error) => {
      setIsErrorModalShown(true)
      logFailedPayment({
        productId: paypalPlanId,
        price: currentPrice,
        currency,
        email,
        screenName,
        paymentResponse: {
          type: error?.name || '',
          code: error?.debug_id,
          message: error?.message,
        },
        paymentMethod: PaymentMethod.PAYPAL,
        paymentSystem: PaymentSystem.PAYPAL,
        isTrialActive: !!trialPrice,
        isPersonalDataAllowed,
      })
    },
    [
      paypalPlanId,
      currentPrice,
      currency,
      email,
      screenName,
      trialPrice,
      isPersonalDataAllowed,
    ],
  )

  const handleButtonClick = useCallback(() => {
    dispatch(setPaymentMethodAction(PaymentMethod.PAYPAL))

    eventLogger.logPaymentMethodSelected({
      paymentMethod: PaymentMethod.PAYPAL,
    })
    eventLogger.logPurchaseStarted({
      email,
      screenName,
      productId: paypalPlanId,
      priceDetails: {
        price: currentPrice,
        trial: !!trialPrice,
        currency,
      },
      paymentMethod: PaymentMethod.PAYPAL,
      paymentSystem: PaymentSystem.PAYPAL,
      isPersonalDataAllowed,
    })
  }, [
    email,
    paypalPlanId,
    currentPrice,
    trialPrice,
    currency,
    screenName,
    dispatch,
    isPersonalDataAllowed,
  ])

  return (
    <>
      <S.ButtonsContainer data-has-laptop-styles={hasLaptopStyles}>
        {paypalPlanId && paypalClientId && (
          <PayPalScriptProvider
            options={{
              'client-id': paypalClientId,
              vault: true,
              'disable-funding': 'credit',
            }}
          >
            <PayPalButtons
              style={{
                label: 'paypal',
                tagline: false,
                layout: 'horizontal',
                height: 55,
              }}
              forceReRender={[paypalPlanId]}
              createSubscription={(data, actions) => {
                return actions.subscription.create({
                  plan_id: paypalPlanId, // Creates the subscription
                  custom_id: uuid,
                })
              }}
              onApprove={(data) => handlePaymentApprove(data)}
              onError={(error) => handlePaymentError(error)}
              onClick={handleButtonClick}
            />
          </PayPalScriptProvider>
        )}
      </S.ButtonsContainer>
      {isPaymentStatusShown && <PaymentSuccess />}
      <Modal onClose={handleResetError} isShown={isErrorModalShown}>
        There was an error processing your payment information
      </Modal>
    </>
  )
}
