import React, { useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getUserStatusAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohortToUse,
  selectScreenName,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { ScreenName } from 'services/eventLogger.service'

import { PaymentMethod } from 'modules/payment/constants'
import { selectPaymentMethod } from 'modules/payment/redux/selects'

import successIconDark from 'assets/images/success-3.svg'
import successIcon from 'assets/images/success.svg'

import { StyledPaymentStatus as S } from 'common-styles'
import { Cohort } from 'root-constants/common'

type TProps = {
  onNextStep?: () => void
  hasChipolo?: boolean
}

export const PaymentSuccess: React.FC<TProps> = ({
  onNextStep,
  hasChipolo,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const uuid = useSelector(selectUUID)
  const paymentMethod = useSelector(selectPaymentMethod)
  const screenName = useSelector(selectScreenName)

  useEffect(() => {
    return () => {
      dispatch(getUserStatusAction(uuid))
    }
  }, [dispatch, uuid])

  const handleSuccessButtonClick = useCallback(() => {
    if (onNextStep) {
      onNextStep()
      return
    }
    dispatch(getUserStatusAction(uuid))
  }, [dispatch, onNextStep, uuid])

  return (
    <S.Wrapper>
      <S.Container data-testid="purchase-outcome-container">
        <S.Icon
          src={cohortToUse === Cohort.FRIENDS_1 ? successIconDark : successIcon}
          alt="Success"
        />
        <S.Title>{t`payment.successful`}</S.Title>
        <S.Subtitle>
          <Trans
            i18nKey={
              screenName === ScreenName.UPGRADE && hasChipolo
                ? 'payment.orderAccepted'
                : 'payment.thankYou'
            }
          />
        </S.Subtitle>
        <S.Button type="button" onClick={handleSuccessButtonClick}>
          {t`payment.proceedToMyPlan`}
        </S.Button>
        {paymentMethod === PaymentMethod.PAYPAL && (
          <S.Disclaimer>{t`payment.successDisclaimer`}</S.Disclaimer>
        )}
      </S.Container>
    </S.Wrapper>
  )
}
