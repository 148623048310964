import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { ThemeProvider } from 'styled-components'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { Cohort } from 'root-constants/common'

import { COMMON_THEME, FRIENDS_THEME, SAFETY_THEME } from '../../styles/themes'

type TProps = {
  children: React.ReactNode
}

export const Theme: React.FC<TProps> = ({ children }) => {
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const currentTheme = useMemo(() => {
    switch (cohortToUse) {
      case Cohort.SAFETY_4:
        return SAFETY_THEME
      case Cohort.FRIENDS_1:
        return FRIENDS_THEME
      default:
        return COMMON_THEME
    }
  }, [cohortToUse])

  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
}
