import { createGlobalStyle } from 'styled-components'

import { Color } from 'root-constants/common'

export const FamylocateGlobalStyles = createGlobalStyle`
  * {
    font-family: 'Open Sans', sans-serif;
  }
  
  :root {
    --base-background: ${Color.DEEP_BLUE};
  }
`
