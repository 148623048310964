import { TProductId } from 'models/subscriptions.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PaymentMethod, PaymentSystem } from '../constants'

export const logSuccessfulPayment = ({
  email,
  productId,
  price,
  screenName,
  currency,
  trialPrice,
  trialPeriodDays,
  subscriptionId,
  discountApplied,
  uuid,
  paymentMethod,
  paymentSystem,
  stripeAccountName,
  stripeAccountId,
  isPersonalDataAllowed,
}: {
  email: string
  productId: TProductId
  price: number
  screenName: ScreenName
  currency?: string
  trialPrice?: number
  trialPeriodDays?: number
  subscriptionId: string
  discountApplied?: string
  uuid: string
  paymentMethod?: PaymentMethod
  paymentSystem: PaymentSystem
  stripeAccountName?: string
  stripeAccountId?: string
  isPersonalDataAllowed: boolean
}): void => {
  if (trialPeriodDays && screenName !== ScreenName.UPSELL_OFFER) {
    window.fbq(
      'track',
      'StartTrial',
      {
        value: trialPrice,
        currency,
        subscription_id: subscriptionId,
        // This field and similar ones below have been added to avoid FB pixel issue. https://github.com/facebook/facebook-nodejs-business-sdk/issues/164
        subscription_sid: subscriptionId,
      },
      { eventID: uuid },
    )
  }

  if (screenName !== ScreenName.UPSELL_OFFER) {
    window.fbq(
      'track',
      'Subscribe',
      {
        value: trialPrice || price,
        currency,
        subscription_id: subscriptionId,
        subscription_sid: subscriptionId,
      },
      { eventID: uuid },
    )
  }

  window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
  window.ttq.track('CompletePayment', {
    value: trialPrice || price,
    currency,
    content_id: subscriptionId,
  })

  eventLogger.logPurchaseCompleted({
    email,
    screenName,
    productId,
    priceDetails: {
      price,
      trial: !!trialPrice,
      currency,
    },
    paymentMethod,
    transactionId: subscriptionId,
    paymentSystem,
    stripeAccountName,
    stripeAccountId,
    isPersonalDataAllowed,
    ...(discountApplied && { discountApplied }),
  })

  if (screenName !== ScreenName.UPSELL_OFFER) {
    googleAnalyticsLogger.logPurchaseCompleted({
      subscriptionId,
      price: trialPrice || price,
      productId,
      currency,
    })
  }
}
