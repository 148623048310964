import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectCurrentVariantCohortToUse,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUUID, selectUserCountryCode } from 'root-redux/selects/user'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useGAPageTracker } from 'hooks/useGAPageTracker'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useGiaFlowTrackerInitialization } from 'hooks/useGiaFlowTrackerInitialization'
import { growthbook } from 'hooks/useGrowthBook'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { useSetPlanAdditions } from 'hooks/useSetPlanAdditions'
import { useStartSession } from 'hooks/useStartSession'
import { useUtmTags } from 'hooks/useUtmTags'

import { getEnvironment } from 'helpers/getEnvironment'

import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { CookieConsent } from 'components/CookieConsent'
import { Header } from 'components/Header'
import { InContextControlPanel } from 'components/InContextControlPanel'
import { FamylocateGlobalStyles } from 'components/LayoutProvider/LayoutProvider.styles'
import { Theme } from 'components/Theme'

import { BackgroundFriendsGlobalStyles } from 'common-styles'
import { Cohort, FAMYLOCATE_COHORTS, Language } from 'root-constants/common'

import { RouteList } from '../../RouteList'

export const LayoutProvider: React.FC = () => {
  const dispatch = useDispatch()
  const { search, pathname } = useLocation()
  const { i18n } = useTranslation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const language = useSelector(selectLanguage)
  const uuid = useSelector(selectUUID)
  const userCountryCode = useSelector(selectUserCountryCode)

  const { isDevEnvironment } = getEnvironment()

  const { isSecondPage, isPaymentPage, isPostPaymentPage } = useGetPageInfo()

  useUtmTags()
  useGAPageTracker()
  useGiaFlowTrackerInitialization()
  useAmplitudeInitialization()
  useStartSession()
  useSetPlanAdditions()
  useScrollToTop()

  const { isEUUser, cookieConsent } = useCookieConsentAnswer()

  const isFriendsCohort = useMemo(
    () => cohortToUse === Cohort.FRIENDS_1,
    [cohortToUse],
  )

  const hasAlternativeStyles = useMemo(
    () => FAMYLOCATE_COHORTS.includes(cohortToUse),
    [cohortToUse],
  )

  const isCookieConsentAvailable = useMemo(
    () => !cookieConsent && isEUUser && language === Language.EN,
    [cookieConsent, isEUUser, language],
  )

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [i18n, language])

  useEffect(() => {
    if (isSecondPage) {
      dispatch(sendFacebookParamsAction())
      window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
      googleAnalyticsLogger.logFirstPageCompleted()
    }
  }, [dispatch, isSecondPage, uuid])

  useEffect(() => {
    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      url: `${pathname}${search}`,
      country: userCountryCode,
      cohort,
      language,
    })
  }, [cohort, language, pathname, search, userCountryCode])

  return (
    <Theme>
      {hasAlternativeStyles && <FamylocateGlobalStyles />}

      {isDevEnvironment && <InContextControlPanel />}
      {isFriendsCohort && (
        <BackgroundFriendsGlobalStyles
          hasDarkenBg={isPaymentPage || isPostPaymentPage}
        />
      )}
      <Header />
      {isCookieConsentAvailable && <CookieConsent />}
      <RouteList />
    </Theme>
  )
}
