import styled from 'styled-components'

import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import appImg from 'assets/images/intro.webp'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants/common'

export const StyledIntro = {
  Wrapper: styled.div`
    ${basePageStyles};
    width: 100%;
  `,
  ImageContainer: styled.div`
    width: 100%;
    min-height: 600px;
    padding-top: 15px;
    background-image: url(${appImg});
    background-size: 100% auto;
    background-repeat: no-repeat;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: none;
    }
  `,
  FixedContainer: styled.div`
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 100px 0 18px;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 3%,
      #fff 24%
    );
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      position: static;
      padding: 24px 0;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    text-align: center;
    margin: 0 auto;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 540px;
    }
  `,
  Title: styled.h1`
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    margin-bottom: 10px;

    @media (min-height: 550px) {
      margin-bottom: 31px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: 65px;
      font-size: 48px;
      line-height: 65px;
    }
  `,
  OptionsContainer: styled(StyledOption.OptionsContainer)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;

    div {
      min-width: 150px;
      flex-shrink: 0;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 0 12px;
      padding-bottom: 0;

      div {
        min-width: 460px;
        height: 76px;
      }
    }
  `,
  QuestionButton: styled(QuestionButton)`
    justify-content: center;
  `,
  NotSureButton: styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};

    input:checked + & {
      color: ${Color.ACCENT_VIOLET};
    }

    @media (min-height: 550px) {
      margin-bottom: 35px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: 150px;
      padding: 25px 0;
      min-width: 460px;
      height: 76px;
      background-color: ${Color.GREY};
      border-radius: 15px;
      font-size: 18px;
      line-height: 25px;
      color: ${Color.DEFAULT_TEXT};

      input:checked + & {
        background-color: ${Color.ACCENT_VIOLET};
        color: #fff;
      }
    }
  `,
}
