/* eslint-disable max-lines */
import { TIdToPageMap } from 'models/common.model'

import { Account } from 'modules/account/Account'
import {
  EmailVariant1,
  EmailVariant2,
  EmailVariant3,
  EmailVariant4,
} from 'modules/email/pages'
import {
  ChipoloCheckout,
  PaymentVariant1,
  PaymentVariant2,
  PaymentVariant3,
  PaymentVariant4,
  PaymentVariant5,
  PaymentVariant6,
} from 'modules/payment/pages'
import {
  CancelOfferSubscriptionVariant1,
  ChipoloUpgradeVariant1,
  EmailSubscriptionsVariant1,
  EmailSubscriptionsVariant2,
  PurchaseUpgradeVariant1,
  SubscriptionsGoogleVariant1,
  SubscriptionsGoogleVariant2,
  SubscriptionsGoogleVariant3,
  SubscriptionsGoogleVariant4,
  SubscriptionsGoogleVariant5,
  SubscriptionsGoogleVariant6,
  SubscriptionsVariant1,
  SubscriptionsVariant2,
  SubscriptionsVariant3,
  SubscriptionsVariant4,
  SubscriptionsVariant5,
  SubscriptionsVariant6,
  SubscriptionsVariant8,
  SubscriptionsVariant9,
  SubscriptionsVariant10,
  TrialSubscriptionsVariant1,
  UpsellVariant1,
} from 'modules/subscriptions/pages'

import { DataVariant3 } from 'pages/data'
import { IntroVariant8 } from 'pages/intro'

import {
  AgeRangeVariant1,
  AppBenefitVariant2,
  BehaviourChangesVariant1,
  BenefitsVariant1,
  BiggestFearVariant1,
  CallsLateVariant1,
  CatchOnALieVariant1,
  CheatingVariant1,
  ChildAgeVariant1,
  ChildInTroubleVariant1,
  ChildLocationVariant1,
  ChildLocationVariant2,
  ChildLocationVariant3,
  ChildLocationVariant4,
  ChildMovesVariant1,
  ChildMovesVariant2,
  ChildSchoolIssuesVariant1,
  ChildSocialCircleVariant1,
  ConcernsVariant1,
  ConcernsVariant2,
  ConnectionVariant1,
  DataVariant1,
  DataVariant2,
  DaysDisclosureVariant1,
  DeleteCallsVariant1,
  DistressSignalsVariant1,
  DownloadApp,
  DrivingHabitsVariant1,
  DrivingHabitsVariant2,
  DrugsDiscussionVariant1,
  DynamicIntroMultipleChoice,
  DynamicIntroSingleChoice,
  DynamicLeftToRight,
  EmergencyCaseVariant1,
  FamilySafetyVariant1,
  FearVariant1,
  FearVariant2,
  FeedbackVariant1,
  FeelAnxiousVariant1,
  FeelingsAboutLackOfContactVariant1,
  FeelingsVariant1,
  FeelingsVariant2,
  FeltHelplessVariant1,
  FeltHelplessVariant2,
  FeltHelplessVariant3,
  FriendSafetyVariant1,
  GenderVariant1,
  GeoGamesVariant1,
  GeoTagsVariant1,
  GeoTrackingVariant1,
  GeoTrackingVariant2,
  GetInTouchVariant1,
  GoingOutVariant1,
  ImportanceVariant1,
  InconsistenciesVariant1,
  InconsistenciesVariant2,
  InterestInPeopleVariant1,
  IntroGoogleVariant1,
  IntroGoogleVariant2,
  IntroGoogleVariant3,
  IntroGoogleVariant4,
  IntroGoogleVariant5,
  IntroVariant1,
  IntroVariant2,
  IntroVariant3,
  IntroVariant4,
  IntroVariant5,
  IntroVariant6,
  IntroVariant7,
  KidLostVariant1,
  KidOutdoorActivitiesVariant1,
  KidsAlertsVariant1,
  KidsSafetyVariant1,
  LackOfContactVariant1,
  LocationSearchVariant1,
  LocationSearchVariant2,
  LocationVariant1,
  LocatorAppVariant1,
  LowBatteryVariant1,
  LowBatteryVariant2,
  LowBatteryVariant3,
  LowBatteryVariant4,
  MovementHistoryVariant1,
  NotificationsVariant1,
  OnePlanForEveryoneVariant1,
  ParentReviewsVariant1,
  PartnersDataVariant1,
  PartnersDataVariant2,
  PartnersInDangerVariant1,
  PartnersSafetyVariant1,
  PartnersSafetyVariant2,
  PhoneHabitsVariant1,
  PhoneNumberVariant1,
  PhoneNumberVariant2,
  PinpointLocationVariant1,
  PossibilityVariant1,
  PreparingFlowVariant1,
  PreparingFlowVariant2,
  PreparingFlowVariant3,
  PreparingFlowVariant4,
  PrivateChatVariant1,
  ProtectiveOfPhoneVariant1,
  PutPhoneAwayVariant1,
  QuickWayVariant1,
  QuickWayVariant2,
  QuickWayVariant3,
  QuickWayVariant4,
  QuicklyFindVariant1,
  QuicklyFindVariant2,
  ReasonToJoinVariant1,
  ReasonToJoinVariant2,
  ReasonsVariant1,
  ReasonsVariant2,
  ReasonsVariant3,
  ReceiveInformationVariant1,
  ReviewsVariant1,
  RoutinesChangesVariant1,
  ShareLocationVariant1,
  SocialProofVariant1,
  SocialProofVariant2,
  SocialProofVariant3,
  SocialProofVariant4,
  SocialProofVariant5,
  SocialProofVariant6,
  SocialProofVariant7,
  SocialProofVariant8,
  SocialProofVariant9,
  SocialProofVariant10,
  SocialProofVariant11,
  SocialProofVariant12,
  SocialProofVariant13,
  SocialProofVariant14,
  SocialProofVariant15,
  SocialProofVariant16,
  SocialProofVariant17,
  SocialProofVariant18,
  SocialProofVariant19,
  SocialProofVariant20,
  SocialProofVariant21,
  SosMessageVariant1,
  SosMessageVariant2,
  SosMessageVariant3,
  SpecificZonesVariant1,
  SpecificZonesVariant2,
  StayConnectedVariant1,
  StayConnectedVariant2,
  StayConnectedVariant3,
  StayOutVariant1,
  StolenPhoneVariant1,
  StolenPhoneVariant2,
  SuspiciousActivityVariant1,
  SuspiciousActivityVariant2,
  TakeCallsVariant1,
  TopPriorityVariant1,
  TopPriorityVariant2,
  TrackingFrequencyVariant1,
  UnsafeAreasVariant1,
  UrgentMessagesVariant1,
  WhatToDoVariant1,
  WhatToDoVariant2,
  WishVariant1,
  WishVariant2,
  WishVariant3,
} from 'pages'

export const enum CustomPageId {
  PHONE_NUMBER = 'phone_number',
}

export const enum PageId {
  INTRO_1 = 'intro_1',
  INTRO_2 = 'intro_2',
  INTRO_3 = 'intro_3',
  INTRO_4 = 'intro_4',
  INTRO_5 = 'intro_5',
  INTRO_6 = 'intro_6',
  INTRO_7 = 'intro_7',
  INTRO_8 = 'intro_8',
  INTRO_GOOGLE_1 = 'intro_google_1',
  INTRO_GOOGLE_2 = 'intro_google_2',
  INTRO_GOOGLE_3 = 'intro_google_3',
  INTRO_GOOGLE_4 = 'intro_google_4',
  INTRO_GOOGLE_5 = 'intro_google_5',
  REVIEWS_1 = 'reviews_1',
  BENEFITS_1 = 'benefits_1',
  CATCH_ON_A_LIE_1 = 'catch_on_a_lie_1',
  BEHAVIOUR_CHANGES_1 = 'behaviour_changes_1',
  ROUTINES_CHANGES_1 = 'routines_changes_1',
  DAYS_DISCLOSURE_1 = 'days_disclosure_1',
  INCONSISTENCIES_1 = 'inconsistencies_1',
  INCONSISTENCIES_2 = 'inconsistencies_2',
  INTEREST_IN_PEOPLE_1 = 'interest_in_people_1',
  PHONE_HABITS_1 = 'phone_habits_1',
  PROTECTIVE_OF_PHONE_1 = 'protective_of_phone_1',
  TAKE_CALLS_1 = 'take_calls_1',
  CALLS_LATE_1 = 'calls_late_1',
  DELETE_CALLS_1 = 'delete_calls_1',
  PUT_PHONE_AWAY_1 = 'put_phone_away_1',
  SUSPICIOUS_ACTIVITY_1 = 'suspicious_activity_1',
  SUSPICIOUS_ACTIVITY_2 = 'suspicious_activity_2',
  GOING_OUT_1 = 'going_out_1',
  STAY_OUT_1 = 'stay_out_1',
  STAY_CONNECTED_1 = 'stay_connected_1',
  STAY_CONNECTED_2 = 'stay_connected_2',
  STAY_CONNECTED_3 = 'stay_connected_3',
  FAMILY_SAFETY_1 = 'family_safety_1',
  ONE_PLAN_FOR_EVERYONE_1 = 'one_plan_for_everyone_1',
  CHEATING_1 = 'cheating_1',
  GENDER_1 = 'gender_1',
  CHILD_AGE_1 = 'child_age_1',
  AGE_RANGE_1 = 'age_range_1',
  LOCATOR_APP_1 = 'locator_app_1',
  PARTNERS_DATA_1 = 'partners_data_1',
  PARTNERS_DATA_2 = 'partners_data_2',
  PARTNERS_SAFETY_1 = 'partners_safety_1',
  PARTNERS_SAFETY_2 = 'partners_safety_2',
  PARTNERS_IN_DANGER_1 = 'partners_in_danger_1',
  FRIENDS_SAFETY_1 = 'friends_safety_1',
  DATA_1 = 'data_1',
  DATA_2 = 'data_2',
  DATA_3 = 'data_3',
  TRACKING_FREQUENCY_1 = 'tracking_frequency_1',
  REASONS_1 = 'reasons_1',
  REASONS_2 = 'reasons_2',
  REASONS_3 = 'reasons_3',
  LOCATION_1 = 'location_1',
  CHILD_LOCATION_1 = 'child_location_1',
  CHILD_LOCATION_2 = 'child_location_2',
  CHILD_LOCATION_3 = 'child_location_3',
  CHILD_LOCATION_4 = 'child_location_4',
  CHILD_SOCIAL_CIRCLE_1 = 'child_social_circle_1',
  CHILD_SCHOOL_ISSUES_1 = 'child_school_issues_1',
  DRUGS_DISCUSSION_1 = 'drugs_discussion_1',
  CHILD_MOVES_1 = 'child_moves_1',
  CHILD_MOVES_2 = 'child_moves_2',
  MOVEMENT_HISTORY_1 = 'movement_history_1',
  URGENT_MESSAGES_1 = 'urgent_messages_1',
  UNSAFE_AREAS_1 = 'unsafe_areas_1',
  DISTRESS_SIGNALS_1 = 'distress_signals_1',
  FEAR_1 = 'fear_1',
  FEAR_2 = 'fear_2',
  CHILD_IN_TROUBLE_1 = 'child_in_trouble_1',
  CONCERNS_1 = 'concerns_1',
  CONCERNS_2 = 'concerns_2',
  CONNECTION_1 = 'connection_1',
  FEELINGS_1 = 'feelings_1',
  FEELINGS_2 = 'feelings_2',
  SOCIAL_PROOF_1 = 'social_proof_1',
  SOCIAL_PROOF_2 = 'social_proof_2',
  SOCIAL_PROOF_3 = 'social_proof_3',
  SOCIAL_PROOF_4 = 'social_proof_4',
  SOCIAL_PROOF_5 = 'social_proof_5',
  SOCIAL_PROOF_6 = 'social_proof_6',
  SOCIAL_PROOF_7 = 'social_proof_7',
  SOCIAL_PROOF_8 = 'social_proof_8',
  SOCIAL_PROOF_9 = 'social_proof_9',
  SOCIAL_PROOF_10 = 'social_proof_10',
  SOCIAL_PROOF_11 = 'social_proof_11',
  SOCIAL_PROOF_12 = 'social_proof_12',
  SOCIAL_PROOF_13 = 'social_proof_13',
  SOCIAL_PROOF_14 = 'social_proof_14',
  SOCIAL_PROOF_15 = 'social_proof_15',
  SOCIAL_PROOF_16 = 'social_proof_16',
  SOCIAL_PROOF_17 = 'social_proof_17',
  SOCIAL_PROOF_18 = 'social_proof_18',
  SOCIAL_PROOF_19 = 'social_proof_19',
  SOCIAL_PROOF_20 = 'social_proof_20',
  SOCIAL_PROOF_21 = 'social_proof_21',
  GET_IN_TOUCH_1 = 'get_in_touch_1',
  WISH_1 = 'wish_1',
  WISH_2 = 'wish_2',
  WISH_3 = 'wish_3',
  TOP_PRIORITY_1 = 'top_priority_1',
  TOP_PRIORITY_2 = 'top_priority_2',
  FELT_HELPLESS_1 = 'felt_helpless_1',
  FELT_HELPLESS_2 = 'felt_helpless_2',
  FELT_HELPLESS_3 = 'felt_helpless_3',
  REASON_TO_JOIN_1 = 'reason_to_join_1',
  REASON_TO_JOIN_2 = 'reason_to_join_2',
  LOW_BATTERY_1 = 'low_battery_1',
  LOW_BATTERY_2 = 'low_battery_2',
  LOW_BATTERY_3 = 'low_battery_3',
  LOW_BATTERY_4 = 'low_battery_4',
  WHAT_TO_DO_1 = 'what_to_do_1',
  WHAT_TO_DO_2 = 'what_to_do_2',
  STOLEN_PHONE_1 = 'stolen_phone_1',
  STOLEN_PHONE_2 = 'stolen_phone_2',
  SOS_MESSAGE_1 = 'sos_message_1',
  SOS_MESSAGE_2 = 'sos_message_2',
  SOS_MESSAGE_3 = 'sos_message_3',
  QUICK_WAY_1 = 'quick_way_1',
  QUICK_WAY_2 = 'quick_way_2',
  QUICK_WAY_3 = 'quick_way_3',
  QUICK_WAY_4 = 'quick_way_4',
  PHONE_NUMBER_1 = 'phone_number_1',
  PHONE_NUMBER_2 = 'phone_number_2',
  FEEDBACK_1 = 'feedback_1',
  BIGGEST_FEAR_1 = 'biggest_fear_1',
  DRIVING_HABITS_1 = 'driving_habits_1',
  DRIVING_HABITS_2 = 'driving_habits_2',
  LACK_OF_CONTACT_1 = 'lack_of_contact_1',
  FEELINGS_ABOUT_LACK_OF_CONTACT_1 = 'feelings_about_lack_of_contact_1',
  EMERGENCY_CASE_1 = 'emergency_case_1',
  SPECIFIC_ZONES_1 = 'specific_zones_1',
  SPECIFIC_ZONES_2 = 'specific_zones_2',
  APP_BENEFIT_4 = 'app_benefit_4',
  APP_BENEFIT_5 = 'app_benefit_5',
  APP_BENEFIT_6 = 'app_benefit_6',
  PREPARING_FLOW_1 = 'preparing_flow_1',
  PREPARING_FLOW_2 = 'preparing_flow_2',
  PREPARING_FLOW_3 = 'preparing_flow_3',
  PREPARING_FLOW_4 = 'preparing_flow_4',
  LOCATION_SEARCH_1 = 'location_search_1',
  LOCATION_SEARCH_2 = 'location_search_2',
  QUICKLY_FIND_1 = 'quickly_find_1',
  QUICKLY_FIND_2 = 'quickly_find_2',
  PRIVATE_CHAT_1 = 'private_chat_1',
  GEO_GAMES_1 = 'geo_games_1',
  IMPORTANCE_1 = 'importance_1',
  GEOTRACKING_1 = 'geotracking_1',
  GEOTRACKING_2 = 'geotracking_2',
  NOTIFICATIONS_1 = 'notifications_1',
  GEOTAGS_1 = 'geotags_1',
  POSSIBILITY_1 = 'possibility_1',
  SHARE_LOCATION_1 = 'share_location_1',
  FEEL_ANXIOUS_1 = 'feel_anxious_1',
  KIDS_SAFETY_1 = 'kids_safety_1',
  KIDS_ALERTS_1 = 'kids_alerts_1',
  PARENT_REVIEWS_1 = 'parent_reviews_1',
  KID_OUTDOOR_ACTIVITIES_1 = 'kid_outdoor_activities_1',
  KID_LOST_1 = 'kid_lost_1',
  PINPOINT_LOCATION_1 = 'pinpoint_location_1',
  CREATING_PROFILE_1 = 'creating_profile_1',
  RECEIVE_INFORMATION_1 = 'receive_information_1',
  DYNAMIC_LEFT_TO_RIGHT = 'dynamic_left_to_right',
  DYNAMIC_INTRO_SINGLE_CHOICE = 'dynamic_intro_single_choice',
  DYNAMIC_INTRO_MULTIPLE_CHOICE = 'dynamic_intro_multiple_choice',
  EMAIL_1 = 'email_1',
  EMAIL_2 = 'email_2',
  EMAIL_3 = 'email_3',
  EMAIL_4 = 'email_4',
  SUBSCRIPTIONS_1 = 'subscriptions_1',
  SUBSCRIPTIONS_2 = 'subscriptions_2',
  SUBSCRIPTIONS_3 = 'subscriptions_3',
  SUBSCRIPTIONS_4 = 'subscriptions_4',
  SUBSCRIPTIONS_5 = 'subscriptions_5',
  SUBSCRIPTIONS_6 = 'subscriptions_6',
  SUBSCRIPTIONS_8 = 'subscriptions_8',
  SUBSCRIPTIONS_9 = 'subscriptions_9',
  SUBSCRIPTIONS_10 = 'subscriptions_10',
  SUBSCRIPTIONS_GOOGLE_1 = 'subscriptions_google_1',
  SUBSCRIPTIONS_GOOGLE_2 = 'subscriptions_google_2',
  SUBSCRIPTIONS_GOOGLE_3 = 'subscriptions_google_3',
  SUBSCRIPTIONS_GOOGLE_4 = 'subscriptions_google_4',
  SUBSCRIPTIONS_GOOGLE_5 = 'subscriptions_google_5',
  SUBSCRIPTIONS_GOOGLE_6 = 'subscriptions_google_6',
  TRIAL_SUBSCRIPTION_1 = 'trial_subscriptions_1',
  CANCEL_OFFER_SUBSCRIPTIONS_1 = 'cancel_offer_subscriptions_1',
  PURCHASE_UPGRADE_1 = 'purchase_upgrade_1',
  EMAIL_SUBSCRIPTIONS_1 = 'email_subscriptions_1',
  EMAIL_SUBSCRIPTIONS_2 = 'email_subscriptions_2',
  CHIPOLO_CHECKOUT = 'chipolo_checkout',
  CHIPOLO_UPGRADE_1 = 'chipolo_upgrade_1',
  UPSELL_1 = 'upsell_1',
  PAYMENT_1 = 'payment_1',
  PAYMENT_2 = 'payment_2',
  PAYMENT_3 = 'payment_3',
  PAYMENT_4 = 'payment_4',
  PAYMENT_5 = 'payment_5',
  ACCOUNT = 'account',
  DOWNLOAD = 'download',
  PROXY_TO_START = 'proxy_to_start',
  PRIVACY_POLICY = 'privacy-policy',
  TERMS_OF_USE = 'terms-of-use',
  COOKIE_POLICY = 'cookie-policy',
}

export const ID_TO_PAGE_MAP: TIdToPageMap = {
  intro_1: IntroVariant1,
  intro_2: IntroVariant2,
  intro_3: IntroVariant3,
  intro_4: IntroVariant4,
  intro_5: IntroVariant5,
  intro_6: IntroVariant6,
  intro_7: IntroVariant7,
  intro_8: IntroVariant8,
  intro_google_1: IntroGoogleVariant1,
  intro_google_2: IntroGoogleVariant2,
  intro_google_3: IntroGoogleVariant3,
  intro_google_4: IntroGoogleVariant4,
  intro_google_5: IntroGoogleVariant5,
  reviews_1: ReviewsVariant1,
  benefits_1: BenefitsVariant1,
  child_age_1: ChildAgeVariant1,
  age_range_1: AgeRangeVariant1,
  behaviour_changes_1: BehaviourChangesVariant1,
  catch_on_a_lie_1: CatchOnALieVariant1,
  routines_changes_1: RoutinesChangesVariant1,
  days_disclosure_1: DaysDisclosureVariant1,
  inconsistencies_1: InconsistenciesVariant1,
  inconsistencies_2: InconsistenciesVariant2,
  interest_in_people_1: InterestInPeopleVariant1,
  phone_habits_1: PhoneHabitsVariant1,
  protective_of_phone_1: ProtectiveOfPhoneVariant1,
  take_calls_1: TakeCallsVariant1,
  calls_late_1: CallsLateVariant1,
  delete_calls_1: DeleteCallsVariant1,
  put_phone_away_1: PutPhoneAwayVariant1,
  suspicious_activity_1: SuspiciousActivityVariant1,
  suspicious_activity_2: SuspiciousActivityVariant2,
  going_out_1: GoingOutVariant1,
  stay_out_1: StayOutVariant1,
  stay_connected_1: StayConnectedVariant1,
  stay_connected_2: StayConnectedVariant2,
  stay_connected_3: StayConnectedVariant3,
  family_safety_1: FamilySafetyVariant1,
  one_plan_for_everyone_1: OnePlanForEveryoneVariant1,
  cheating_1: CheatingVariant1,
  gender_1: GenderVariant1,
  locator_app_1: LocatorAppVariant1,
  partners_data_1: PartnersDataVariant1,
  partners_data_2: PartnersDataVariant2,
  partners_safety_1: PartnersSafetyVariant1,
  partners_safety_2: PartnersSafetyVariant2,
  partners_in_danger_1: PartnersInDangerVariant1,
  friends_safety_1: FriendSafetyVariant1,
  data_1: DataVariant1,
  data_2: DataVariant2,
  data_3: DataVariant3,
  tracking_frequency_1: TrackingFrequencyVariant1,
  reasons_1: ReasonsVariant1,
  reasons_2: ReasonsVariant2,
  reasons_3: ReasonsVariant3,
  location_1: LocationVariant1,
  child_location_1: ChildLocationVariant1,
  child_location_2: ChildLocationVariant2,
  child_location_3: ChildLocationVariant3,
  child_location_4: ChildLocationVariant4,
  child_social_circle_1: ChildSocialCircleVariant1,
  child_school_issues_1: ChildSchoolIssuesVariant1,
  drugs_discussion_1: DrugsDiscussionVariant1,
  child_moves_1: ChildMovesVariant1,
  child_moves_2: ChildMovesVariant2,
  movement_history_1: MovementHistoryVariant1,
  urgent_messages_1: UrgentMessagesVariant1,
  unsafe_areas_1: UnsafeAreasVariant1,
  distress_signals_1: DistressSignalsVariant1,
  fear_1: FearVariant1,
  fear_2: FearVariant2,
  child_in_trouble_1: ChildInTroubleVariant1,
  concerns_1: ConcernsVariant1,
  concerns_2: ConcernsVariant2,
  connection_1: ConnectionVariant1,
  feelings_1: FeelingsVariant1,
  feelings_2: FeelingsVariant2,
  social_proof_1: SocialProofVariant1,
  social_proof_2: SocialProofVariant2,
  social_proof_3: SocialProofVariant3,
  social_proof_4: SocialProofVariant4,
  social_proof_5: SocialProofVariant5,
  social_proof_6: SocialProofVariant6,
  social_proof_7: SocialProofVariant7,
  social_proof_8: SocialProofVariant8,
  social_proof_9: SocialProofVariant9,
  social_proof_10: SocialProofVariant10,
  social_proof_11: SocialProofVariant11,
  social_proof_12: SocialProofVariant12,
  social_proof_13: SocialProofVariant13,
  social_proof_14: SocialProofVariant14,
  social_proof_15: SocialProofVariant15,
  social_proof_16: SocialProofVariant16,
  social_proof_17: SocialProofVariant17,
  social_proof_18: SocialProofVariant18,
  social_proof_19: SocialProofVariant19,
  social_proof_20: SocialProofVariant20,
  social_proof_21: SocialProofVariant21,
  get_in_touch_1: GetInTouchVariant1,
  wish_1: WishVariant1,
  wish_2: WishVariant2,
  wish_3: WishVariant3,
  top_priority_1: TopPriorityVariant1,
  top_priority_2: TopPriorityVariant2,
  felt_helpless_1: FeltHelplessVariant1,
  felt_helpless_2: FeltHelplessVariant2,
  felt_helpless_3: FeltHelplessVariant3,
  reason_to_join_1: ReasonToJoinVariant1,
  reason_to_join_2: ReasonToJoinVariant2,
  low_battery_1: LowBatteryVariant1,
  low_battery_2: LowBatteryVariant2,
  low_battery_3: LowBatteryVariant3,
  low_battery_4: LowBatteryVariant4,
  what_to_do_1: WhatToDoVariant1,
  what_to_do_2: WhatToDoVariant2,
  stolen_phone_1: StolenPhoneVariant1,
  stolen_phone_2: StolenPhoneVariant2,
  sos_message_1: SosMessageVariant1,
  sos_message_2: SosMessageVariant2,
  sos_message_3: SosMessageVariant3,
  quick_way_1: QuickWayVariant1,
  quick_way_2: QuickWayVariant2,
  quick_way_3: QuickWayVariant3,
  quick_way_4: QuickWayVariant4,
  phone_number_1: PhoneNumberVariant1,
  phone_number_2: PhoneNumberVariant2,
  feedback_1: FeedbackVariant1,
  biggest_fear_1: BiggestFearVariant1,
  driving_habits_1: DrivingHabitsVariant1,
  driving_habits_2: DrivingHabitsVariant2,
  lack_of_contact_1: LackOfContactVariant1,
  feelings_about_lack_of_contact_1: FeelingsAboutLackOfContactVariant1,
  emergency_case_1: EmergencyCaseVariant1,
  specific_zones_1: SpecificZonesVariant1,
  specific_zones_2: SpecificZonesVariant2,
  app_benefit_4: AppBenefitVariant2,
  app_benefit_5: AppBenefitVariant2,
  app_benefit_6: AppBenefitVariant2,
  preparing_flow_1: PreparingFlowVariant1,
  preparing_flow_2: PreparingFlowVariant2,
  preparing_flow_3: PreparingFlowVariant3,
  preparing_flow_4: PreparingFlowVariant4,
  location_search_1: LocationSearchVariant1,
  location_search_2: LocationSearchVariant2,
  quickly_find_1: QuicklyFindVariant1,
  quickly_find_2: QuicklyFindVariant2,
  private_chat_1: PrivateChatVariant1,
  geo_games_1: GeoGamesVariant1,
  importance_1: ImportanceVariant1,
  geotracking_1: GeoTrackingVariant1,
  geotracking_2: GeoTrackingVariant2,
  geotags_1: GeoTagsVariant1,
  notifications_1: NotificationsVariant1,
  possibility_1: PossibilityVariant1,
  share_location_1: ShareLocationVariant1,
  feel_anxious_1: FeelAnxiousVariant1,
  kids_safety_1: KidsSafetyVariant1,
  kids_alerts_1: KidsAlertsVariant1,
  parent_reviews_1: ParentReviewsVariant1,
  kid_outdoor_activities_1: KidOutdoorActivitiesVariant1,
  kid_lost_1: KidLostVariant1,
  pinpoint_location_1: PinpointLocationVariant1,
  receive_information_1: ReceiveInformationVariant1,
  dynamic_left_to_right: DynamicLeftToRight,
  dynamic_intro_single_choice: DynamicIntroSingleChoice,
  dynamic_intro_multiple_choice: DynamicIntroMultipleChoice,
  email_1: EmailVariant1,
  email_2: EmailVariant2,
  email_3: EmailVariant3,
  email_4: EmailVariant4,
  subscriptions_1: SubscriptionsVariant1,
  subscriptions_2: SubscriptionsVariant2,
  subscriptions_3: SubscriptionsVariant3,
  subscriptions_4: SubscriptionsVariant4,
  subscriptions_5: SubscriptionsVariant5,
  subscriptions_6: SubscriptionsVariant6,
  subscriptions_8: SubscriptionsVariant8,
  subscriptions_9: SubscriptionsVariant9,
  subscriptions_10: SubscriptionsVariant10,
  subscriptions_google_1: SubscriptionsGoogleVariant1,
  subscriptions_google_2: SubscriptionsGoogleVariant2,
  subscriptions_google_3: SubscriptionsGoogleVariant3,
  subscriptions_google_4: SubscriptionsGoogleVariant4,
  subscriptions_google_5: SubscriptionsGoogleVariant5,
  subscriptions_google_6: SubscriptionsGoogleVariant6,
  trial_subscriptions_1: TrialSubscriptionsVariant1,
  cancel_offer_subscriptions_1: CancelOfferSubscriptionVariant1,
  purchase_upgrade_1: PurchaseUpgradeVariant1,
  email_subscriptions_1: EmailSubscriptionsVariant1,
  email_subscriptions_2: EmailSubscriptionsVariant2,
  chipolo_upgrade_1: ChipoloUpgradeVariant1,
  chipolo_checkout: ChipoloCheckout,
  upsell_1: UpsellVariant1,
  payment_1: PaymentVariant1,
  payment_2: PaymentVariant2,
  payment_3: PaymentVariant3,
  payment_4: PaymentVariant4,
  payment_5: PaymentVariant5,
  payment_6: PaymentVariant6,
  account: Account,
  download: DownloadApp,
}
