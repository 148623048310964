import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { resetErrorAction } from 'root-redux/actions/common'
import {
  selectActionList,
  selectCurrentVariantCohortToUse,
  selectError,
  selectScreenName,
} from 'root-redux/selects/common'

import { ScreenName } from 'services/eventLogger.service'

import {
  CHECK_3D_SECURE,
  MAKE_UPSELL,
  PURCHASE,
} from 'modules/payment/redux/actions'

import { Spinner } from 'components/Spinner'

import errorIconDark from 'assets/images/error-2.svg'
import errorIcon from 'assets/images/error.svg'

import { goTo } from 'browser-history'
import { StyledPaymentStatus as S } from 'common-styles'
import { Cohort } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { PaymentSuccess } from '../PaymentSuccess'

type TProps = {
  onTryAgain?: () => void
  onNextStep?: () => void
  hasChipolo?: boolean
}

export const StripePaymentProcessing: React.FC<TProps> = ({
  onTryAgain,
  onNextStep,
  hasChipolo = false,
}) => {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const fetchingActionsList = useSelector(selectActionList)
  const screenName = useSelector(selectScreenName)
  const requestError = useSelector(selectError)
  const [isShown, setIsShown] = useState(false)

  const { t } = useTranslation()

  const isPurchaseInProcess = useMemo(
    () =>
      fetchingActionsList.includes(PURCHASE) ||
      fetchingActionsList.includes(CHECK_3D_SECURE) ||
      fetchingActionsList.includes(MAKE_UPSELL),
    [fetchingActionsList],
  )

  const isUpgradeScreenName = useMemo(
    () => screenName === ScreenName.UPGRADE,
    [screenName],
  )

  useEffect(() => {
    return () => {
      dispatch(resetErrorAction())
    }
  }, [dispatch])

  useEffect(() => {
    if (isPurchaseInProcess) {
      setIsShown(true)
    }
  }, [isPurchaseInProcess])

  const handleResetError = useCallback(() => {
    !onTryAgain && setIsShown(false)
    dispatch(resetErrorAction())
    onTryAgain && onTryAgain()
  }, [dispatch, onTryAgain])

  const handleClose = useCallback(() => {
    dispatch(resetErrorAction())
    goTo({ pathname: PageId.ACCOUNT, search })
  }, [dispatch, search])

  return (
    <>
      {isPurchaseInProcess && (
        <S.Wrapper>
          <S.Container>
            <Spinner isFullScreen={false} />
            <S.Title>{t`payment.stripe.titleProcessing`}</S.Title>
            <S.Subtitle>{t`payment.stripe.subtitleProcessing`}</S.Subtitle>
          </S.Container>
        </S.Wrapper>
      )}
      {!isPurchaseInProcess && !requestError && isShown && (
        <PaymentSuccess onNextStep={onNextStep} hasChipolo={hasChipolo} />
      )}
      {!isPurchaseInProcess && requestError && isShown && (
        <S.Wrapper>
          <S.Container data-testid="purchase-outcome-container">
            {isUpgradeScreenName && <S.CloseButton onClick={handleClose} />}
            <S.Icon
              src={cohortToUse === Cohort.FRIENDS_1 ? errorIconDark : errorIcon}
              alt="Error"
            />
            <S.Title>{t`payment.stripe.error`}</S.Title>
            <S.Subtitle>
              {requestError?.description ||
                requestError ||
                t`payment.commonError`}
            </S.Subtitle>
            <S.Button onClick={handleResetError}>
              {t`payment.stripe.tryAgain`}
            </S.Button>
          </S.Container>
        </S.Wrapper>
      )}
    </>
  )
}
