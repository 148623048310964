import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IInputFieldState, TInputFieldHook } from 'models/inputField.model'

export const usePasswordInputField = (
  errorMessage: string | null,
  resetErrorMessage: () => void,
): TInputFieldHook => {
  const { t } = useTranslation()

  const [password, setPassword] = useState<IInputFieldState>({
    value: '',
    isValid: true,
    validationText: t`commonComponents.shortPassword`,
  })

  const validatePassword = useCallback(
    (passwordValue: string) => {
      const formattedPassword = passwordValue.trim()

      if (formattedPassword.length < 8 && formattedPassword !== '') {
        setPassword((prevState) => ({
          ...prevState,
          value: formattedPassword,
          isValid: false,
          validationText: t`commonComponents.shortPassword`,
        }))
        return
      }

      setPassword((prevState) => ({
        ...prevState,
        value: formattedPassword,
        isValid: true,
        validationText: '',
      }))
    },
    [t],
  )

  useEffect(() => {
    if (errorMessage) {
      setPassword((prevState) => ({
        ...prevState,
        isValid: false,
        validationText: errorMessage,
      }))

      resetErrorMessage()
    }
  }, [errorMessage, resetErrorMessage])

  return [password, setPassword, validatePassword]
}
