import styled from 'styled-components'

import { Button } from 'components/Button'
import { StyledButtonBase } from 'components/Button/Button.styles'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import {
  BASE_COLUMN_WIDTH,
  Color,
  HEADER_HEIGHT,
  MediaBreakpoint,
} from 'root-constants/common'

type TProps = {
  isNotAdaptedCohort: boolean
}
export const StyledDownloadAppPage = {
  MobileWrapper: styled.div<TProps>`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: ${({ isNotAdaptedCohort }) =>
        isNotAdaptedCohort ? 'flex' : 'none'};
    }
  `,
  LaptopWrapper: styled.div`
    display: none;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      ${basePageStyles};
      display: flex;
      justify-content: center;
      height: 100vh;
      padding-top: ${HEADER_HEIGHT}px;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-bottom: 41px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      padding-bottom: 90px;
    }
  `,
  Title: styled.h1`
    position: absolute;
    top: 96px;
    width: ${BASE_COLUMN_WIDTH}px;
    font-size: 25px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;

    br {
      display: none;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      position: static;
      width: initial;
      margin-bottom: 16px;
      font-weight: 800;
      font-size: 48px;
      line-height: 54px;

      br {
        display: block;
      }
    }
  `,
  Subtitle: styled.h2`
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  `,
  List: styled.ul`
    margin-bottom: 24px;

    li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
  `,
  ListLabel: styled.div`
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    margin-right: 14px;
    font-size: 16px;
    font-weight: 800;
    line-height: 28px;
    text-align: center;
    border-radius: 8px;
    color: #bcc9d0;
    background-color: #eff5f7;
  `,
  ListText: styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    opacity: 0.8;
  `,
  Image: styled.img`
    margin: 40px 0 32px;
    aspect-ratio: 375/296;
    width: 100%;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  StickyButton: styled.button`
    ${StyledButtonBase};
    position: fixed;
    z-index: 1;
    bottom: 40px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    &[data-is-virtual-keypad-active='true'] {
      position: relative;
      bottom: 0;
    }

    &[data-is-auto-complete-shown='true'] {
      z-index: -1;
    }

    @media (orientation: landscape) {
      position: relative;
      bottom: 0;
    }

    @media (max-height: 620px) {
      position: static;
    }
  `,
  Advice: styled.p`
    max-width: 460px;
    margin: 0 auto;
    padding: 12px 24px;
    background-color: #eff1f4;
    border-radius: 20px;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;

    strong {
      color: ${Color.ACCENT_VIOLET};
    }
  `,
  Separator: styled.p`
    padding: 12px 0;
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: ${Color.ACCENT_VIOLET};
  `,
  LoginMethodAdvice: styled.p`
    max-width: 460px;
    margin: 0 auto;
    padding: 32px 0;
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
  `,
  Button: styled(Button)`
    width: 460px;
    height: 76px;
    margin-bottom: 64px;
    font-size: 24px;
    line-height: 76px;
    border-radius: 100px;
  `,
  Support: styled.p`
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #526f7f;

    a {
      font-weight: 700;
      text-decoration: underline;
      color: ${Color.ACCENT_VIOLET};
    }
  `,
}
