import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledSocialProofVariant18 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  ImageContainer: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    aspect-ratio: 375/300;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    position: fixed;
    bottom: 0;
    padding-bottom: 40px;
  `,
  Text: styled.p`
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    margin-bottom: 7px;
    color: ${Color.ACCENT_VIOLET};
  `,
  Note: styled.p`
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;
    text-align: center;
  `,
}
