import React from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'

import { Color } from 'root-constants/common'

import { StyledProgressRounded as S } from './ProgressRounded.styles'

type TProps = {
  value: number
  pathColor?: string
  trailColor?: string
}

export const ProgressRounded: React.FC<TProps> = ({
  value,
  pathColor = Color.ACCENT_VIOLET,
  trailColor = Color.LIGHT_GREY,
}) => (
  <S.Wrapper>
    <CircularProgressbarWithChildren
      value={value}
      styles={{
        path: {
          stroke: pathColor,
          strokeLinecap: 'round',
          strokeWidth: '8px',
          transition: 'all 0.5s ease-out',
        },
        trail: {
          stroke: trailColor,
          strokeWidth: '8px',
        },
      }}
    >
      <S.Value>{value}%</S.Value>
    </CircularProgressbarWithChildren>
  </S.Wrapper>
)
