/* eslint-disable max-lines */
import { PageId } from 'root-constants/pages'

export const enum CurrentEnvironment {
  DEV = 'dev',
  PROD = 'prod',
  STAGE = 'stage',
}

export const enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
}

export const enum CardPaymentFieldName {
  NAME = 'name',
  NUMBER = 'cardNumber',
  EXPIRY = 'cardExpiry',
  CVC = 'cardCvc',
}

export const enum SubscriptionListType {
  PURCHASE = 'purchase',
  UPGRADE = 'upgrade',
  UPSELL = 'upsell',
}

export const enum SubscriptionTag {
  TAX = 'tax',
  NO_TAX = 'no_tax',
  CANCEL_OFFER = 'cancel',
  MAN = 'man',
  AGE = 'age',
  NO_TAX_NEW_PRICE = 'no_tax_newprice',
}

export const enum SubscriptionStatus {
  ACTIVE = 'active',
  TRIAL = 'trial',
  CANCELLED = 'cancelled',
  NOT_ACTIVE = 'not_active',
}

export const BASE_COLUMN_WIDTH = 311

export const enum MediaBreakpoint {
  MIN_PHONE = 321,
  DEFAULT_PHONE = 375,
  MAX_PHONE = 431,
  TABLET = 768,
  LAPTOP = 1200,
}
export const HEADER_HEIGHT = 56
export const FAMY_LOCATE_HEADER_HEIGHT = 53
export const STORE_ONE_LINK =
  'https://famio.onelink.me/b9Ag?af_xp=custom&pid=web2web_payment_success'

export const CONTACT_FORM_LINKS = {
  [CurrentEnvironment.DEV]: 'https://account-famio.gidev.xyz/contact-form',
  [CurrentEnvironment.PROD]: 'https://account.myfamio.com/contact-form',
  [CurrentEnvironment.STAGE]: 'https://account-famio.gistage.com/contact-form',
}

export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const CARDHOLDER_NAME_REGEX =
  /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/
export const PHONE_REGEX = /^[0-9 \-()+]+$/

export const AWS_SHORTENER_API_KEY = 'JdEX69pWneoo0DRz8wg49gkyTnKttEHa5xEfTtOc'

export const enum LoginMethod {
  EMAIL = 'email',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export const enum LoginMethodRaw {
  EMAIL = 'password',
  GOOGLE = 'google.com',
  FACEBOOK = 'facebook.com',
  APPLE = 'apple.com',
}

export const LOGIN_METHOD_RAW_MAP = {
  [LoginMethodRaw.EMAIL]: LoginMethod.EMAIL,
  [LoginMethodRaw.GOOGLE]: LoginMethod.GOOGLE,
  [LoginMethodRaw.FACEBOOK]: LoginMethod.FACEBOOK,
  [LoginMethodRaw.APPLE]: LoginMethod.APPLE,
}

export const enum ProductKey {
  ONE_WEEK = 'oneWeek',
}

export const enum FirebaseSignUpErrorCode {
  EMAIL_EXISTS = 'auth/email-already-in-use',
}

export const enum Color {
  DEFAULT_TEXT = '#0a324d',
  LIGHT_TEXT = '#7b8898',
  ERROR = '#fe5959',
  GREY = '#eff5f7',
  LIGHT_GREY = '#f0f2fa',
  ACCENT_VIOLET = '#6336ff',
  WHITE = '#fff',
  BLUE = '#5c90ff',
  DEEP_BLUE = '#3e3baf',
}

export const enum TimeInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export const enum BillingCycle {
  ONE_MONTH = 1,
  THREE_MONTHS = 3,
  SIX_MONTHS = 6,
  NINE_MONTHS = 9,
  YEAR = 12,
}

export const enum TrialPeriod {
  ONE_DAY = 1,
  TWO_DAYS = 2,
  THREE_DAYS = 3,
  FOUR_DAYS = 4,
  ONE_WEEK = 7,
  ONE_MONTH = 30,
  THREE_MONTH = 90,
  ONE_YEAR = 360,
}

export const TRIAL_IN_HOURS = {
  [TrialPeriod.ONE_DAY]: 24,
  [TrialPeriod.TWO_DAYS]: 48,
  [TrialPeriod.THREE_DAYS]: 72,
  [TrialPeriod.FOUR_DAYS]: 96,
}
export const enum Currency {
  USD = 'usd',
  EUR = 'eur',
  AUD = 'aud',
  CAD = 'cad',
  GBP = 'gbp',
  MXN = 'mxn',
}

export const CURRENCY_SYMBOLS = {
  [Currency.USD]: '$',
  [Currency.EUR]: '€',
  [Currency.AUD]: 'AU$',
  [Currency.CAD]: 'C$',
  [Currency.GBP]: '£',
  [Currency.MXN]: 'MXN',
}

export const enum EventLoggerInstanceName {
  GIA = 'gia-amplitude',
  AMPLITUDE = 'amplitude',
  USER_FLOW_TRACKER = 'user-flow-tracker',
}

export const enum PlatformOS {
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'unknown',
}

export const enum AppWithInAppBrowser {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
}

export const enum PagePath {
  LOCALHOST = 'localhost',
  SUBSCRIPTIONS = 'subscriptions',
}

export const enum Cohort {
  MAIN_1 = 'famio_main1',
  MAIN_3 = 'famio_main3',
  MAIN_4 = 'famio_main4',
  MAIN_4_UPD = 'famio_main4_upd',
  MAIN_8 = 'famio_main8',
  MAIN_9 = 'famio_main9',
  SAFETY_2 = 'famio_safety2',
  SAFETY_4 = 'famio_safety4',
  SAFETY_6 = 'famio_safety6',
  SPOUSE_1 = 'famio_spouse1',
  EMAIL_PARTNERS = 'famio_email_partners',
  EMAIL_KIDS = 'famio_email_kids',
  UPGRADE_1 = 'famio_chat_upgrade3',
  UPGRADE_2 = 'famio_chat_upgrade6',
  UPGRADE_3 = 'famio_chat_upgrade12',
  UPGRADE_4 = 'famio_chat_upgrade6_70',
  UPGRADE_5 = 'famio_chat_upgrade12_100',
  UPGRADE_6 = 'famio_chat_upgrade12_80',
  GOOGLE_1 = 'famio_google_1',
  GOOGLE_2 = 'famio_google_2',
  GOOGLE_3 = 'famio_google_3',
  GOOGLE_4 = 'famio_google_4',
  GOOGLE_5 = 'famio_google_5',
  GOOGLE_6 = 'famio_google_6',
  GOOGLE_7 = 'famio_google_7',
  GOOGLE_8 = 'famio_google_8',
  GOOGLE_9 = 'famio_google_9',
  GOOGLE_10 = 'famio_google_10',
  GOOGLE_13 = 'famio_google_13',
  GOOGLE_14 = 'famio_google_14',
  GOOGLE_15 = 'famio_google_15',
  GOOGLE_16 = 'famio_google_16',
  FRIENDS_1 = 'famio_friends1',
  KIDS_1 = 'famio_kids1',
  FAMYLOCATE_V1 = 'famylocate_v1',
}

export const EMAIL_COHORTS: string[] = [
  Cohort.EMAIL_KIDS,
  Cohort.EMAIL_PARTNERS,
]

export const UPGRADE_COHORTS: string[] = [
  Cohort.UPGRADE_1,
  Cohort.UPGRADE_2,
  Cohort.UPGRADE_3,
  Cohort.UPGRADE_4,
  Cohort.UPGRADE_5,
  Cohort.UPGRADE_6,
]

export const DESKTOP_COHORTS: string[] = [Cohort.MAIN_1, Cohort.SPOUSE_1]

export const TRIAL_COHORTS: string[] = [
  Cohort.SAFETY_2,
  Cohort.SAFETY_4,
  Cohort.SAFETY_6,
]

export const FAMYLOCATE_COHORTS: string[] = [Cohort.FAMYLOCATE_V1]

export const PUBLIC_PAGES: PageId[] = [
  PageId.SUBSCRIPTIONS_GOOGLE_1,
  PageId.SUBSCRIPTIONS_GOOGLE_2,
  PageId.SUBSCRIPTIONS_GOOGLE_3,
  PageId.SUBSCRIPTIONS_GOOGLE_4,
  PageId.SUBSCRIPTIONS_GOOGLE_5,
  PageId.SUBSCRIPTIONS_GOOGLE_6,
  PageId.EMAIL_2,
  PageId.PAYMENT_4,
]
export const POST_PAYMENT_PAGES: PageId[] = [
  PageId.CHIPOLO_CHECKOUT,
  PageId.CHIPOLO_UPGRADE_1,
  PageId.UPSELL_1,
  PageId.ACCOUNT,
  PageId.DOWNLOAD,
]

export const NO_SCROLL_TO_TOP_PAGES: string[] = [
  PageId.INTRO_GOOGLE_4,
  PageId.SUBSCRIPTIONS_GOOGLE_2,
  PageId.SUBSCRIPTIONS_GOOGLE_3,
  PageId.SUBSCRIPTIONS_GOOGLE_4,
  PageId.SUBSCRIPTIONS_GOOGLE_5,
  PageId.SUBSCRIPTIONS_GOOGLE_6,
]

export const TEST_ENV_QUERY_PARAM = 'is_test_environment'
export const APP_NAME = 'famio'
export const PAGE_TRANSITION_DELAY = 500
export const CENTS_IN_DOLLAR = 100
export const INITIAL_PRICE = 0
export const DEFAULT_CURRENCY = Currency.USD
export const NO_NAME_AB_SEGMENT = 'no_name'
export const NO_GROWTH_BOOK_TEST = 'no_test'
export const EXPERIMENT_ID_NOT_SET = 'experiment_id_not_set'
export const IS_ACCOUNT_VISITED_SESSION_STORAGE_KEY = 'is_account_visited'
export const FAMYLOCATE_ROOT_DOMAIN = 'famylocate'

export const DEFAULT_LIVECHAT_START_TIME = 18
export const DEFAULT_LIVECHAT_END_TIME = 22

export const SINGLE_GOAL_LENGTH = 1

export const enum Frequency {
  QUITE_OFTEN = 'quite_often',
  SOMETIMES = 'sometimes',
  RARELY = 'rarely',
  NEVER = 'never',
  NOT_SURE = 'not_sure',
  OFTEN = 'often',
  ALWAYS = 'always',
}

export const FREQUENCY_OPTION_DATA = [
  { value: Frequency.QUITE_OFTEN, text: 'commonComponents.quiteOften' },
  { value: Frequency.SOMETIMES, text: 'commonComponents.sometimes' },
  { value: Frequency.RARELY, text: 'commonComponents.rarely' },
  { value: Frequency.NEVER, text: 'commonComponents.never' },
]
export const enum Confidence {
  YES = 'yes',
  NO = 'no',
  NOT_SURE = 'not_sure',
  SOMETIMES = 'sometimes',
  CANT_REMEMBER = 'cant_remember',
  MAYBE = 'maybe',
  SURE = 'sure',
}

export const CONFIDENCE_OPTION_DATA = [
  { value: Confidence.YES, text: 'commonComponents.yes' },
  { value: Confidence.NO, text: 'commonComponents.no' },
  { value: Confidence.SOMETIMES, text: 'commonComponents.sometimes' },
]

export const ALTERNATIVE_CONFIDENCE_OPTION_DATA = [
  { value: Confidence.YES, text: 'commonComponents.yes' },
  { value: Confidence.MAYBE, text: 'commonComponents.maybe' },
  { value: Confidence.NO, text: 'commonComponents.no' },
]

export const LOW_CONFIDENCE_OPTION_DATA = [
  { value: Confidence.YES, text: 'commonComponents.yes' },
  { value: Confidence.NO, text: 'commonComponents.no' },
  { value: Confidence.NOT_SURE, text: 'commonComponents.notSure' },
]

export const ALTERNATIVE_LOW_CONFIDENCE_OPTION_DATA = [
  { value: Confidence.YES, text: 'commonComponents.yes' },
  { value: Confidence.NO, text: 'commonComponents.no' },
  { value: Confidence.SOMETIMES, text: 'commonComponents.sometimes' },
  { value: Confidence.NOT_SURE, text: 'commonComponents.notSure' },
]

export const SIMPLE_OPTION_DATA = [
  { value: Confidence.YES, text: 'commonComponents.yes' },
  { value: Confidence.NO, text: 'commonComponents.no' },
]

export const enum Interest {
  GREAT = 'great',
  INTERESTING = 'interesting',
  MAYBE = 'maybe',
  NOT_INTERESTED = 'not_interested',
}

export const INTEREST_OPTION_DATA = [
  { value: Interest.GREAT, text: 'commonComponents.great' },
  { value: Interest.INTERESTING, text: 'commonComponents.interesting' },
  { value: Interest.MAYBE, text: 'commonComponents.maybe' },
  { value: Interest.NOT_INTERESTED, text: 'commonComponents.notInterested' },
]

export const enum TrackingData {
  MOVEMENT_HISTORY = 'movement_history',
  LOCATION_NOTIFICATIONS = 'location_notifications',
  BATTERY_ALERTS = 'battery_alerts',
  SOS_NOTIFICATIONS = 'sos_messages',
  DRIVING_MODE = 'car_crash_notifications',
  GET_HURTS = 'somebody_get_hurts',
  PROXIMITY_ALERTS = 'proximity_alerts',
}

export const enum ChildAgeRange {
  BABY = '0-5',
  KID = '6-11',
  TEENAGER = '12-18',
  ADULT = '18+',
}

export const enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export const enum UserGoal {
  PARTNER = 'partner',
  KID = 'kid',
  FRIEND = 'friend',
  OTHER = 'other',
  TEEN = 'teen',
}

export const enum CountryCode {
  UNITED_STATES = 'us',
}

export const PERIOD_NAME_TO_MARKUP_MAP = {
  week: 'subscriptions.planBlock.planPeriods.week',
  month: 'subscriptions.planBlock.planPeriods.month',
  day: 'subscriptions.planBlock.planPeriods.day',
}

export const INTRO_OFFER_PERIODS = {
  [TrialPeriod.ONE_WEEK]: 7,
  [TrialPeriod.ONE_MONTH]: 1,
  [TrialPeriod.THREE_MONTH]: 3,
}

export const INTRO_OFFER_PERIODS_WEEKS = {
  [TrialPeriod.ONE_WEEK]: 1,
  [TrialPeriod.ONE_MONTH]: 4,
  [TrialPeriod.THREE_MONTH]: 12,
}

export const INTRO_OFFER_PERIOD_NAMES = {
  [TrialPeriod.ONE_WEEK]: 'subscriptions.planBlock.planPeriods.day',
  [TrialPeriod.ONE_MONTH]: 'subscriptions.planBlock.planPeriods.month',
  [TrialPeriod.THREE_MONTH]: 'subscriptions.planBlock.planPeriods.month',
}

export const INTRO_OFFER_WEEKLY_PERIOD_NAMES = {
  [TrialPeriod.ONE_WEEK]: 'subscriptions.planBlock.weeklyPlanPeriods.oneWeek',
  [TrialPeriod.ONE_MONTH]:
    'subscriptions.planBlock.weeklyPlanPeriods.moreThanOneWeek',
  [TrialPeriod.THREE_MONTH]:
    'subscriptions.planBlock.weeklyPlanPeriods.moreThanOneWeek',
}

export const INTRO_OFFER_FULL_PERIOD_NAMES = {
  [TrialPeriod.ONE_WEEK]: 'subscriptions.introOfferPeriod.week',
  [TrialPeriod.ONE_MONTH]: 'subscriptions.introOfferPeriod.oneMonth',
  [TrialPeriod.THREE_MONTH]: 'subscriptions.introOfferPeriod.threeMonth',
}

export const TRIAL_QUANTITY_TO_MARKUP_MAP = {
  [TrialPeriod.ONE_WEEK]: 'subscriptions.description.monthly',
  [TrialPeriod.ONE_MONTH]: 'subscriptions.description.monthly',
  [TrialPeriod.THREE_MONTH]: 'subscriptions.description.quarterly',
}

export const enum Language {
  ES = 'es',
  EN = 'en',
  DE = 'de',
  FR = 'fr',
  PT = 'pt',
  IN_CONTEXT = 'af',
}
export const LANG_QUERY_PARAM = 'lang'
export const INITIAL_LANGUAGE = Language.EN
export const SUPPORTED_LOCALES: string[] = [
  Language.EN,
  Language.ES,
  Language.DE,
  Language.FR,
  Language.PT,
]
export const DYNAMIC_PAGE_ID_PREFIX = 'dynamic'

export const CDN_FOLDER_LINK =
  'https://cdn-prod-unified-content.gismart.xyz/d07f41c2d723bc846a1e179da43ae3c95cbdbb81345281bc599ad3d1618ca197'

export const enum PlanAddition {
  PREMIUM = 'Premium',
  UPGRADE = 'Upgrade',
  CHIPOLO = 'Chipolo One',
  UPSELL = 'Upsell',
}

export const PLAN_ADDITIONS_MAP: Record<string, PlanAddition[]> = {
  [PageId.SUBSCRIPTIONS_1]: [PlanAddition.PREMIUM],
  [PageId.SUBSCRIPTIONS_2]: [PlanAddition.PREMIUM],
  [PageId.SUBSCRIPTIONS_3]: [PlanAddition.PREMIUM],
  [PageId.SUBSCRIPTIONS_5]: [PlanAddition.PREMIUM],
  [PageId.SUBSCRIPTIONS_8]: [PlanAddition.PREMIUM],
  [PageId.TRIAL_SUBSCRIPTION_1]: [PlanAddition.PREMIUM],
  [PageId.CANCEL_OFFER_SUBSCRIPTIONS_1]: [PlanAddition.PREMIUM],
  [PageId.PURCHASE_UPGRADE_1]: [PlanAddition.UPGRADE],
  [PageId.CHIPOLO_UPGRADE_1]: [PlanAddition.UPGRADE],
  [PageId.CHIPOLO_CHECKOUT]: [PlanAddition.UPGRADE, PlanAddition.CHIPOLO],
  [PageId.UPSELL_1]: [PlanAddition.UPSELL],
}

export const VAT_INCLUDED_COUNTRIES: string[] = [CountryCode.UNITED_STATES]
export const VAT_INCLUDED_COHORTS: string[] = [
  Cohort.SPOUSE_1,
  Cohort.FRIENDS_1,
  Cohort.SAFETY_2,
  Cohort.SAFETY_6,
  Cohort.MAIN_4,
  Cohort.MAIN_4_UPD,
  Cohort.KIDS_1,
  Cohort.MAIN_8,
  Cohort.MAIN_9,
]

export const EMPTY_OLD_PRICE = {
  fullPrice: 0,
  amountOfDiscount: 0,
  weekly: 0,
  daily: 0,
  monthly: 0,
  yearly: 0,
  percentOfDiscount: 0,
}

export const enum CheckboxCustomValue {
  ALL_OF_THE_ABOVE = 'all_of_the_above',
}
export const CHECKBOX_CUSTOM_VALUES: string[] = [
  CheckboxCustomValue.ALL_OF_THE_ABOVE,
]

export const enum Images {
  HUSBAND_CALL = '/images/subscription-husband-call',
  HUSBAND_CALL_LAPTOP = '/images/subscription-husband-call-laptop',
  WIFE_CALL = '/images/subscription-wife-call',
  WIFE_CALL_LAPTOP = '/images/subscription-wife-call-laptop',
  GUARANTEE = '/images/guarantee',
  FEW_GOALS_IMG = '/images/subscription-few',
  FEW_GOALS_IMG_LAPTOP = '/images/subscription-laptop-few',
  FRIEND_GOAL = '/images/subscription-friend',
  FRIEND_GOAL_LAPTOP = '/images/subscription-laptop-friend',
  KID_GOAL = '/images/subscription-kid',
  KID_GOAL_LAPTOP = '/images/subscription-laptop-kid',
  PARTNER_GOAL = '/images/subscription-partner',
  PARTNER_GOAL_LAPTOP = '/images/subscription-laptop-partner',
  TEEN_GOAL = '/images/subscription-teen',
  GOOGLE_SUBS_PHONE = '/images/google-subs-phone',
  GOOGLE_SUBS_PHONE_PREMIUM = '/images/google-phone-premium',
  GOOGLE_SUBS_PHONE_FAMILY = '/images/google-phone-family',
  GOOGLE_SUBS_DISCOUNT_BADGE = '/images/google-discount-badge',
  GOOGLE_SUBS_PHONE_ALEX_FAMILY = '/images/google-subs-phone-alex-family',
  GOOGLE_SUBS_PHONE_LOCATION = '/images/google-subs-phone-location',
  LOCATION_LOVED = '/images/location-loved',
  LOCATION_HISTORY = '/images/location-history',
  UNLIMITED_GROUPS = '/images/unlimited-groups',
  SOS_NOTIFICATION = '/images/sos-notification',
  PRIVATE_CHAT = '/images/private-chat',
  UNLIMITED_OFFER_BADGE = '/images/unlimited-offer-badge',
  GOOGLE_PHONE_KID = '/images/google-phone-kid',
  GOOGLE_PHONE_1 = '/images/google-phone-1',
  GOOGLE_PHONE_2 = '/images/google-phone-2',
  GOOGLE_PHONE_3 = '/images/google-phone-3',
  UNSAFE_DRIVING = '/images/unsafe-driving',
  REAL_TIME_LOCATION = '/images/real-time-location',
  LOCATION_HISTORY_ROUNDED = '/images/location-history-rounded',
  PLACE_ALERTS = '/images/place-alerts',
  AR_SEARCH = '/images/ar-search',
  SOS_BUTTON = '/images/sos-button',
  FALL_DETECTION = '/images/fall-detection',
  CRASH_SPEED_CONTROL = '/images/crash-control',
  WEARABLES = '/images/wearables',
  COMPATIBLE_WITH = '/images/compatible-with',
  TRACK_LOCATION = '/images/track-location',
  REAL_TIME_LOCATION_WITHOUT_BG = '/images/real-time-location-without-bg',
  UNLIMITED_GROUPS_WITHOUT_BG = '/images/unlimited-groups-without-bg',
  SOS_NOTIFICATION_WITHOUT_BG = '/images/sos-notification-without-bg',
  PRIVATE_CHAT_WITHOUT_BG = '/images/private-chat-without-bg',
}

export const enum ChildAge {
  TEN_YEARS = 10,
  FIFTEEN_YEARS = 15,
}
export const enum ChildFlow {
  MIXED = 'mixed',
  KID = 'kid',
  TEENAGE = 'teenage',
}

export const DISCLAIMER_CONTEXTS = {
  [TrialPeriod.ONE_WEEK]: TimeInterval.WEEK,
  [TrialPeriod.ONE_MONTH]: TimeInterval.MONTH,
  [TrialPeriod.THREE_MONTH]: TimeInterval.QUARTER,
}

export const enum AppLinks {
  TOU_LINK = `/${PageId.TERMS_OF_USE}`,
  PP_LINK = `/${PageId.PRIVACY_POLICY}`,
  COOKIE_LINK = `/${PageId.COOKIE_POLICY}`,
}
export const TOU_PP_COOKIE_LINKS: string[] = [
  AppLinks.TOU_LINK,
  AppLinks.PP_LINK,
  AppLinks.COOKIE_LINK,
]

export const enum CookieConsentOption {
  STRICTLY_NECESSARY = 'necessary',
  ANALYTICS_AND_PERFORMANCE = 'analytics',
  ADVERTISING_AND_TARGETING = 'advertising',
  FUNCTIONAL_COOKIES = 'functional_cookies',
  REJECT_ALL = 'reject_all',
}

export const enum GAConsent {
  GRANTED = 'granted',
  DENIED = 'denied',
}

export const enum FBConsent {
  REVOKE = 'revoke',
  GRANT = 'grant',
}

export const enum HotJarClientId {
  PROD = '4995498',
  DEV = '4995500',
}
