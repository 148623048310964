import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/usePurchaseStore'

import {
  CURRENCY_SYMBOLS,
  INTRO_OFFER_PERIODS,
  INTRO_OFFER_PERIOD_NAMES,
  PERIOD_NAME_TO_MARKUP_MAP,
  TimeInterval,
} from 'root-constants/common'

import { StyledTotalAmount as S } from './TotalAmount.styles'

export const TotalAmount: React.FC = () => {
  const { t } = useTranslation()

  const {
    trialPriceId,
    trialPrice,
    trialPeriodDays,
    currentPrice,
    periodName,
    periodQuantity,
    currency,
  } = usePurchaseStore()

  const subscriptionDescription = useMemo(() => {
    if (trialPriceId) {
      const period = INTRO_OFFER_PERIODS[trialPeriodDays]

      return (
        <Trans
          i18nKey="payment.trialDescription"
          values={{
            count: period,
            trialPeriod: period,
            trialPeriodName: t(INTRO_OFFER_PERIOD_NAMES[trialPeriodDays], {
              count: period,
            }),
          }}
        />
      )
    }

    return t('payment.subscriptionDescription', {
      periodQuantity,
      periodName: t(PERIOD_NAME_TO_MARKUP_MAP[periodName as TimeInterval], {
        count: periodQuantity,
      }),
      count: periodQuantity,
    })
  }, [periodName, periodQuantity, t, trialPeriodDays, trialPriceId])

  return (
    <S.Container>
      <S.TitleContainer>{t('payment.total')}</S.TitleContainer>
      <div>
        <S.PriceContainer>
          {t('payment.totalPrice', {
            price: trialPrice || currentPrice,
            currencySymbol: CURRENCY_SYMBOLS[currency],
          })}{' '}
        </S.PriceContainer>
        <S.SubscriptionDescription>
          {subscriptionDescription}
        </S.SubscriptionDescription>
      </div>
    </S.Container>
  )
}
